import getApiUrl from "../../helpers/apiUrls";
import * as commonActions from "../../store/common/actions";
import axios from "axios";
import * as type from "../../store/reward/actionTypes";
import utilCommon from "../../helpers/utilCommon";
import authHeader from "../../helpers/authHeader";
import * as commonAction from "../../store/common/actions";
import * as customerType from "../../store/customer/actionTypes";
import customerService from "@services/customerService";

const getRewardSettings = (dispatch, lang, dictionary) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('reward', 'settings');

    dispatch(commonActions.setLoader(true))

    axios.get(url, params)
        .then((response) => {
            let responseData = response.data;

            dispatch(commonActions.setLoader(false))

            if (responseData.success === true) {
                dispatch({
                    type: type.SET_REWARD_SETTINGS,
                    rewardSettings: responseData.data
                })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch(() => {
            dispatch(commonActions.setLoader(false))
        });
};

const updateRewardSettings = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('reward', 'settings');

    dispatch(commonActions.setLoader(true))

    axios.put(url, inputDTO, params)
        .then((response) => {
            let responseData = response.data;

            dispatch(commonActions.setLoader(false))

            if (responseData.success === true) {
                utilCommon.showNotification('Reward settings updated successfully.')
                getRewardSettings(dispatch, lang, dictionary)
                getRewardLogs(dispatch, lang, dictionary)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

const updateRewardPoints = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('reward', 'points');

    dispatch(commonActions.setLoader(true))

    axios.post(url, inputDTO, params)
        .then((response) => {
            let responseData = response.data;

            dispatch(commonActions.setLoader(false))

            if (responseData.success === true) {
                utilCommon.showNotification('Reward points processed successfully.')
                getRewardLogs(dispatch, lang, dictionary)
                customerService.getCustomerData(dispatch, lang, dictionary, {customerUuid: inputDTO.customer_id});
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
}

const getRewardLogs = (dispatch, lang, dictionary) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('reward', 'rewardLogs');

    dispatch(commonAction.setLoader(true))

    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))

            if (response.data.success === true) {
                dispatch({type: type.SET_REWARD_LOGS, rewardLogs: response.data.data.activityLog})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

const getCustomerRewardsPointsLogs = (dispatch, lang, dictionary, customerId) => {
    const params = {headers: authHeader()};
    let url = getApiUrl('reward', 'customerRewardsPointsLogs') + ('?customerUuid=' + customerId + "&sortColumn=created_at&sortOrder=desc");
    dispatch(commonAction.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success === true) {
                dispatch({type: customerType.SET_CUSTOMER_REWARDS_POINTS_LOGS, customerRewardsPointsLogs: response.data.data})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
};

export default {
    getRewardSettings,
    updateRewardSettings,
    updateRewardPoints,
    getRewardLogs,
    getCustomerRewardsPointsLogs
}