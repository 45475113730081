import React from "react";
import {connect} from "react-redux";
import shipmentService from "../../../services/api/shipmentService";
import Loader from '../../atoms/Loader'
import moment from 'moment'
import utilCommon from '../../../helpers/utilCommon'
import {LTL_SHIPMENT_MODE, TL_SHIPMENT_MODE} from "@helpers/enums";
import lodash from 'lodash';

class TransactionLogList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        this.props.getTransactionLogs({shipmentUuid: this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]}, this.props.currentLanguage, this.props.dictionaries)
    }

    getShippingModeId() {
        return lodash.get(this.props.transactionLogs, 'shippingModeId', LTL_SHIPMENT_MODE);
    }

    getDateTime = (record, format) => {
        const recordDate = record.paymentDate || record.payment_date || record.addedDate || false;

        return recordDate ? moment(recordDate).format(format) : '';
    }

    render() {
        const isTlShippingMode = this.getShippingModeId() === TL_SHIPMENT_MODE;

        return (
            <div className="main-wraper mt30">
                <div className="main-content">
                    <div className="page-title"><h2>Transaction logs</h2></div>
                    <div className="white-section search-cutomer">
                        <fieldset className={"tableFieldset p10 bdr0"}>
                            <div className="filter-title-row1 mb10 uppercase pl10"><span className="font13 pl10">Shipment Id# {this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]}</span>
                            </div>
                            <div className="searchbaar open">
                                <table cellpadding="0" cellspacing="0" border="0" className="tbl-data">
                                    <thead>
                                    <tr>
                                        <td width="14%">
                                            <div className="sort">Type</div>
                                        </td>

                                        <td width="20%">
                                            <div className="sort">Description</div>
                                        </td>
                                        {utilCommon.isPermitted(1, 3, 1) && <td width="12%">
                                            <div className="sort">Cost</div>
                                        </td>}
                                        <td width="12%">
                                            <div className="sort">Charge</div>
                                        </td>
                                        <td width="12%">
                                            <div className="sort">Paid</div>
                                        </td>
                                        <td>
                                            <div className="sort">Created by</div>
                                        </td>
                                        <td>
                                            <div className="sort">Date and time</div>
                                        </td>
                                        <td width={isTlShippingMode ? '' : '20%'}>
                                            <div className="sort">Assigned To</div>
                                        </td>
                                        {
                                            isTlShippingMode && <td width="10%">
                                                <div className="sort">Carrier Sales Assigned To</div>
                                            </td>
                                        }
                                    </tr>
                                    </thead>

                                    {
                                        ! lodash.isEmpty(this.props.transactionLogs) &&
                                        this.props.transactionLogs.shipmentTransactionList.map(transaction => (
                                            <tbody className={''}
                                                   key={transaction.shipmentTransactionUuid}>
                                            <tr key={transaction.shipmentTransactionUuid}>
                                                <td>{transaction.transactionTypeId ? transaction.transactionTypeId.name : ''}{transaction.transactionSubTypeId ? (' : ' + transaction.transactionSubTypeId.name) : ''}</td>
                                                <td>{transaction.shipmentDescription}</td>
                                                {utilCommon.isPermitted(1, 3, 1) &&
                                                    <td className={transaction.shipmentCost && parseFloat(transaction.shipmentCost) < 0 ? 'error' : ''}>$ {transaction.shipmentCost ? (parseFloat(transaction.shipmentCost || 0) < 0 ? `(${Math.abs(transaction.shipmentCost || 0).toFixed(2)})` : Math.abs(transaction.shipmentCost || 0).toFixed(2)) : '0.00'}</td>}
                                                <td className={transaction.shipmentCharge && parseFloat(transaction.shipmentCharge) < 0 ? 'error' : ''}>$ {transaction.shipmentCharge ? (parseFloat(transaction.shipmentCharge) < 0 ? `(${Math.abs(transaction.shipmentCharge).toFixed(2)})` : Math.abs(transaction.shipmentCharge).toFixed(2)) : '0.00'}</td>
                                                <td className={transaction.paymentAmount < 0 ? 'error' : ''}>$ {transaction.paymentAmount ? (transaction.paymentAmount < 0 ? `(${Math.abs(transaction.paymentAmount || 0).toFixed(2)})` : Math.abs(transaction.paymentAmount || 0).toFixed(2)) : '0.00'}</td>
                                                <td>{transaction.addedBy ? transaction.addedBy.name : ''}</td>
                                                <td>{transaction.addedDate ? moment(transaction.addedDate).format('MM/DD/YYYY') : ''}<br/>
                                                    <em className="timeTxt">{this.getDateTime(transaction, 'hh:mm:ss A')}</em>
                                                </td>
                                                <td>{transaction.agentUserUuid ? transaction.agentUserUuid.name : ''}</td>
                                                {
                                                    isTlShippingMode &&
                                                    <td>{transaction.carrierAgentUserUuid ? transaction.carrierAgentUserUuid.name : ''}</td>
                                                }
                                            </tr>

                                            </tbody>

                                        ))}
                                    {
                                        lodash.isEmpty(this.props.transactionLogs) || this.props.transactionLogs.shipmentTransactionList.length == 0
                                            ? <tbody className={''}>
                                            <tr>
                                                <td colSpan="8" className="error txtCenter">No Records Found</td>
                                            </tr>
                                            </tbody>
                                            : null
                                    }
                                </table>
                            </div>
                            {this.props.loading && <Loader/>}
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    transactionLogs: state.shipment.transactionLogs
});

const mapDispatchToProps = dispatch => ({
    getTransactionLogs: (inputDTO, lang, dictionaries) => {
        shipmentService.getTransactionLogs(dispatch, lang, dictionaries, inputDTO)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionLogList);
