import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import CustomInputField from '../atoms/CustomInputField'
import CustomSelectBox from '../atoms/CustomSelectBox'
import CustomRadioButton from '../atoms/CustomRadioButton'
import CustomCheckBox from '../atoms/CustomCheckBox'
import DatePicker from '../atoms/DatePicker'
import utilCommon from '../../helpers/utilCommon';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import SuggestiveSelectBox from '../atoms/SuggestiveSelectBox';
import lodash from 'lodash';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});



const getMappedInitial = (users, saleTeams, leadTypes, leadSubTypes, leadModes) => {
    let user = Object.assign({}, users)

    if (user) {
        if(user.teamUuid && Array.isArray(user.teamUuid) && user.teamUuid.length>0){
            user.teamUuid = user.teamUuid[0].teamUuid ? user.teamUuid[0].teamUuid :''
        }
        if(user.teamUuid && Array.isArray(user.teamUuid) && user.teamUuid.length == 0){
            user.teamUuid  = ''
        }
        if (user.timeZone && user.timeZone.value) {
            user.timeZone = user.timeZone.value
        }

        if (user.departmentId && user.departmentId.name) {
            user.departmentId = user.departmentId.id
        }

        if (user.leadEnabled && user.leadEnabled == true) {
            user.leadEnabled = "1"
        } else {
            user.leadEnabled = "0"
        }

        if (user.leadType && user.leadType.length > 0) {
            user.leadType.map(x => {
                const type = lodash.find(leadTypes, function (el) { return el.id == x.id });
                user[type ? type.name.split(' ').join('') : x.name] = true;
            })
        }

        if (user.leadSubType && user.leadSubType.length > 0) {
            user.leadSubType.map(x => {
                const subType = lodash.find(leadSubTypes, function (el) { return el.id == x.id });
                user['leadSubType_' + subType.id] = true;
            })
        }

        if (user.userType && user.userType.length > 0) {
            user.userType.map(x => {
                if (x.id && x.name == 'salesFloorManager') {
                    user.salesFloorManager = true
                }

                if (x.id && x.name == 'executive') {
                    user.executive = true
                }
            })
        }

        if (user.leadTimezone && user.leadTimezone.length > 0) {
            user.leadTimezone.map(x => {
                user[x.name] = true
            })
        } else {
            user['Alltimezones'] = true;
        }

        if (user.leadMode && user.leadMode.length > 0) {
            user.leadMode.map(x => {
                const mode = lodash.find(leadModes, function (el) { return el.id === x.id });
                user[mode ? mode.name : x.name] = true
            })
        }

        if (user.weekDay && user.weekDay.length > 0) {
            user.weekDay.map(x => {
                user[x.name.toLowerCase()] = true
            })
        }

        if (user.role && user.role.length > 0) {
            user.role = user.role[0] ? user.role[0].value:'';
        }
    }

    return user
}


const validate = (formProps) => {
    const errors = {};
    let requiredFields = []

    if (utilCommon.getSession('c_user')) {
        requiredFields = [
            'firstName',
            'lastName',
            'emailId',
            // 'quota',
            'role',
            'extension',
            'status',
            'timeZone',
            'leadEnabled',
            'topDogVisible',
            'restrictedByIp',
            'hireDate',
            'userGoal',
            'teamUuid'
        ]

        if (formProps.password) {
            requiredFields.push('confirmpassword')
            if (formProps.password && !utilCommon.Password_Regex.test(formProps.password)) {
                errors.password = 'The password should be alphanumeric with minimum of 8 characters and a special character.';
            }

            if (formProps.confirmpassword && !utilCommon.Password_Regex.test(formProps.confirmpassword)) {
                errors.confirmpassword = 'The password should be alphanumeric with minimum of 8 characters and a special character.';
            }

            if (formProps.confirmpassword && formProps.password && formProps.confirmpassword !== formProps.password) {
                errors.confirmpassword = '“Confirm Password” should match the “Password”';
            }
        }

        if (formProps.confirmpassword) {
            requiredFields.push('password')
            if (formProps.password && !utilCommon.Password_Regex.test(formProps.password)) {
                errors.password = 'The password should be alphanumeric with minimum of 8 characters and a special character.';
            }

            if (formProps.confirmpassword && !utilCommon.Password_Regex.test(formProps.confirmpassword)) {
                errors.confirmpassword = 'The password should be alphanumeric with minimum of 8 characters and a special character.';
            }

            if (formProps.confirmpassword && formProps.password && formProps.confirmpassword !== formProps.password) {
                errors.confirmpassword = '“Confirm Password” should match the “Password”';
            }
        }
    } else {
        requiredFields = [
            'firstName',
            'lastName',
            'emailId',
            // 'quota',
            'role',
            'extension',
            'status',
            'timeZone',
            'leadEnabled',
            'topDogVisible',
            'restrictedByIp',
            'hireDate',
            'password',
            'confirmpassword',
            'userGoal',
            'teamUuid'
        ]
    }

    requiredFields.map((x) => {
        if (!formProps[x]) {
            errors[x] = ' is a required field';
        }
        if (formProps[x] && formProps[x].length === 0) {
            errors[x] = ' is a required field';
        }
    })

    // if (formProps.quota && isNaN(formProps.quota)) {
    //     errors.quota = 'Please enter a numeric value';
    // }

    if (formProps.extension && isNaN(formProps.extension)) {
        errors.extension = 'Please enter a numeric value';
    }


    if (formProps.hireDate) {
        if (utilCommon.checkDate(formProps.hireDate)) {
            errors.hireDate = 'Please provide a valid date.';
        }
    }

    if (!formProps.hireDate) {
        errors.hireDate = ' is a required field';
    }

    if (formProps.emailId && !utilCommon.Email_Regex.test(formProps.emailId)) {
        errors.emailId = 'Please enter a valid email address.';
    }

    if (formProps.firstName && !utilCommon.AlphaNumericWhiteSpace_Regex.test(formProps.firstName)) {
        errors.firstName = 'Special characters not allowed.';
    }

    if (formProps.lastName && !utilCommon.AlphaNumericWhiteSpace_Regex.test(formProps.lastName)) {
        errors.lastName = 'Special characters not allowed.';
    }

    if (formProps.password && !utilCommon.Password_Regex.test(formProps.password)) {
        errors.password = 'The password should be alphanumeric with minimum of 8 characters and a special character.';
    }

    if (formProps.confirmpassword && !utilCommon.Password_Regex.test(formProps.confirmpassword)) {
        errors.confirmpassword = 'The password should be alphanumeric with minimum of 8 characters and a special character.';
    }

    if (formProps.confirmpassword && formProps.password && formProps.confirmpassword !== formProps.password) {
        errors.confirmpassword = '“Confirm Password” should match the “Password”';
    }

    if (formProps.userGoal && (formProps.userGoal > 999999 || formProps.userGoal <  0)) {
        errors.userGoal = 'Goal per month(profit) should be in between 0-999999';
    }
    else if(formProps.userGoal && (isNaN(formProps.userGoal) || formProps.userGoal.toString().indexOf('.')!=-1) ){
        errors.userGoal = 'Please enter a valid numeric value';
    }

    if (!formProps['1-timeshipment'] && !formProps['1-10/month'] && !formProps['10+/month']) {
        errors.leadTypeError = true
    } else {
        errors.leadTypeError = false
    }
    if (!formProps.monday && !formProps.tuesday && !formProps.wednesday && !formProps.thursday && !formProps.friday && !formProps.saturday && !formProps.sunday) {
        errors.receiveLeadsOnError = true
    } else {
        errors.receiveLeadsOnError = false
    }
    if(!formProps.Easterntimezone && !formProps.Centraltimezone && !formProps.Mountaintimezone && !formProps.Pacifictimezone && !formProps.Alltimezones) {
        errors.timeZonesError = true
    } else {
        errors.timeZonesError = false
    }
    if (!formProps.LTL && !formProps.TL && !formProps.Parcel) {
        errors.leadModeError = true
    } else {
        errors.leadModeError = false
    }

    return errors;
}


export class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 'female',
            showRestrictedIps: this.props.initialValues ? this.props.initialValues.restrictedByIp == true : false,
            restrictedIps: [],
            toggle: false,
            editView: false,
            height: window.innerHeight,
            leadTypes: [],
            leadModes: [],
            timeZone: [{id: 0, name:'All time zones'}],
            receiveLeadsOn: [{label:'Monday', name:'monday'}, {label:'Tuesday', name:'tuesday'},{label:'Wednesday', name:'wednesday'},{label:'Thursday', name:'thursday'},{label:'Friday', name:'friday'},{label:'Saturday', name:'saturday'},{label:'Sunday', name:'sunday'}]
        }
        this.updateView = this.updateView.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    handleToggle = value => () => {
        this.setState({
            accountMenuOpen: !this.state.accountMenuOpen
        });
    };

    getTimeZones = () => {
        let tzm = Object.assign([], this.state.timeZone)
        this.props.timeZones.map((tz) => {
            if(tz.name.includes('Pacific Time') || tz.name.includes('Mountain Time') || tz.name.includes('Central Time') || tz.name.includes('Eastern Time')) {
                let names = (tz.name.includes('Pacific Time') ? 'Pacific time zone' : (tz.name.includes('Mountain Time') ? 'Mountain time zone' : (tz.name.includes('Central Time') ? 'Central time zone' : (tz.name.includes('Eastern Time') ? 'Eastern time zone' : ''))))
                tzm.push({id: tz.id, name: names})
            }
        })
        this.setState({timeZone: tzm})
    }

    componentWillMount() {
        this.getTimeZones();
    }

    updateView = () => {
        this.setState({
            editView: !this.state.editView
        });
        this.props.reset()
    }

    cancel = (id) => {
        this.setState({
            editView: false
        });
        this.props.reset()
        if (!id) {
            this.props.setListView()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.users !== this.props.users) {
            this.setState({
                editView: false
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            height: window.innerHeight
        });
    }

    // check if any checkbox error is there
    checkErrors = ()=>{
        let errors = validate(this.props.formProps)
        if(!errors)
            return false;
        else if(errors.leadModeError || errors.leadTypeError|| errors.timeZonesError || errors.receiveLeadsOnError){
            return true;
        }
        return false;
    }

    timezoneSelect = (e , currentId)=>{
        if( (currentId==0 && e.target.checked == true)){
            this.props.change('Pacifictimezone',false)
            this.props.change('Mountaintimezone',false)
            this.props.change('Centraltimezone',false)
            this.props.change('Easterntimezone',false)
        } else {
            this.props.change('Alltimezones',false)
        }
    }

    render() {
        const { handleSubmit, submitting, pristine, valid, upadteHireDate, reset } = this.props;
        const { accountMenuOpen } = this.state;
        const viewMode = this.props.initialValues.userId && !this.state.editView;
        let scrollHeight = {
            height: (this.state.height - 201) + 'px'
        }
        return (
            <form name="UserForm" className="gridsBase addUserForm" onSubmit={valid ? handleSubmit : handleSubmit(validate)}>
                <div className="col-grid2 fixed-head">
                    <div className="col"><h1>{this.props.initialValues.userId ? (this.state.editView ? 'Edit user' : "View user") : "Add user"}</h1></div>
                    <div className="col">
                        <div className="floatRight edit-blue-btn tooltipWrap">{this.props.initialValues.userId ? (this.state.editView ? '' : <i onClick={this.updateView} className="zmdi zmdi-hc-fw"></i>) : ""}<span className="tooltiptext">Edit user</span></div>
                    </div>
                </div>

                 <Scrollbars style={scrollHeight} >
                    <div className={(this.state.editView == true  ||  !this.props.listView) ? 'right-section-scroll' : 'right-section-scroll-view'}>
                        <div className="contentInner">

                            <fieldset>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="First name" autoFocus  maxLength={60} disabled={viewMode} requiredClass name="firstName" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field label="Last name" maxLength={40} disabled={viewMode} requiredClass name="lastName" component={CustomInputField} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Email address" type='email' maxLength={100} disabled={viewMode} requiredClass name="emailId" component={CustomInputField} />
                                    </div>
                                    {
                                        viewMode ? <div className="col"/> : <div className="col">
                                            <Field label="Password" maxLength={255} type="password" disabled={viewMode} requiredClass name="password" component={CustomInputField} />
                                        </div>
                                    }
                                </div>
                                {
                                    !viewMode && <div className="col-grid2 single-col">
                                        <div className="col">
                                            <Field label="Confirm password" type="password" requiredClass name="confirmpassword" component={CustomInputField} />
                                        </div>
                                    </div>
                                }
                            </fieldset>
                            <fieldset className="scdFieldset">
                                <div className="col-grid2">
                                    <div className="col datePicker datePicker">
                                        <Field label="Hire date" disabled={viewMode} requiredClass name="hireDate" onChange={this.props.upadteHireDate} component={DatePicker} />
                                        <span className="calendar-icon "><button type="button"><i className="zmdi zmdi-calendar"></i></button></span>
                                    </div>
                                    <div className="col">
                                        {/* <Field label="Quota" disabled={viewMode} requiredClass name="quota" component={CustomInputField} /> */}
                                    </div>
                                </div>
                                <div className="col-grid2 requiredClass">
                                    <div className="col selectSingle requiredClass">
                                        <Field label="Sales team" readOnly={viewMode} options={this.props.salesTeams} requiredClass name="teamUuid" component={SuggestiveSelectBox} />
                                    </div>
                                    <div className="col selectSingle requiredClass">
                                        <Field label="Security group" readOnly={viewMode} options={this.props.roles} requiredClass name="role" component={SuggestiveSelectBox} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Extension" requiredClass disabled={viewMode} maxLength={10} name="extension" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <div className="radioGroupWrap">
                                            <h5>Status </h5>
                                            <Field disabled={viewMode} options={[{ text: 'Active', value: '1' }, { text: 'Inactive', value: '0' }]} requiredClass name="status" component={CustomRadioButton} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col selectSingle requiredClass">
                                        <Field readOnly={viewMode} label="Time zones" options={this.props.timeZones} requiredClass name="timeZone" component={SuggestiveSelectBox} />
                                    </div>
                                    <div className="col">
                                            {  <Field label="Goal per month(profit)" type="number" adornment={true} position={'start'} adornmentValue={'$'} disabled={viewMode} requiredClass name="userGoal" component={CustomInputField} /> }

                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col selectSingle requiredClass">
                                        <Field readOnly={viewMode} label="Department" options={this.props.departments} requiredClass name="departmentId" component={SuggestiveSelectBox} />
                                    </div>

                                        <div className="col">
                                            <Field label="Sales floor manager" disabled={viewMode} name='salesFloorManager' component={CustomCheckBox} />
                                        </div>

                                            {/* <Field label="Executive" disabled={viewMode} name='executive' component={CustomCheckBox} /> */}


                                </div>
                            </fieldset>
                            <fieldset className="addFormLastlist">
                                <div className="col-grid2 ">
                                    <div className="col ">
                                        <div className="col-innerleft">
                                            <div className="radioGroupWrap">
                                                <h5>Leads enabled</h5>
                                                <Field disabled={viewMode} options={[{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }]}  name="leadEnabled" component={CustomRadioButton} />
                                            </div>
                                        </div>
                                        <div className="col-innerright">
                                            <div className="radioGroupWrap">
                                                <h5>Top dog visible</h5>
                                                <Field disabled={viewMode} options={[{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }]} name="topDogVisible" component={CustomRadioButton} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-innerright">
                                        <div className="radioGroupWrap">
                                            <h5>Restricted by IP</h5>
                                            <Field disabled={viewMode} options={[{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }]} name="restrictedByIp" component={CustomRadioButton} />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            {this.props.formProps && this.props.formProps.leadEnabled == 1 &&
                            <fieldset className="addFormLastlist fieldset">
                                <div className="col-grid4">
                                    <div className="col ">
                                        <div className="labelTxt">Lead types</div>
                                        <div className="checkbox-row ml-10">
                                            {this.props.leadType.map((x) => (
                                                <Field disabled={viewMode} label={x.name} name={x.name.split(' ').join('')} component={CustomCheckBox} />
                                            ))}
                                            <Field disabled={viewMode} label="At-risk customers" name="leadSubType_2" component={CustomCheckBox} />
                                        </div>
                                        {!viewMode && validate(this.props.formProps) && validate(this.props.formProps)['leadTypeError'] && <span className="invalidTxt">Atleast one lead type should be selected.</span>}
                                    </div>
                                    <div className="col ">
                                        <div className="labelTxt">Time zones</div>
                                        <div className="checkbox-row ml-10">
                                        {this.state.timeZone.map((x) => (
                                            <Field  disabled={viewMode} label={x.name} name={x.name.split(' ').join('')} onChange={(e)=>this.timezoneSelect(e,x.id)} component={CustomCheckBox} />
                                        ))}
                                        </div>
                                        {!viewMode && validate(this.props.formProps) && validate(this.props.formProps)['timeZonesError'] && <span className="invalidTxt">Atleast one time zone should be selected.</span>}
                                    </div>
                                    <div className="col ">
                                        <div className="labelTxt"> Receive leads on</div>
                                        <div className="checkbox-row ml-10">
                                            {this.state.receiveLeadsOn.map((x) => (
                                                <Field disabled={viewMode} label={x.label} name={x.name} component={CustomCheckBox} />
                                            ))}
                                        </div>
                                        {!viewMode && validate(this.props.formProps) && validate(this.props.formProps)['receiveLeadsOnError'] && <span className="invalidTxt">Atleast one of the above day should be selected.</span>}
                                    </div>
                                    <div className="col ">
                                        <div className="labelTxt">Lead mode</div>
                                        <div className="checkbox-row ml-10">
                                        {this.props.leadMode.map((x) => (
                                            <Field disabled={viewMode} label={x.name} name={x.name.split(' ').join('')} component={CustomCheckBox} />
                                        ))}
                                        </div>
                                        {!viewMode && validate(this.props.formProps) && validate(this.props.formProps)['leadModeError'] && <span className="invalidTxt">Atleast one lead mode should be selected.</span>}
                                    </div>
                                </div>
                            </fieldset>
                            }
                            {
                                viewMode && <fieldset className="publishedInfo">
                                    <div className="viewCreatedDate">
                                        <p><span>Creation date: </span> {this.props.initialValues ? moment(this.props.initialValues.addedDate).format('MM/DD/YYYY HH:mm:ss') : ''}</p>
                                    </div>
                                    <div className="viewModifiedDate">
                                        <p><span>Modified date: </span> {this.props.initialValues ? moment(this.props.initialValues.modifiedDate).format('MM/DD/YYYY HH:mm:ss') : ''}</p>
                                    </div>

                                </fieldset>
                            }
                        </div>

                    </div>
                </Scrollbars>
                {
                    !viewMode && <div className="formAction txtCenter btnWrap">
                        <Button onClick={() => { this.cancel(this.props.initialValues.userId) }} className="btnCancel">Cancel</Button>
                        <Button type="submit" disabled={submitting || !valid || this.props.loading || (this.props.formProps && this.props.formProps.leadEnabled == 1 && this.checkErrors(this.props.syncErrors))} className={(submitting || !valid || this.props.loading || (this.props.formProps && this.props.formProps.leadEnabled == 1 && this.checkErrors(this.props.syncErrors))) ? "btnInactive" : "btnPrimary"}>{this.props.initialValues.userId ? (this.state.editView ? 'Update' : "Update") : "Add"}</Button>
                    </div>
                }
            </form>


        )
    }
}

UserForm.propTypes = {
    classes: PropTypes.object.isRequired,
};


const getLeadType = (leadTypes) => {
	let res = []
	leadTypes.map(x => {
		res.push({
			id: x.id,
			name: x.name
		})
	})
	return res
}

export default connect((state) => ({
    initialValues: getMappedInitial(
        state.user.currentUser,
        state.user.teams,
        getLeadType(state.user.leadType),
        [{ id: '2', name: 'At-risk' }, { id: '3', name: 'Win-back' }],
        state.user.leadMode,
    ),
    formProps: state.form['UserForm'] ? state.form['UserForm'].values : {},
    syncErrors: state.form['UserForm'] ? state.form['UserForm'].syncErrors : {},
    currentUser: state.user.currentUser,
    loading: state.common.loading,
    users: state.user.users,
    timeZones: state.user.timeZones,
    roles: state.user.roles ? lodash.sortBy(state.user.roles, 'name') : [],
    leadType: getLeadType(state.user.leadType),
    leadMode: state.user.leadMode,
    salesTeams: lodash.sortBy(state.user.teams, 'name') || [],
    departments: lodash.sortBy(state.user.departments, 'name') || [],
}), {})(reduxForm({ form: 'UserForm', validate, enableReinitialize: true })(withStyles(styles)(UserForm)));
