// @flow
import * as type from './actionTypes';

const initialState = {
	leadDetails: null,
	leadSettings: [],
	leadSettingsParams: [],
	allLeads: [],
	totalCount: 0,
	stausMaster: [],
	managementReviewReasonMaster: [],
	adminLeads: [],
	adminLeadCount: 0,
	leadAgents: [],
	statusFlag: false,
	leadQuota: {},
	liveUsers: [],
	agentLeadMode: null,
	loading: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case type.SET_LEADSETTINGS:
			return {
				...state,
				leadSettings: action.leadSettings
			};
		case type.SET_LEADSETTINGS_PARAMS:
			return {
				...state,
				leadSettingsParams: action.leadSettings
			}
		case type.SET_ALL_LEADS:
			return {
				...state,
				allLeads: action.allLeads,
				totalCount: action.totalCount
			}
		case type.SET_LEADS_STATUS_MASTER:
			return {
				...state,
				stausMaster: action.stausMaster
			}
		case type.SET_LEADS_MANAGEMENT_REVIEW_REASON_MASTER:
			return {
				...state,
				managementReviewReasonMaster: action.managementReviewReasonMaster
			}
		case type.SET_LEADS_DETAILS:
			return {
				...state,
				leadDetails: action.leadDetails
			}
		case type.SET_ADMIN_LEADS:
			return {
				...state,
				adminLeads: action.adminLeads,
				adminLeadCount: action.adminLeadCount
			}
		case type.SET_LEAD_AGENTS:
			return {
				...state,
				leadAgents: action.leadAgents
			}
		case type.SET_LEADS_CONTACTED:
			return {
				...state,
				leadsContacted: action.leadsContacted,
				leadsContactedCount: action.leadsContactedCount,
				totalUsers: action.totalUsers
			}
		case type.SET_STATUS_FLAG:
			return {
				...state,
				statusFlag: action.statusFlag
			}
		case type.SET_ADMIN_LEAD_QUOTA:
			return {
				...state,
				leadQuota: action.adminLeadQuota
			}
		case type.SET_ONLINE_USER:
			return {
				...state,
				liveUsers: action.liveUsers
			}
		case type.SET_AGENT_LEAD_MODE:
			return {
				...state,
				agentLeadMode: action.agentLeadMode
			}
		case type.SET_LOADER:
			return {
				...state,
				loading: action.flag
			};
		default:
			return state;
	}
};

export default reducer;
