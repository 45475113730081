import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInputField from '../atoms/CustomInputField';
import CustomSelectBox from '../atoms/CustomSelectBox';
import DatePicker from '../atoms/DatePicker';
import utilCommon from '../../helpers/utilCommon';


const validate = (formProps) => {

    const errors = {};

    if (formProps.customerEmailId && !utilCommon.Email_Regex.test(formProps.customerEmailId)) {
        errors.customerEmailId = 'Please enter a valid email address.';
    }

    let requiredFields = []

    if (formProps && formProps.pickupDate == '5') {
        requiredFields.push('pickupStartDate')
        requiredFields.push('pickupEndDate')
    }

    if (formProps && formProps.createdDate == '5') {
        requiredFields.push('createdStartDate')
        requiredFields.push('createdEndDate')
    }

    if (formProps && formProps.bookingDate == '5') {
        requiredFields.push('bookingStartDate')
        requiredFields.push('bookingEndDate')
    }
    // if(formProps && formProps.proNumber && ( formProps.proNumber <1 ||formProps.proNumber >9999999999)){
    //     formProps.proNumber = 'Please enter a valid pro number'
    // }
    if (formProps.shipmentFromZip && !utilCommon.AlphaNumericWhiteSpace_Regex.test(formProps.shipmentFromZip)) {
        errors.shipmentFromZip = 'Alphanumeric values only.';
    } else if (formProps.shipmentFromZip && (formProps.shipmentFromZip.length < 4 || formProps.shipmentFromZip.length > 8)) {
        errors.shipmentFromZip = 'Zip must be between 4 to 8 length';
    }
    if (formProps.shipmentToZip && !utilCommon.AlphaNumericWhiteSpace_Regex.test(formProps.shipmentToZip)) {
        errors.shipmentToZip = 'Alphanumeric values only.';
    } else if (formProps.shipmentToZip && (formProps.shipmentToZip.length < 4 || formProps.shipmentToZip.length > 8)) {
        errors.shipmentToZip = 'Zip must be between 4 to 8 length';
    }

    requiredFields.map((x) => {
        if (!formProps[x]) {
            errors[x] = 'This is a required field.';
        }
        if (formProps[x] && formProps[x].length === 0) {
            errors[x] = 'This is a required field.';
        }
    })

    return errors;
}

export class ShipmentAdvancedSearchForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { handleSubmit, submitting, valid, open, handleClose, formProps, initialValues, reset } = this.props;
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="scroll-dialog-title" maxWidth={'md'} className="DialogBox large" >
                <form name="ShipmentAdvancedSearchForm" onSubmit={valid ? handleSubmit : handleSubmit(validate)}  className="mdlScroll">
                    <DialogTitle className="DialogTitle">Search shipment<i className="zmdi zmdi-close" onClick={() => this.props.handleOpen(0,true)}/></DialogTitle>
                    <DialogContent className="DialogBoxcontent">
                        <DialogContentText>
                            <fieldset className="row-margin-b20">
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Shipment Id" autoFocus type="number" name="shipmentUuid" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field label="Pro number" name='proNumber' type="text" component={CustomInputField} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Pickup number" autoFocus type="text" name="pickupNumber" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field label="Customer company" maxLength={200} name="customerCompany" component={CustomInputField} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Customer first name" maxLength={100} name="customerFirstName" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field label="Customer last name" maxLength={100} name="customerLastName" component={CustomInputField} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Customer email address" type="email" name="customerEmailId" component={CustomInputField} />
                                    </div>
                                    <div className="col ">
                                        <Field label="Date created" name="createdDate" options={this.props.dateTypes} component={CustomSelectBox} />
                                        {this.props.formProps && this.props.formProps.createdDate == 5 &&
                                        <div className="col-grid2">
                                            <div className="col datePicker">
                                                <Field label="Start date" name='createdStartDate'
                                                       component={DatePicker}/>
                                                <span className="calendar-icon right12"><button type="button">
                                                    <i className="zmdi zmdi-calendar"/></button></span>
                                            </div>
                                            <div className="col datePicker">
                                                <Field label="End date" name='createdEndDate' component={DatePicker}/>
                                                <span className="calendar-icon right1"><button type="button">
                                                    <i className="zmdi zmdi-calendar"/></button></span>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Booking date" name='bookingDate' options={this.props.dateTypes} component={CustomSelectBox} />
                                        {this.props.formProps && this.props.formProps.bookingDate == 5 &&
                                        <div className="col-grid2">
                                            <div className="col datePicker">
                                                <Field label="Start date" name='bookingStartDate'
                                                       component={DatePicker}/>
                                                <span className="calendar-icon right12"><button type="button">
                                                    <i className="zmdi zmdi-calendar"/></button></span>
                                            </div>
                                            <div className="col datePicker">
                                                <Field label="End date" name='bookingEndDate' component={DatePicker}/>
                                                <span className="calendar-icon right1"><button type="button">
                                                    <i className="zmdi zmdi-calendar"/></button></span>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col">
                                        <Field label="Pickup date" name='pickupDate' options={this.props.dateTypes} component={CustomSelectBox} />
                                        {this.props.formProps && this.props.formProps.pickupDate == 5 &&
                                        <div className="col-grid2">
                                            <div className="col datePicker">
                                                <Field label="Start date" name='pickupStartDate'
                                                       component={DatePicker}/>
                                                <span className="calendar-icon right12"><button type="button">
                                                    <i className="zmdi zmdi-calendar"/></button></span>
                                            </div>
                                            <div className="col datePicker">
                                                <Field label="End date" name='pickupEndDate'
                                                       component={DatePicker}/>
                                                <span className="calendar-icon right1"><button type="button">
                                                    <i className="zmdi zmdi-calendar"/></button></span>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                 <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Ship from zipcode" maxLength={8} name="shipmentFromZip" component={CustomInputField} />
                                    </div>
                                     <div className="col">
                                         <Field label="Ship to zipcode" maxLength={8} name="shipmentToZip" component={CustomInputField} />
                                     </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Ship from city" maxLength={200} name="shipmentFromCity" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field label="Ship to city" maxLength={200} name="shipmentToCity" component={CustomInputField} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Ship from state" name="shipmentFromStateId" options={this.props.states} component={CustomSelectBox} />
                                    </div>
                                    <div className="col">
                                        <Field label="Ship to state" name="shipmentToStateId" options={this.props.states} component={CustomSelectBox} />
                                    </div>
                                </div>
                            </fieldset>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="DialogBoxfooter">
                        <Button onClick={() => { handleClose(), reset() }} className="btnCancel">Reset</Button>
                        <Button type="submit" disabled={submitting || !valid} className={(submitting || !valid) ? "btnInactive" : "btnPrimary"}>Search</Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

export default connect(state => {
    return {
        initialValues: {pickupDate:'0',bookingDate:'0',createdDate:'0'},
        states: utilCommon.nullCheck(state.common.masterData) ? state.common.masterData.stateList : [],
        formProps: state.form.ShipmentAdvancedSearchForm ? state.form.ShipmentAdvancedSearchForm.values : {},
        dateTypes: [{ id: '0', name: 'Any' }, { id: '1', name: 'Today' }, { id: '2', name: 'Yesterday' }, { id: '3', name: 'Last 7 days' }, { id: '4', name: 'This month' }, { id: '5', name: 'Date range' }],
    }
}, {})(reduxForm({
    form: 'ShipmentAdvancedSearchForm',
    validate, enableReinitialize: true
})(ShipmentAdvancedSearchForm));
