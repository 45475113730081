// @flow
// /file/{fileId}

export const SET_QUOTE = 'quoteManagement/SET_QUOTE';
export const SET_CUSTOMERS = 'quoteManagement/SET_CUSTOMERS';
export const SET_CUSTOMER = 'quoteManagement/SET_CUSTOMER';

export const SET_QUOTE_QUERY = 'quoteManagement/SET_QUOTE_QUERY';
export const SET_QUOTE_RESULTS = 'quoteManagement/SET_QUOTE_RESULTS';
export const SET_QUOTE_STEP = 'quoteManagement/SET_QUOTE_STEP';
export const SET_USER_FILTER = 'quoteManagement/SET_USER_FILTER';
export const SET_FACILITY_TYPES = 'quoteManagement/SET_FACILITY_TYPES';
export const SET_CONTRACTS = 'quoteManagement/SET_CONTRACTS';

export const SET_ORIGIN_CODE = 'quoteManagement/SET_ORIGIN_CODE';
export const SET_DESTINATION_CODE = 'quoteManagement/SET_DESTINATION_CODE';
