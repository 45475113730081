// @flow

// /file/{fileId}
export const SET_ACCESSORIAL_DATA = 'contractManagement/SET_ACCESSORIAL_DATA';
export const SET_CARRIERS = 'contractManagement/SET_CARRIERS';
export const SET_CUSTOMERS = 'contractManagement/SET_CUSTOMERS';
export const SET_STATES = 'contractManagement/SET_STATES';
export const SET_COUNTRIES = 'contractManagement/SET_COUNTRIES';
export const SET_CONTRACTINFO = 'contractManagement/SET_CONTRACTINFO';
export const SET_LIABILITYCOVERAGE = 'contractManagement/SET_LIABILITYCOVERAGE';
export const SET_NEGOTIATEDLANE = 'contractManagement/SET_NEGOTIATEDLANE';
export const SET_GEOGRAPHICLANE = 'contractManagement/SET_GEOGRAPHICLANE';
export const SET_COMMODITYTYPE = 'contractManagement/SET_COMMODITYTYPE';
export const CLEAR_FORM = 'contractManagement/CLEAR_FORM';
export const SET_CURRENTLIABILITYCOVERAGE = 'contractManagement/SET_CURRENTLIABILITYCOVERAGE';
export const SET_CURRENTCOMMODITYTYPE = 'contractManagement/SET_CURRENTCOMMODITYTYPE';
export const SET_EXCLUDED_FREIGHT_CLASSES = 'contractManagement/SET_EXCLUDED_FREIGHT_CLASSES';
export const SET_CONTRACT_LOGS = 'contractManagement/SET_CONTRACT_LOGS';
export const REDIRECT_NEGOTIATEDLANE ='contractManagement/REDIRECT_NEGOTIATEDLANE';