// @flow
import * as type from './actionTypes';
import lodash from "lodash";
import {SET_MANUAL_PURCHASE_INSURANCE_NOTIFICATION} from "./actionTypes";

const runnerState = {
	WAITING: 'WAITING',
	RUN: 'RUN',
	ALLOWED: 'ALLOWED'
};

const initialState = {
	countries: [],
	states: [],
	notes: [],
	accessorials: [],
	shipmentMasterData: {},
	timezones: [],
	shipmentState: {},
	facilityTypes: [],
	totalTaskCount: 0,
	shipments: [],
	totalcount: 0,
	addresses: [],
	sectionLoading: false,
	products: [],
	shipmentProducts: [],
	carriers: [],
	agents: [],
	activeAgents: [],
	reassignFlag: false,
	shipmentAlert: {},
	masterData: {},
	currentAlert: {},
	transactionLogs: [],
	transactions: [],
	transactionDetails: [],
	isCouponValid: false,
	totalCost: 0,
	totalCharge: 0,
	payments: [],
	totalAmount: 0,
	discountAmount: 0.00,
	maxDiscountAmount: 0.00,
	isInsuranceValid: false,
	insuranceAmount: 0.00,
	localCards: [],
	insuranceExcludedId: "",
	insuranceExcludedItemName: "",
	totalDocumentCount: 0,
	documents: [],
	documentStatus: [],
	currentDocument: { document: [] },
	documentPageNumber: 1,
	documentPageSize: 20,
	documentSortColumn: 'modifiedDate',
	documentSortOrder: 'desc',
	previewContent: '',
	shipmentCharge: 0,
	shipmentCost: 0,
	shipmentPayment: 0,
	previewFileName: '',
	trackingInfoList: [],
	trackingHeader: {},
	cancellationDetails: {},
	teams: [],
	contracts: [],
	carrierDetails: {},
	bookManualShipmentManually : false,
	falveyDetails:{},
	termsCondition:{},
	searchKeyword : null,
	tasks:[],
	shipmentLogs: [],
	allShipmentLogs: [],
	shipmentLogsCount: 0,
	cspURL: null,
	shipmentURL: null,
	updatedProducts: null,
	updatedProductsFlag: false,
	runnerProducts:[],
	documentInvoicesInfo: {},
	cancellationInfo: {},
	insurancePurchaseResponse: "",
	shipmentProductsCount: null,
	shipmentProductAdded: false,
	shipmentProductDeleted: false,
	shipmentElsFactor: null,
	processingPaymentAndBook: false,
	initialTask: {},
	editTaskFlag: false
};

const reducer = (state = initialState, action) => {
	let runnerProducts = state.runnerProducts

	switch (action.type) {
		case type.SET_UPDATED_PRODUCTS:
			const out = {
				...state,
				updatedProductsFlag: action.updatedProductsFlag
			}
			if (action.updatedProducts){
				out.updatedProducts = action.updatedProducts;
			}

			return out;
		case type.SET_NOTES:
			return {
				...state,
				notes: action.notes,
				totalNotesCount: action.totalNotesCount
			};
		case type.SET_LOADER:
			return {
				...state,
				sectionLoading: action.loading
			}
		case type.SET_SEARCH:
			return {
				...state,
				searchKeyword : action.keyword
			}
		case type.SET_LOCALCARDS:
			let currentCards = state.localCards
			currentCards = currentCards.concat([action.card])
			return {
				...state,
				localCards: currentCards
			}
		case type.CLEAR_LOCALCARDS:
			return {
				...state,
				localCards: []
			}
		case type.SET_AGENTS:
			return {
				...state,
				agents: action.agents
			}
		case type.SET_TRANSACTIONS:
			return {
				...state,
				transactions: action.transactions
			}
		case type.SET_TRANSACTION_DETAILS:
			return {
				...state,
				transactionDetails: action.transactionDetails,
				totalCost: action.totalCost,
				totalCharge: action.totalCharge
			}
		case type.SET_TRANSACTION_LOGS:
			return {
				...state,
				transactionLogs: action.transactionLogs
			}

		case type.SET_SHIPMENT_LOGS:
			return {
				...state,
				shipmentLogs: action.logs,
				shipmentLogsCount:action.totalResults
			}
		case type.SET_ALL_SHIPMENT_LOGS:
			return {
				...state,
				allShipmentLogs: action.allShipmentLogs,
			}
		case type.SET_ACTIVE_AGENTS:
			return {
				...state,
				activeAgents: action.activeAgents
			}
		case type.SET_FACILITY_TYPE:
			return {
				...state,
				facilityTypes: action.facilityList
			}
		case type.SET_SHIPMENT_MASTER:
			return {
				...state,
				shipmentMasterData: action.shipmentMasterData,
			};
		case type.SET_MASTER_DATA:
			return {
				...state,
				masterData: action.masterData
			}
		case type.SET_COUNTRIES:
			return {
				...state,
				countries: action.countries,
			};
		case type.SET_STATES:
			return {
				...state,
				states: action.states,
			};
		case type.SET_ADDRESS:
			return {
				...state,
				addresses: action.addresses,
				totalAddressCount: action.totalAddressCount
			};
		case type.SET_PRODUCTS:
			return {
				...state,
				products: action.products,
				totalProductCount: action.totalProductCount
			};
		case type.SET_SHIPMENT_PRODUCTS:
			return {
				...state,
				shipmentProducts: action.shipmentProducts,
				shipmentProductsCount: action.shipmentProductsCount,
				shipmentProductAdded: action.shipmentProductAdded,
				shipmentProductDeleted: action.shipmentProductDeleted,
				shipmentElsFactor: action.shipmentElsFactor
			};
		case type.SET_ACCESSORIALS:
			return {
				...state,
				accessorials: action.accessorials
			}
		case type.SET_SHIPMENTS:
			return {
				...state,
				shipments: action.shipments,
				totalcount: action.totalCount
			};
		case type.SET_REASSIGN_FLAG:
			return {
				...state,
				reassignFlag: action.reassignFlag
			};
		case type.SET_CURRENT_SHIPMENT:

			let value = { ...action.currentShipment };
			if (action.currentShipment && action.currentShipment.accessorials && state.accessorials) {
				value.accessorials = action.currentShipment.accessorials.filter(({id}) => state.accessorials.some(acc => acc.id === id));
			}
			const CURRENT_SHIPMENT = {
				...state,
				shipmentState: value
				//customerState: state.customers.filter(x=>x.customerUuid==action.customer)[0],
			};

			return CURRENT_SHIPMENT;
		case type.CLEAR_INITIAL_DATA:
			return {
				...state,
				shipmentState: {},
				initialCarrier: [],
				shipmentAlert: {},
				loadCarrierFlag: false
			}
		case type.SET_SHIPMENT_ALERT:
			return {
				...state,
				shipmentAlert: action.shipmentAlert
			}
		case type.SET_CURRENT_ALERT:
			return {
				...state,
				currentAlert: action.currentAlert
			}
		case type.SET_COUPON_VALIDATE_FLAG:
			return {
				...state,
				isCouponValid: action.isCouponValid,
				discountAmount: action.discountAmount,
				discountType: action.discountType,
				maxDiscountAmount: action.maxDiscountAmount,
			}
		case type.SET_INSURANCE_VALIDATE_FLAG:
			return {
				...state,
				isInsuranceValid: action.isInsuranceValid,
				insuranceAmount: action.insuranceAmount,

			}
		case type.SET_EXCLUDE_ITEM:
			return {
				...state,
				insuranceExcludedId: action.insuranceExcludedId,
				insuranceExcludedItemName: action.insuranceExcludedItemName
			}
		case type.SET_PAYMENTS:
			return {
				...state,
				payments: action.payments,
				totalAmount: action.totalAmount
			}
		case type.SET_DOCUMENTS:
			return {
				...state,
				documents: action.documents,
				totalDocumentCount: action.totalDocumentCount
			}
		case type.CLEAR_DOCUMENTS:
			return {
				...state,
				documents: [],
				totalDocumentCount: 0
			}
		case type.FILE_UPLOADED_COUNT:
			return {
				...state,
				documentStatus: Array.apply(null, { length: action.documentCount }).map(function (value, index) { return { sentForUploading: false } })
			}
		case type.FILE_UPLOADED:
			let documentStatus = state.documentStatus;
			documentStatus[action.uploadedIndex] = { sentForUploading: true, uploadedStatus: action.uploadedflag };
			return {
				...state,
				documentStatus: documentStatus
			}
		case type.CLEAR_DOCUMENT_STATUS:
			return {
				...state,
				documentStatus: []
			}
		case type.SET_INITIAL_DOCUMENT:
			return {
				...state,
				currentDocument: action.currentDocument
			}
		case type.BOOK_SHIPMENT_MANUALLY:
			return {
				...state,
				bookManualShipmentManually : action.flag
			}
		case type.UPDATE_DOCUMENT_PAGING:
			return {
				...state,
				documentPageNumber: action.documentPageNumber,
				documentPageSize: action.documentPageSize,
				documentSortColumn: action.documentSortColumn,
				documentSortOrder: action.documentSortOrder,
			}
		case type.SET_PREVIEW_CONTENT:
			return {
				...state,
				previewContent: action.previewContent,
				previewFileName: action.previewFileName
			}
		case type.SET_SHIPMENT_CHARGES:
			return {
				...state,
				shipmentCharge: action.shipmentCharges.shipmentCharge,
				shipmentCost: action.shipmentCharges.shipmentCost,
				shipmentPayment: action.shipmentCharges.shipmentPayment,
			}
		case type.SET_TRACKING_INFO:
			return {
				...state,
				trackingInfoList: action.trackingInfoList
			}
		case type.SET_TRACKING_HEADER:
			return {
				...state,
				trackingHeader: action.trackingHeader
			}
		case type.CLEAR_TRACKING_INFO:
			return {
				...state,
				trackingHeader: {},
				trackingInfoList: []
			}
		case type.SET_CANCELLATION_FORM_DETAILS:
			return {
				...state,
				cancellationDetails: action.cancellationDetails
			}
		case type.SET_TEAMS:
			return {
				...state,
				teams: action.teams
			}
		case type.SET_CONTRACTS:
			return {
				...state,
				contracts: action.contracts
			}
		case type.SET_CARRIER_DETAILS:
			return {
				...state,
				carrierDetails: action.carrierDetails
			}
		case type.SET_FALVEY:
			return {
				...state,
				falveyDetails:action.falveyDetails
			}
		case type.SET_TERMS:
			return {
				...state,
				termsCondition:action.termsCondition
			}
		case type.SET_TASKS:
			return {
				...state,
				tasks: action.tasks,
				totalTaskCount: action.totalTaskCount
			};
		case type.SET_CARRIERS:
			return {
				...state,
				carriers:action.carriers
			}
		case type.SET_SHIPMENT_URL:
			return {
				...state,
				shipmentURL:action.shipmentURL
			}
		case type.SET_CSP_URL:
			return {
				...state,
				cspURL:action.cspURL
			}
		case type.SET_AGENT_UUID:
			return {
				...state,
				agentUserUuid: action.agentUserUuid
			}
    	case type.SET_BOL_STATUS:
			const res = {...state};
			res.shipmentState.bolStatus = action.status;
			res.shipmentState.documentsGenerated = action.documentsGenerated;
			return res;
		case type.ADD_PRODUCT_RUNNER:
			const active = lodash.filter(runnerProducts, {state: runnerState.RUN});
			const waiting = lodash.filter(runnerProducts, {state: runnerState.WAITING});

			let currentState = runnerState.WAITING;
			if (active.length === 0 && waiting.length === 0){
				currentState = runnerState.ALLOWED;
			}
			runnerProducts.push({
				id: action.id,
				state: currentState
			});

			return {
				...state,
				runnerProducts
			};

		case type.RUN_PRODUCT_RUNNER:
			const toRun = lodash.findIndex(runnerProducts,{id: action.id });
			if (toRun !== -1){
				runnerProducts[toRun].state = runnerState.RUN
			}

			return {
				...state,
				runnerProducts
			};

		case type.DONE_PRODUCT_RUNNER:
			const idx = lodash.findIndex(runnerProducts,{id: action.id });
			runnerProducts.splice(idx, 1);

			const waitingIdx = lodash.findIndex(runnerProducts,{state: runnerState.WAITING });

			if (waitingIdx !== -1){
				runnerProducts[waitingIdx].state = runnerState.ALLOWED
			}

			return {
				...state,
				runnerProducts
			};
		case type.SET_DOCUMENT_INVOICES_INFO:
			return {
				...state,
				documentInvoicesInfo: {...action.data}
			}
		case type.CLEAR_DOCUMENT_INVOICES_INFO:
			return {
				...state,
				documentInvoicesInfo: {}
			}
		case type.SET_TL_PICKUP_ADDRESS:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlShipmentData: {
						...state.shipmentState.tlShipmentData,
						pickupAddress: action.payload
					}
				}
			};
		case type.SET_TL_DROP_ADDRESS:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlShipmentData: {
						...state.shipmentState.tlShipmentData,
						dropAddress: action.payload
					}
				}
			};

		case type.SUBMIT_TL_PICKUP_ADDRESS:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlShipmentData: {
						...state.shipmentState.tlShipmentData,
						pickupAddress: action.payload
					}
				}
			};

		case type.SUBMIT_TL_DROP_ADDRESS:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlShipmentData: {
						...state.shipmentState.tlShipmentData,
						dropAddress: action.payload
					}
				}
			};

		case type.SET_ORDER_STATUSES:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlTrackingData: {
						...state.shipmentState.tlTrackingData,
						orderStatuses: action.orderStatuses
					}
				}
			};

		case type.SET_TRIP_EVENTS:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlTrackingData: {
						...state.shipmentState.tlTrackingData,
						tripEvents: action.tripEvents
					}
				}
			};

		case type.SET_LOCATION_UPDATES:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlTrackingData: {
						...state.shipmentState.tlTrackingData,
						locationUpdates: action.locationUpdates
					}
				}
			};

		case type.SET_SCHEDULE_ALERTS:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					tlTrackingData: {
						...state.shipmentState.tlTrackingData,
						scheduleAlerts: action.scheduleAlerts
					}
				}
			};
		case type.SET_CANCELLATION_INFO:
			return {
				...state,
				cancellationInfo: action.cancellationInfo
			}

		case type.SET_ONLINE_CUSTOMER:
			return {
				...state,
				liveCustomers: action.liveCustomers
			};

		case type.SET_ONLINE_CUSTOMER_SHIPMENTS:
			return {
				...state,
				onlineCustomerShipments: action.onlineCustomerShipments,
				totalOnline: action.totalOnline
			};

		case type.ADD_SHIPMENT_PRODUCT:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					products: [...state.shipmentState.products, action.newProduct]
				}
			}

		case type.REMOVE_TL_SHIPMENT_PRODUCT_BY_INDEX:
			return {
				...state,
				shipmentState: {
					...state.shipmentState,
					products: (state.shipmentState.products || []).filter((_, index) => index !== action.productIndex)
				}
			}

		case type.SET_MANUAL_PURCHASE_INSURANCE_NOTIFICATION:
			return {
				...state,
				insurancePurchaseResponse: action.insurancePurchaseResponse
			}

		case type.SET_PROCESSING_PAYMENT_AND_BOOK_STATUS:
			return {
				...state,
				processingPaymentAndBook: action.processingPaymentAndBook
			}

		case type.SET_INITIAL_TASK:
			return {
				...state,
				initialTask: action.currentTask,
				editTaskFlag: true
			}

		default:
			return state;
		}
};

export default reducer;
