import React, {Component} from "react";
import {connect} from "react-redux";
import moment from 'moment';
import lodash from 'lodash'

const now = moment().format().substring(0, 10);

class RewardLogsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: [],
            open: false,
            accountMenuOpen: false,
            hireDate: now,
            pageSize: 50,
            pageNumber: 1,
            sortColumn: 'firstName',
            sortOrder: 'desc',
            cutomerLogTable: -1
        };
    }

    render() {
        return (
            <div>
                <div className="CustomerContractList">
                    <table cellPadding="0" cellSpacing="0" border="0" className="tbl-data responsiveTbl">
                        <thead>
                        <tr>
                            <td className="resCol100" width="20%">
                                <div className="sort">Date & time</div>
                            </td>
                            <td className="resColHide" width="40%">
                                <div className="sort">Old value(s)</div>
                            </td>
                            <td className="resColHide">
                                <div className="sort">New value(s)</div>
                            </td>

                        </tr>
                        </thead>
                        {this.props.logs.map((log, index) => (
                            ((this.props.allowOnlyTen && index < 10) || (!this.props.allowOnlyTen)) &&
                            <tbody key={index}>
                            <tr key={log.masterUuid}>
                                <td className="relativeRes">
                                <span
                                    className="dateFormate1">{moment(log.modifiedDate).format('MM/DD/YYYY hh:mm:ss A')}</span>
                                    <span>Source:<strong>{log.source}</strong></span><br/>
                                    <span>By:<strong>{log.updatedBy}</strong></span><br/>
                                    <span>Action:<strong>{log.action}</strong></span>
                                    <div className="collapsible responsive"><i
                                        className={this.state.cutomerLogTable == index ? "zmdi zmdi-minus" : "zmdi zmdi-plus"}/>
                                    </div>
                                </td>
                                <td data-head="Old value(s)"
                                    className={this.state.cutomerLogTable == index ? "dataHead" : "resColcollapse"}>{getArr(log.Activities.old).map(x => (
                                    <div
                                        key={x}>{getTXT(log.Activities.old[x])}</div>))}
                                </td>
                                <td data-head="New value(s)"
                                    className={this.state.cutomerLogTable == index ? "dataHead" : "resColcollapse"}>{getArr(log.Activities.attributes).map(x => (
                                    <div className="multiline"
                                         key={x}>{getTXT(log.Activities.attributes[x])}</div>))}
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        );
    }
}

const getArr = (obj) => {
    return Object.keys(obj)
}

const getTXT = (obj) => {
    if (lodash.isObject(obj)) {
        return obj[Object.keys(obj)[0]];
    } else {
        return obj
    }
}

export default connect(state => ({}), {})(RewardLogsList);
