import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import Login from './components/pages/Login'
import ForgotPassword from './components/pages/ForgotPassword'
import ResetPassword from './components/pages/ResetPassword'
import HomePageContainer from './containers/HomePageContainer';
import utilCommon from './helpers/utilCommon';
import './assets/css/material-design-iconic-font.css'
import './components/themes/main.css'
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TransactionLogList from './components/organisms/TransactionLogList';
import CustomerAllContacts from './components/organisms/CustomerAllContacts';
import Logs from './components/organisms/Logs';
import CarrierLogs from './components/organisms/CarrierLogs';
import ContractLogs from './components/organisms/ContractLogs';
import loginService from './services/api/loginService';
import HealthCheck from './components/pages/HealthCheck';
import RewardLogs from "./components/organisms/RewardLogs";
import StoreCreditLogList from "@organisms/StoreCreditLogList";
import {Analytics} from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import RewardsPointsLogList from "@organisms/RewardsPointsLogList";

Auth.configure({
	Auth: {
		identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
		region: process.env.REACT_APP_AWS_REGION
	}
});

Analytics.configure({
	AWSPinpoint: {
		appId: process.env.REACT_APP_AWS_PINPOINT_APP_ID,
		region: process.env.REACT_APP_AWS_REGION,
		mandatorySignIn: false,
	}
})

const theme = createMuiTheme({
	overrides: {
		MuiCheckbox: {
			root: {
				color: '#4a4a4a',
				'&$checked': {
					color: '#0093d0 !important',
				},
			},
			checked: {
				color: '#0093d0',
			},
		},
		MuiRadio: {
			root: {
				color: '#cdcdcd',
				'&$checked': {
					color: '#0093d0 !important',
				},
			},
			checked: {
				color: '#0093d0',
			},
		},
		MuiListItem: {
			root: {

			},
		},
		MuiMenuItem: {
			root: {
				'&$selected': {
					color: '#0093d0',
				},
			},
		},
		MuiCircularProgress: {
			root: {
				color: '#0093d0 !important',
			},
		}
	},

});
const systemPermissionArr = [
	'/home/user-management',
	`/home/teams`,
	`/home/carrier-management`,
	'/home/contract-management',
	'/home/transit-time',
	'/home/global-discount',
	'/home/fuel-surcharge',
	'/home/facility-type',
	'/home/accessorials',
	'/home/manage-coupon',
	'/home/system-wide-notification',
	'/home/tms-terms-conditions',
	'/home/financial-settings',
	'/home/approved-ip',
	'/home/security-levels',
	'/home/security-roles',
	'/home/lead-settings',
	'/home/admin-tool',
	'/home/rewards-settings'
]
const customerPermissionArr = ['/home/customer-management']
const shipmentPermissionArr = ['/home/shipment-management', '/home/tl-shipment-management']

const getRedirect = (baseUrl) => {
	let url = baseUrl
	// check permission if not allowes redirect to /home
	if (utilCommon.getSession('redirectionUrl')) {
		if(!utilCommon.isPermitted(1,1,1) && systemPermissionArr.filter(x=>utilCommon.getSession('redirectionUrl').startsWith(x)) ){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(2,6,1) && customerPermissionArr.filter(x=>utilCommon.getSession('redirectionUrl').startsWith(x))){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(4,18,1) && utilCommon.getSession('redirectionUrl') == shipmentPermissionArr[0]){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(3,17,1) && utilCommon.getSession('redirectionUrl') == '/home/processing-review-queue'){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(6,45,1) && utilCommon.getSession('redirectionUrl') == '/home/customer-ar-report'){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(3,14,1) && utilCommon.getSession('redirectionUrl') == '/home/new-ba-queue'){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(3,15,1) && utilCommon.getSession('redirectionUrl') == '/home/in-progress-ba-queue'){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(3,16,1) && utilCommon.getSession('redirectionUrl') == '/home/carrier-dispute-queue'){
			url = '/home'
		}
		else if(!utilCommon.isPermitted(3,51,1) && utilCommon.getSession('redirectionUrl') == '/home/processed-ba-queue'){
			url = '/home'
		}
		else{
			url = utilCommon.getSession('redirectionUrl')
		}
		utilCommon.removeSession('redirectionUrl')
	}
	return url
}

const getRedirectUrl = (user) => {
	if (window.location.pathname.indexOf('/v1/shipment/external-download') > -1) {
		return false
	}

	if (window.location.pathname.length > 5) {
		localStorage.setItem('redirectionUrl', window.location.pathname)
	}
	let url = '/'
	if (user) {
		if (window.location.pathname === '/') {
			url = getRedirect(process.env.REACT_APP_BASEPATH)
		} else {
			url = window.location.pathname + window.location.search
		}
	} else {
		if (window.location.pathname.indexOf('resetpassword') > -1) {
			url = '/resetpassword/' + window.location.pathname.split('/')[2]
		}
		if(window.location.pathname.indexOf('health-check')>-1){
			url = window.location.pathname
		}
	}
	// user ? (window.location.pathname == '/' ? (getRedirect(process.env.REACT_APP_BASEPATH)) : window.location.pathname) : window.location.pathname.indexOf('resetpassword') > -1 ? '/resetpassword/' + window.location.pathname.split('/')[2] : '/';
	return url;
};

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {};
		this.ws;
	}

	startWebsocket() {
			let self = this;
			let timeIntrvlMsg;
      let url = (window.location.protocol == 'https:' ? 'wss://' : 'ws://') + process.env.REACT_APP_SOCKET_URL.replace('http://', '').replace('https://', '') + '/v1/notifications';
			this.ws = new WebSocket(url, [utilCommon.getSession('accessToken')])
			this.ws.onerror = () => {
				if(self.ws)
					self.ws.close();
				self.ws = null
			}
			this.ws.onopen = () => {
				var t = {};
				self.ws.send(JSON.stringify(t));
			}
			this.ws.onmessage = function (event) {
				if(timeIntrvlMsg) clearTimeout(timeIntrvlMsg)
				timeIntrvlMsg=setTimeout(()=>{
					if (event.data && JSON.parse(event.data).content && JSON.parse(event.data).content) {
						let res = []
						JSON.parse(event.data).content.map(x => {
							utilCommon.showAgentNotification(x.notifiactionText, x.notificationClassification,x.notifcationType)
							res.push(x.notificationUuid)
						})
						if (res.length) {
							//self.props.updateNotificationFeed({ notificationUuid: res })
							loginService.updateNotificationFeed({ notificationUuid: res });
						}

						// self.ws.close()
					}
				},2500);
			}
			this.ws.onclose = function (event) {
				// connection closed, discard old websocket and create a new one in 5s
				self.ws = null
			}

	}
	checkPeriodically=function(){
		var selfRef=this
		if(this.intrvl)
			clearInterval(this.intrvl)
		this.intrvl=setInterval(()=>{
			if(!selfRef.ws || (selfRef.ws.readyState && selfRef.ws.readyState!=1)){
				setTimeout(selfRef.startWebsocket.call(selfRef), 1000);
			}
		},20000);
	}
	initWebSocket(){
		if (utilCommon.getSession('accessToken')){
			if(!this.ws || (this.ws.readyState && this.ws.readyState!=1)){
				this.startWebsocket();
			}
			this.checkPeriodically();
		}
	}

	componentDidMount() {
		this.props.setDispatch()
		this.initWebSocket();
	}

	render() {
		return (
			<BrowserRouter>
				<div className="App">
					<ToastContainer />
					<MuiThemeProvider theme={theme}>
						<Redirect exact from="/" to={getRedirectUrl(this.props.user)} />
						<Route path='/' exact component={Login} />
						<Route path='/forgotpassword' component={ForgotPassword} />
						<Route path='/resetpassword/:id' component={ResetPassword} />
						<Route path='/transactionlogs/:id' component={TransactionLogList} />
						<Route path='/store-credit-logs/:id' component={StoreCreditLogList} />
						<Route path='/customerlogs/:id' component={Logs} />
						<Route path='/rewardlogs' component={RewardLogs} />
						<Route path='/shipmentlogs/:id' component={Logs} />
						<Route path='/carrierlogs/:id' component={CarrierLogs} />
						<Route path='/contractlogs/:id' component={ContractLogs} />
						<Route path='/contactlogs/:id' component={CustomerAllContacts} />
						<Route path='/health-check' component={HealthCheck} />
						<Route path='/rewards-points-logs/:id' component={RewardsPointsLogList} />
						<Route path={`${process.env.REACT_APP_BASEPATH}`} render={(routeProps) => this.props.user ? <HomePageContainer {...routeProps} /> : ''} />
					</MuiThemeProvider>
				</div>
			</BrowserRouter>
		);
	}
}


const mapStateToProps = state => ({
	user: (utilCommon.getSession('accessToken') || utilCommon.getSession('refreshToken')) || state.login.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
	setDispatch: () => {
		Window['dispatch'] = dispatch
	},
	updateNotificationFeed: (inputDTO) => {
		loginService.updateNotificationFeed(inputDTO)
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
