import axios from 'axios';
import { reset } from 'redux-form';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon from '../../helpers/utilCommon'
import * as actions from '../../store/user/actions'
import * as commonAction from '../../store/common/actions'
import * as type from '../../store/user/actionTypes'


export const addUser = (dispatch, lang, dictionary, inputDTO, flag) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('user', 'addUser');
  dispatch(commonAction.setLoader(true))
  axios[inputDTO.userId ? 'put' : 'post'](url, inputDTO, params)
    .then((response) => {
      if (response.data.success) {
        utilCommon.setSession('c_user', "1")
        if (inputDTO.userId && inputDTO.userId == utilCommon.getSession('userId')) {
          utilCommon.setSession('fname', inputDTO.firstName)
          utilCommon.setSession('lname', inputDTO.lastName)
          utilCommon.setSession('username', inputDTO.firstName)

        }
        dispatch(commonAction.setLoader(false))
        if (flag) {
          utilCommon.showNotification('Lead setting updated successfully', dictionary, lang)
        } else {
          utilCommon.showNotification(response.data.message, dictionary, lang)
        }
        getUsers(dispatch, lang, dictionary, {
          pageNumber: inputDTO.pageNumber,
          pageSize: inputDTO.pageSize,
          sortColumn: inputDTO.sortColumn,
          sortOrder: inputDTO.sortOrder,
        })
      } else {
        dispatch(commonAction.setLoader(false))
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonAction.setLoader(false))
    });
};

export const bulUpdateUsers = (dispatch, lang, dictionary, inputDTO) => {
  if (inputDTO && inputDTO.userId.length == 0) {
    return
  }
  const params = { headers: authHeader() };
  const url = getApiUrl('user', 'bulUpdateUsers');
  dispatch(commonAction.setLoader(true))
  axios.put(url, inputDTO, params)
    .then((response) => {
      if (response.data.success) {
        utilCommon.showNotification(response.data.message, dictionary, lang)
        getUsers(dispatch, lang, dictionary, {
          pageNumber: inputDTO.pageNumber,
          pageSize: inputDTO.pageSize,
          sortColumn: inputDTO.sortColumn,
          sortOrder: inputDTO.sortOrder,
        })
      } else {
        dispatch(commonAction.setLoader(false))
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonAction.setLoader(false))
    });
};

export const updateUserInfo = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('user', 'updateUserInfo');
  dispatch(commonAction.setLoader(true))
  axios.put(url, inputDTO, params)
    .then((response) => {
      if (response.data.success) {
        dispatch(reset('MyAccountForm'));
        utilCommon.setSession('fname', inputDTO.firstName)
        utilCommon.setSession('lname', inputDTO.lastName)
        utilCommon.setSession('username', inputDTO.firstName)
        dispatch(commonAction.setLoader(false))
        utilCommon.showNotification(response.data.message, dictionary, lang)
      } else {
        dispatch(reset('MyAccountForm'));
        dispatch(commonAction.setLoader(false))
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {

      dispatch(commonAction.setLoader(false))
    });
};

const filterUsers = (inputDTO) => {
  const params = { headers: authHeader() };
  let url = getApiUrl('user', 'getUsers');
  let col = inputDTO.sortColumn || 'name';
  if (inputDTO.sortColumn === 'firstName' || inputDTO.sortColumn === 'lastName') {
    col = 'name'
  }
  // url = url + '?pageSize=' + (inputDTO.pageSize||20) + '&pageNumber=' + (inputDTO.pageNumber||1);
  if (col) {
    url += '?sortColumn=' + col;
    url += '&sortOrder=asc';
  }
  if (inputDTO.keyword) {
    url = url + '&keyword=' + (inputDTO.keyword.includes('&') ? inputDTO.keyword.split('&')[0] + encodeURIComponent('&') + inputDTO.keyword.split('&')[1] : inputDTO.keyword)
  }

  if (inputDTO.status) {
    url += '&status=' + inputDTO.status;
  }

  if (inputDTO.ids) {
    url += '&' + utilCommon.buildQueryString({userId: inputDTO.ids});
  }


  return axios.get(url, params)
      .then((response) => {
        const resp = [];
        if (response.data.success) {
          return response.data.content.userList;
        }
        return [];
      })
      .catch(()=>[])
}

export const getUsers = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  let url = getApiUrl('user', 'addUser');
  let col = inputDTO.sortColumn
  if (inputDTO.sortColumn == 'firstName' || inputDTO.sortColumn == 'lastName') {
    col = 'name'
  }

  url = url + '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
  if (col) {
    url += '&sortColumn=' + col;
    if (inputDTO.sortOrder) {
      url += '&sortOrder=' + inputDTO.sortOrder;
    }
  }
  if (inputDTO.keyword) {
    url = url + '&keyword=' + (inputDTO.keyword.includes('&') ? inputDTO.keyword.split('&')[0] + encodeURIComponent('&') + inputDTO.keyword.split('&')[1] : inputDTO.keyword)
  }
  if (inputDTO.status) {
    url += '&status=' + inputDTO.status;
  }
  dispatch(commonAction.setLoader(true))
  dispatch({ type : type.SET_KEYWORD , searchKeyword : null })
  axios.get(url, params)
    .then((response) => {
      dispatch(commonAction.setLoader(false))
      if (response.data.success) {
        if (inputDTO.keyword && response.data.content.userList && response.data.content.userList.length == 0) {
          utilCommon.showNotification(9001, dictionary, lang)
        }
        if(inputDTO.keyword && inputDTO.keyword.length > 0){
           dispatch({ type : type.SET_KEYWORD , searchKeyword : inputDTO.keyword })
        }
        dispatch(actions.setUsers(response.data.content.userList, response.data.content.count, false))
        dispatch({type:type.SET_USER_PAGINATION,pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder });
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {

      dispatch(commonAction.setLoader(false))
    });
};

export const getUserDetails = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  let url = getApiUrl('user', 'addUser');
  url = url + '/' + inputDTO
  axios.get(url, params)
    .then((response) => {
      if (response.data.success) {
        dispatch(actions.setCurrentUser(response.data.content))
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {

    });
};

export const getMasterData = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };

  // Sales Teams
  let url = getApiUrl('user', 'salesTeam');
  axios.get(url, params)
    .then((response) => {
      if (response.data.success) {
        let data = response.data.content.salesTeamLists
        dispatch({ type: type.SET_SALESTEAMS, data })
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
    });

  // Pay Types
  let url1 = getApiUrl('user', 'payType');
  axios.get(url1, params)
    .then((response) => {
      if (response.data.success) {
        let data = response.data.content.payTypeList
        dispatch({ type: type.SET_PAYTYPES, data })
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonAction.setLoader(false))
    });

  // Time Zones
  let url2 = getApiUrl('user', 'timeZone');
  axios.get(url2, params)
    .then((response) => {
      if (response.data.success) {
        let data = response.data.content.timeZoneList
        dispatch({ type: type.SET_TIMEZONES, data })
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {

    });

  // Time Zones
  let url3 = getApiUrl('user', 'role');
  axios.get(url3, params)
    .then((response) => {
      if (response.data.success) {
        let data = response.data.content.roleList
        dispatch({ type: type.SET_ROLES, data })
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {

    });

  // Master Data
  const url15 = getApiUrl('user', 'getMasterInfo');
  axios.get(url15, params)
    .then((response) => {
      dispatch(commonAction.setLoader(false))
      if (response.data.success) {
        let leadType = response.data.content.masterDataList.shippingFrequencyList
        let leadMode = response.data.content.masterDataList.contractModeList
        dispatch({ type: type.SET_LEAD_TYPE, leadType: leadType })
        dispatch({ type: type.SET_LEAD_MODE, leadMode: leadMode })
      }
    })
    .catch((err) => {
      dispatch(commonAction.setLoader(false))
    });


    const url16 = getApiUrl('applicationSettings', 'teams')
    axios.get(url16 + '?teamStatus=1', params).then((response) => {if (response.data.success) {dispatch({ type: type.SET_TEAMS, teams: response.data.content.teamsList })}}).catch((err) => {});


  // Department
  let url4 = getApiUrl('user', 'department');
  axios.get(url4, params)
    .then((response) => {
      if (response.data.success) {
        let data = response.data.content.departmentList
        dispatch({ type: type.SET_DEPARTMENTS, data })
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {

    });

};

// export const getMasterInfo = (dispatch) => {
//   const params = { headers: authHeader() };
//   const url = getApiUrl('user', 'getMasterInfo');
//   axios.get(url, params)
//     .then((response) => {
//       dispatch(commonAction.setLoader(false))
//       if (response.data.success) {
//         dispatch({ type: type.SET_MASTER_DATA, masterData: response.data.content.masterDataList })
//       }
//     })
//     .catch((err) => {
//       dispatch(commonAction.setLoader(false))
//     });
//   }

export const getSecurityLevel = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  let url = getApiUrl('user', 'securityLevels') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
  dispatch(commonAction.setLoader(true))
  axios.get(url, params)
    .then((response) => {
      dispatch(commonAction.setLoader(false))
      if (response.data.success) {
        dispatch({ type: type.SET_SECURITY_LEVEL, securityLevelList: response.data.content.securityLevelList, totalResults: response.data.content.totalResults })
        dispatch({type: type.SET_USER_PAGINATION, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonAction.setLoader(false))
    });
};

export const getMasterModulesData = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('user', 'getModules');
  axios.get(url, params)
    .then((response) => {
      if (response.data.success) {
        dispatch({ type: type.SET_MODULES, modules: response.data.content.moduleList })
      }
    })
    .catch((err) => {
    });
}

export const addSecurityLevel = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('user', 'securityLevels');
  dispatch(commonAction.setLoader(true))
  axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
    .then((response) => {
      if (response.data.success) {
        if (inputDTO.id) {
          utilCommon.showNotification('Security level updated succesfully.', dictionary, lang)
        } else {
          utilCommon.showNotification('Security level added succesfully.', dictionary, lang)
        }
        utilCommon.removeSession('sortOrder');
        dispatch({ type: type.SET_PAGING })
        getSecurityLevel(dispatch, lang, dictionary, { sortColumn: 'name', sortOrder: utilCommon.getSession('sortOrder') || 'asc', pageNumber: utilCommon.getSession('pageNumber') || 1, pageSize: utilCommon.getSession('pageSize') || 50 })
      } else {
        dispatch(commonAction.setLoader(false))
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonAction.setLoader(false))
    });
};

export const deleteSecurityLevel = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('user', 'securityLevels');
  let payload = { id: [inputDTO.id] }
  dispatch(commonAction.setLoader(true))
  axios.patch(url, payload, params)
    .then((response) => {
      if (response.data.success) {
        utilCommon.showNotification('Security level deleted succesfully.', dictionary, lang)
        dispatch({ type: type.SET_PAGING })
        getSecurityLevel(dispatch, lang, dictionary, { sortColumn: 'name', sortOrder: utilCommon.getSession('sortOrder') || 'asc', pageNumber: utilCommon.getSession('pageNumber') || 1, pageSize: utilCommon.getSession('pageSize') || 50 })
      } else {
        dispatch(commonAction.setLoader(false))
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonAction.setLoader(false))
    });
};

export default {
  addUser, // edits User in case of a user id with payload
  bulUpdateUsers,
  updateUserInfo,
  getUsers,
  getUserDetails,
  getMasterData,
  getMasterModulesData,
  getSecurityLevel,
  addSecurityLevel,
  deleteSecurityLevel,
  filterUsers,
  // getMasterInfo
};
