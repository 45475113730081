// @flow
import * as type from './actionTypes';
import sortBy from 'lodash/sortBy'


const parsedFuelCostIndex = (costIndexes) => {
  let res = []
  costIndexes.map(x => {
    res.push({
      ...x,
      minFuelPrice: parseFloat(x.minFuelPrice)
    })
  })
  return sortBy(res, 'minFuelPrice')
}

const initialState = {
  fuelSurchargeIndexList: [],
  currentFuelSurchargeIndex: null,
  facilityTypes: [],
  currentFacilityType: null,
  fuelCostIndexes: [],
  currentFuelCostIndex: [],
  globalDiscount: {},
  fuelSurchargeCount: 0,
  facilityTypeCount: 0,
  // accessorials
  accessorials: [],
  facilityAccessorials: [],
  currentAccessorial: null,
  accessorialCount: 0,
  // transit time
  transitTimes: [],
  currentTransitTime: null,
  transitTimesCount: 0,
  // system wide notification
  systemWideNotification: [],
  currentSystemWideNotification: null,
  systemWideNotificationCount: 0,
  // manage coupon
  manageCouponList: [],
  currentCoupon: null,
  currentCouponIndex: null,
  manageCouponCount: 0,
  binaryToDownloadReport: null,
  url: null,
  // teams
  teams: [],
  currentTeams: null,
  currentTeamsIndex: null,
  teamsCount: 0,
  supervisiors: [],
  teamUserCount: 0,
  //falvey terms & conditions
  falveyTermsConditionsList: [],
  currentFalveyTermsConditions: null,
  currentFalveyTermsConditionsIndex: null,
  falveyTermsConditionsCount: 0,
  //tms terms & conditions
  tmsTermsConditionsList: [],
  currentTMSTermsConditions: null,
  currentTMSTermsConditionsIndex: null,
  tmsTermsConditionsCount: 0,
  //financial settings
  financialSettings: {},
  //approved IP
  approvedIPList: [],
  currentIP: null,
  currentApprovedIPIndex: null,
  approvedIPCount: 0,
  // paging
  paging: false,
  pageNumber: 1,
  pageSize: 50,
  sortColumn: '',
  sortOrder: '',
  // accessorial paging
  accPaging: false,
  accPageNumber: 1,
  accPageSize: 50,
  accSortColumn: '',
  accSortOrder: '',
  // Shipment Mode Master
  shipmentMode: [],
  emailTemplates: {},
  currentEmailTemplate: {},
  popUpContentList: {},
  carrierMarginAdjustments: {},
  carrierMarginAdjustmentLogs: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_TRANSITTIMES:
      return {
        ...state,
        transitTimes: action.transitTimes,
        transitTimesCount: action.totalResults
      };
    case type.SET_CURRENT_TRANSITTIME:
      return {
        ...state,
        currentTransitTime: action.currentTransitTime
      };
    case type.SET_FACILITYTYPES:
      return {
        ...state,
        facilityTypes: action.facilityTypes,
        facilityTypeCount: action.facilityTypeCount
      };
    case type.SET_CURRENT_FACILITYTYPE:
      return {
        ...state,
        currentFacilityType: action.currentFacilityType
      };

    case type.SET_FUELSURCHARGES:
      return {
        ...state,
        fuelSurchargeIndexList: action.fuelSurchargeIndexList,
        fuelSurchargeCount: action.fuelSurchargeCount
      };
    case type.SET_CURRENT_FUELSURCHARGE:
      return {
        ...state,
        currentFuelSurchargeIndex: action.currentFuelSurchargeIndex
      };
    case type.SET_FUELCOSTINDEXES:
      return {
        ...state,
        fuelCostIndexes: parsedFuelCostIndex(action.fuelCostIndexes)
      };

    case type.SET_CURRENT_FUELCOSTINDEX:
      return {
        ...state,
        currentFuelCostIndex: action.currentFuelCostIndex
      };
    case type.SET_GLOBAL_DISCOUNT:
      return {
        ...state,
        globalDiscount: action.globalDiscount
      };

    case type.SET_FACILITY_TYPE_ACCESSORIALS:
      return {
        ...state,
        facilityAccessorials: action.facilityAccessorials
      };


    case type.SET_ACCESSORIALS:
      return {
        ...state,
        accessorials: action.accessorials,
        accessorialCount: action.accessorialCount
      }

    case type.SET_CURRENT_ACCESSORIAL:
      return {
        ...state,
        currentAccessorial: action.currentAccessorial
      }

    case type.SET_MANAGE_COUPON:
      return {
        ...state,
        manageCouponList: action.manageCouponList,
        manageCouponCount: action.totalResults,
        url: action.url,
      };
    case type.SET_CURRENT_COUPON:
      return {
        ...state,
        currentCoupon: action.currentCoupon,
        currentCouponIndex: action.currentCouponIndex
      };

    case type.SET_MODE:
      return {
        ...state,
        shipmentMode: action.shipmentMode
      };

    case type.SET_SYSTEM_WIDE_NOTIFICATION:
      return {
        ...state,
        systemWideNotification: action.systemWideNotification[0],
        systemWideNotificationCount: action.systemWideNotificationCount
      };
    case type.SET_CURRENT_SYSTEM_WIDE_NOTIFICATION:
      return {
        ...state,
        currentSystemWideNotification: action.currentSystemWideNotification
      };

    case type.SET_TEAMS:
      return {
        ...state,
        teams: action.teams,
        teamsCount: action.totalResults
      };
    case type.SET_CURRENT_TEAMS:
      return {
        ...state,
        currentTeams: action.currentTeams,
        currentTeamsIndex: action.currentTeamsIndex
      };

    case type.SET_TEAM_USER_COUNT:
      return {
        ...state,
        teamUserCount: action.teamUserCount,
      };

    case type.SET_SUPERVISIORS:
			return {
				...state,
				supervisiors: action.supervisiors
			}

    case type.SET_FALVEY_TERMS_CONDITIONS:
      return {
        ...state,
        falveyTermsConditionsList: action.falveyTermsConditionsList,
        falveyTermsConditionsCount: action.totalResults
      };

    case type.SET_CURRENT_FALVEY_TERMS_CONDITIONS:
      return {
        ...state,
        currentFalveyTermsConditions: action.currentFalveyTermsConditions,
        currentFalveyTermsConditionsIndex: action.currentFalveyTermsConditionsIndex
      };

      case type.SET_TMS_TERMS_CONDITIONS:
      return {
        ...state,
        tmsTermsConditionsList: action.tmsTermsConditionsList,
        tmsTermsConditionsCount: action.totalResults
      };

    case type.SET_CURRENT_TMS_TERMS_CONDITIONS:
      return {
        ...state,
        currentTMSTermsConditions: action.currentTMSTermsConditions,
        currentTMSTermsConditionsIndex: action.currentTMSTermsConditionsIndex
      };

    case type.SET_FINANCIAL_SETTINGS:
    return {
      ...state,
      financialSettings: action.financialSettings
    };

    case type.SET_APPROVED_IP:
      return {
        ...state,
        approvedIPList: action.approvedIPList,
        approvedIPCount: action.totalResults
      };

    case type.SET_CURRENT_APPROVED_IP:
      return {
        ...state,
        currentIP: action.currentIP,
        currentApprovedIPIndex: action.currentApprovedIPIndex
      };

    case type.SET_PAGING:
      return {
        ...state,
        paging: !state.paging
      }
    case type.SET_CURRENT_PAGE:
      return {
        ...state,
        pageNumber: action.pageNumber
      }

      case type.SET_PAGING_SORTING:
      return {
        ...state,
        pageNumber: action.pageNumber,
        pageSize: action.pageSize,
        sortColumn: action.sortColumn,
        sortOrder: action.sortOrder
      }

    case type.SET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.emailTemplates
      }

    case type.SET_CURRENT_EMAIL_TEMPLATE:
      return {
        ...state,
        currentEmailTemplate: action.currentEmailTemplate
      }

    case type.SET_POP_UP_CONTENT_LIST:
      return {
        ...state,
        popUpContentList: action.popUpContentList
      }

    case type.SET_REGISTRATION_REPORT_SETTINGS:
      return {
        ...state,
        registrationReportSettings: action.registrationReportSettings
      }

    case type.SET_CARRIER_MARGIN_ADJUSTMENTS:
      return {
        ...state,
        carrierMarginAdjustments: action.carrierMarginAdjustments
      }

    case type.SET_CARRIER_MARGIN_ADJUSTMENT_LOGS:
      return {
        ...state,
        carrierMarginAdjustmentLogs: action.carrierMarginAdjustmentLogs
      }

    default:
      return state;
  }
};

export default reducer;
