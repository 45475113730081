export const SET_SHIPMENTS = 'shipmentManagement/SET_SHIPMENTS';
export const SET_AGENTS = 'shipmentManagement/SET_AGENTS';
export const SET_STATES = 'shipmentManagement/SET_STATES';
export const SET_COUNTRIES = 'shipmentManagement/SET_COUNTRIES';
export const SET_ADDRESS = 'shipmentManagement/SET_ADDRESS';
export const SET_ACCESSORIALS = 'shipmentManagement/SET_ACCESSORIALS';
export const SET_LOADER = 'shipmentManagement/SET_LOADER';
export const SET_NOTES = 'shipmentManagement/SET_NOTES';
export const SET_FACILITY_TYPE = 'shipmentManagement/SET_FACILITY_TYPE'
export const SET_SHIPMENT_MASTER = 'shipmentManagement/SET_SHIPMENT_MASTER';
export const SET_ACTIVE_AGENTS = 'shipmentManagement/SET_ACTIVE_AGENTS';
export const SET_TIMEZONES = 'shipmentManagement/SET_TIMEZONES';
export const SET_PRODUCTS = 'shipmentManagement/SET_PRODUCTS';
export const SET_SHIPMENT_PRODUCTS = 'shipmentManagement/SET_SHIPMENT_PRODUCTS';
export const SET_REASSIGN_FLAG = 'shipmentManagement/SET_REASSIGN_FLAG';
export const SET_SHIPMENT_ALERT = 'shipmentManagement/SET_SHIPMENT_ALERT';
export const SET_MASTER_DATA = 'shipmentManagement/SET_MASTER_DATA';
export const SET_CURRENT_ALERT = 'shipmentManagement/SET_CURRENT_ALERT';
export const SET_TRANSACTIONS = 'shipmentManagemtn/SET_TRANSACTIONS';
export const SET_COUPON_VALIDATE_FLAG = 'shipmentManagement/SET_COUPON_VALIDATE_FLAG';
export const SET_TRANSACTION_LOGS = 'shipmentManagement/SET_TRANSACTION_LOGS';
export const SET_TRANSACTION_DETAILS = 'shipmentManagement/SET_TRANSACTION_DETAILS';
export const SET_PAYMENTS = 'shipmentManagement/SET_PAYMENTS';
export const SET_INSURANCE_VALIDATE_FLAG = 'shipmentManagement/SET_INSURANCE_VALIDATE_FLAG';
export const SET_EXCLUDE_ITEM = 'shipmentManagement/SET_EXCLUDE_ITEM';
export const SET_TASKS = 'shipmentManagement/SET_TASKS';
export const SET_LOCALCARDS = 'shipmentManagement/SET_LOCALCARDS';
export const CLEAR_LOCALCARDS = 'shipmentManagement/CLEAR_LOCALCARDS';
export const SET_CONTACTLOGS = 'shipmentManagement/SET_CONTACTLOGS'
export const SET_DOCUMENTS = 'shipmentManagement/SET_DOCUMENTS'
export const CLEAR_DOCUMENTS = 'shipmentManagement/CLEAR_DOCUMENTS'
export const SET_CURRENT_SHIPMENT = 'shipmentManagement/SET_CURRENT_SHIPMENT';
export const FILE_UPLOADED_COUNT = 'shipmentManagement/FILE_UPLOADED_COUNT';
export const FILE_UPLOADED = 'shipmentManagement/FILE_UPLOADED'
export const CLEAR_DOCUMENT_STATUS = 'shipmentManagement/CLEAR_DOCUMENT_STATUS'
export const SET_INITIAL_DOCUMENT = 'shipmentManagement/SET_INITIAL_DOCUMENT'
export const UPDATE_DOCUMENT_PAGING = 'shipmentManagement/UPDATE_DOCUMENT_PAGING'
export const SET_PREVIEW_CONTENT = 'shipmentManagement/SET_PREVIEW_CONTENT';
export const SET_SHIPMENT_CHARGES = 'shipmentManagement/SET_SHIPMENT_CHARGES'
export const SET_TRACKING_INFO = 'shipmentManagement/SET_TRACKING_INFO'
export const SET_TRACKING_HEADER = 'shipmentManagement/SET_TRACKING_HEADER'
export const CLEAR_TRACKING_INFO = 'shipmentManagement/CLEAR_TRACKING_INFO'
export const SET_CANCELLATION_FORM_DETAILS = 'shipmentManagement/SET_CANCELLATION_FORM_DETAILS'
export const SET_TEAMS = 'shipmentManagement/SET_TEAMS';
export const SET_CONTRACTS = 'shipmentManagement/SET_CONTRACTS'
export const SET_FALVEY = 'shipmentManagement/SET_FALVEY'
export const SET_TERMS = 'shipmentManagement/SET_TERMS'
export const SET_CARRIER = 'shipmentManagement/SET_CARRIER'
export const SET_CARRIER_DETAILS = 'shipmentManagement/SET_CARRIER_DETAILS'
export const SET_INITIAL_CARRIER = 'shipmentManagement/SET_INITIAL_CARRIER'
export const CLEAR_INITIAL_DATA  = 'shipmentManagement/CLEAR_INITIAL_DATA';
export const SET_INITIAL_TASK = 'shipmentManagement/SET_INITIAL_TASK';
export const SET_ALL_CONTACTLOGS = 'shipmentManagement/SET_ALL_CONTACTLOGS'
export const UPDATE_ADDRESS_PAGING = 'shipmentManagement/UPDATE_ADDRESS_PAGING';
export const UPDATE_PRODUCT_PAGING = 'shipmentManagement/UPDATE_PRODUCT_PAGING';
export const BOOK_SHIPMENT_MANUALLY = 'shipmentManagement/BOOK_SHIPMENT_MANUALLY';
export const SET_SEARCH = 'shipmentManagement/SET_SEARCH';
export const SET_SHIPMENT_LOGS = 'customerManagement/SET_SHIPMENT_LOGS';
export const SET_ALL_SHIPMENT_LOGS = 'customerManagement/SET_ALL_SHIPMENT_LOGS';
export const SET_CARRIERS = 'shipmentManagement/SET_CARRIERS';
export const SET_CSP_URL = 'shipmentManagement/SET_CSP_URL';
export const SET_SHIPMENT_URL = 'shipmentManagement/SET_SHIPMENT_URL';
export const SET_UPDATED_PRODUCTS = 'shipmentManagement/SET_UPDATED_PRODUCTS';
export const SET_AGENT_UUID = 'shipmentManagement/SET_AGENT_UUID';
export const SET_BOL_STATUS = 'shipmentManagement/SET_BOL_STATUS';
export const ADD_PRODUCT_RUNNER = 'shipmentManagement/ADD_PRODUCT_RUNNER';
export const DONE_PRODUCT_RUNNER = 'shipmentManagement/DONE_PRODUCT_RUNNER';
export const RUN_PRODUCT_RUNNER = 'shipmentManagement/RUN_PRODUCT_RUNNER';
export const SET_DOCUMENT_INVOICES_INFO = 'shipmentManagement/SET_DOCUMENT_INVOICES_INFO';
export const CLEAR_DOCUMENT_INVOICES_INFO = 'shipmentManagement/CLEAR_DOCUMENT_INVOICES_INFO';
export const SET_TL_PICKUP_ADDRESS = 'shipmentManagement/SET_TL_PICKUP_ADDRESS';
export const SET_TL_DROP_ADDRESS = 'shipmentManagement/SET_TL_DROP_ADDRESS';
export const SUBMIT_TL_PICKUP_ADDRESS = 'shipmentManagement/SUBMIT_TL_PICKUP_ADDRESS';
export const SUBMIT_TL_DROP_ADDRESS = 'shipmentManagement/SUBMIT_TL_DROP_ADDRESS';
export const SET_ORDER_STATUSES = 'shipmentManagement/SET_ORDER_STATUSES';
export const SET_TRIP_EVENTS = 'shipmentManagement/SET_TRIP_EVENTS';
export const SET_LOCATION_UPDATES = 'shipmentManagement/SET_LOCATION_UPDATES';
export const SET_SCHEDULE_ALERTS = 'shipmentManagement/SET_SCHEDULE_ALERTS';
export const SET_CANCELLATION_INFO = 'shipmentManagement/SET_CANCELLATION_INFO';
export const SET_ONLINE_CUSTOMER = 'shipmentManagement/SET_ONLINE_CUSTOMER';
export const SET_ONLINE_CUSTOMER_SHIPMENTS = 'shipmentManagement/SET_ONLINE_CUSTOMER_SHIPMENTS';
export const ADD_SHIPMENT_PRODUCT = 'shipmentManagement/ADD_SHIPMENT_PRODUCT'
export const REMOVE_TL_SHIPMENT_PRODUCT_BY_INDEX = 'shipmentManagement/REMOVE_TL_SHIPMENT_PRODUCT_BY_INDEX'
export const SET_MANUAL_PURCHASE_INSURANCE_NOTIFICATION = 'shipmentManagement/SET_MANUAL_PURCHASE_INSURANCE_NOTIFICATION'
export const SET_PROCESSING_PAYMENT_AND_BOOK_STATUS = 'shipmentManagement/SET_PROCESSING_PAYMENT_AND_BOOK_STATUS'
