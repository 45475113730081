// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import config from '../config';
import middlewares from './middlewares';
import reducer from './reducer';
import { translations } from '../translations';
import { i18nActions } from 'redux-react-i18n';
import thunk from 'redux-thunk';

// Check for dev env, if false hide extensions like redux
const { isDev, isBrowser } = config;
const devtools = isDev && isBrowser && window.devToolsExtension
  ? window.devToolsExtension
  : () => (fn) => fn;

const dictionaries = translations;

const languages = [
  {
    code: 'en-US',
    name: 'English (USA)',
  },
];

const configureStore = (initialState = {}, services = {}) => {
  const enhancers = [
    applyMiddleware(
      ...middlewares,
        thunk
    ),
    devtools(),
  ];

  const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
  const store = createStore(reducer, initialState, composeEnhancers(...enhancers));
  store.dispatch(i18nActions.setDictionaries(dictionaries));
  store.dispatch(i18nActions.setLanguages(languages));
  store.dispatch(i18nActions.setCurrentLanguage('en-US'));
  return store;
};

export default configureStore;
