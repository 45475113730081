/*eslint-disable no-script-url*/

import React from 'react';



const Footer = () => (
    <div className="footer">
        <ul>
            <li><a href="javascript:void()">© 2018 FreightCenter</a></li>
            <li>|</li>
            <li><a href="javascript:void()">Terms & conditions</a></li>
            <li>|</li>
            <li><a href="javascript:void()"> Privacy policy</a></li>
        </ul>
    </div>
);

export default Footer;
