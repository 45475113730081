// @flow
import * as type from './actionTypes';

const initialState = {
	states: [],
	notes: [],
	actionTypes: [
		{"id": 1, "name": "Left Voicemail", "status": "1"},
		{"id": 2, "name": "Spoke to Customer", "status": "1"},
		{"id": 3, "name": "Emailed Customer", "status": "1"},
		{"id": 4, "name": "Called, unable to reach", "status": "1"}
	],
	customerMasterData: {},
	timezones: [],
	countries: [],
	tasks: [],
	totalTaskCount: 0,
	customers: [],
	totalcount: 0,
	addresses: [],
	sectionLoading: false,
	products: [],
	carriers: [],
	agents: [],
	customerARInfo: {
		arStatus: false,
		arCreditLimit: 0,
		arGraceAmount: 0,
		defaultPaymentMethod: false
	},
	initialTask: {},
	initialCarrier: {},
	initialAddress: {},
	initialProduct: { measurementIn: 1 },
	editTaskFlag: false,
	totalProductCount: 0,
	addressEditFlag: false,
	loadCarrierFlag: false,
	contactLogs: [],
	productEditFlag: false,
	allContactLogs: [],
	addressPageNumber: 1,
	specificPricing: [],
	customerCreditCardsListing: [],
	addressPageSize: 10,
	addressSortColumn: 'addedDate',
	addressSortOrder: 'desc',
	productPageNumber: 1,
	logPageSize: 10,
	logSortColumn: 'addedDate',
	logSortOrder: 'desc',
	logPageNumber: 1,
	paymentTypeFlag: '1',
	productPageSize: 10,
	productSortColumn: 'addedDate',
	productSortOrder: 'desc',
	customerTypeOptions: [{ value: '1', text: 'Residential' }, { value: '2', text: 'Commercial' }],
	customerState: {
		"customerType": "2", "customerShippingFrequency": "", "customerFirstName": "", "customerCompany": "", "customerDepartment": "", "customerLastName": "",
		"customerPosition": "", "customerCountry": "", "customerState": "", "customerCity": "", "customerAddress": "", "customerApt": "", "customerZip": "",
		"customerPhone": "", "customerAlternatePhone": "", "customerAltExt": "", "customerCell": "", "customerEmailId": "", "customerBillingEmailId": "",

	},
	contactLogCount:0,
	searchKeyword : null,
	customerLogs: [],
	allCustomerLogs: [],
	customerLogsCount: 0,
	activeAgents:[],
	assigneeAgents : [],
	templates:[],
	previewTemplate:{},
	url: null,
	bulkProductUploaded : false,
	bulkProductError : [],
	reassignFlag:false,
	advancedSearch: 0,
	advancedSearchQuery: {},
	customerStoreCreditBalance: null,
	storeCreditLogs: [],
	emailTemplates: {},
	emailTemplateData: {},
	rewardPoints: null
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case type.SET_NOTES:
			return {
				...state,
				notes: action.notes,
				totalNotesCount: action.totalNotesCount
			};
		case type.SET_INITIAL_TASK:
			return {
				...state,
				initialTask: action.currentTask,
				editTaskFlag: true
			}
		case type.SET_INITIAL_CARRIER:
			return {
				...state,
				initialCarrier: action.initialCarrier,
				loadCarrierFlag: true
			}
		case type.SET_STATES:
			return {
				...state,
				states: action.states,
			};

		case type.SET_ASSIGNEE_AGENTS:
		return {
			...state,
			assigneeAgents : action.agentList

		}

		case type.SET_PAYMENT_FLAG:
			return {
				...state,
				paymentTypeFlag: action.flag
			};

		case type.SET_SEARCH :
		return {
			...state,
			searchKeyword : action.keyword
		}

		case type.SET_CONTACTLOGS:
			return {
				...state,
				contactLogs: action.contactLogs,
				contactLogCount:action.contactLogCount
			}

		case type.SET_PAYMENT_OPTIONS:
			return {
				...state,
				customerCreditCardsListing: action.customerCreditCardsListing,
				paymentTypeFlag: action.flag ? '1' : '0'
			}
		case type.SET_PAYMENT_AR_OPTIONS:
			return {
				...state,
				customerARInfo: action.customerARInfo,
				paymentTypeFlag: action.flag ? '0' : '1'
			}
		case type.SET_ALL_CONTACTLOGS:
			return {
				...state,
				allContactLogs: action.allContactLogs,
			}

		case type.SET_CUSTOMER_LOGS:
			return {
				...state,
				customerLogs: action.logs,
				customerLogsCount:action.totalResults
			}

		case type.SET_ALL_CUSTOMER_LOGS:
			return {
				...state,
				allCustomerLogs: action.allCustomerLogs,
			}

		case type.SET_LOADER:
			return {
				...state,
				sectionLoading: action.loading
			}
		case type.SET_TIMEZONES:
			return {
				...state,
				timezones: action.timezones,
			};

		case type.SET_PACKAGE_TYPE:
			return {
				...state,
				packageType: action.packageTypes,
			};
		case type.SET_COMMODITY_CATEGORY:
			return {
				...state,
				commodityCategory: action.commodityCategory,
			};
		case type.SET_ENGAGEMENTTYPE:
			return {
				...state,
				engagementType: action.engagementType,
			};
		case type.SET_ADDRESS_PRODUCT_INITIAL_VALUE:
			if (action.actionType == "address") {
				return {
					...state,
					initialAddress: { ...action.initialVal, customerCountryId: action.initialVal.customerCountryId.id, customerStateId: action.initialVal.customerStateId ? action.initialVal.customerStateId.id : '' },
					addressEditFlag: true
				}
			}
			else {
				return {
					...state,
					initialProduct: { ...action.initialVal, freightClassId: action.initialVal.freightClassId?.id, commodityTypeId: action.initialVal.commodityTypeId?.id, packageTypeId: action.initialVal.packageTypeId?.id, commodityCategoryId: action.initialVal.commodityCategoryId?.id },
					productEditFlag: true
				}
			}
		case type.SET_AGENTS:
			return {
				...state,
				agents: action.agents
			}
		case type.SET_CUSTOMER_MASTER:
			return {
				...state,
				customerMasterData: action.customerMasterData,
			};
		case type.SET_CARRIER:
			return {
				...state,
				carriers: action.carriers,
			};
		case type.SET_COUNTRIES:
			return {
				...state,
				countries: action.countries,
			};
		case type.SET_ADDRESS:
			return {
				...state,
				addresses: action.addresses,
				totalAddressCount: action.totalAddressCount
			};
		case type.SET_TASKS:
			return {
				...state,
				tasks: action.tasks,
				totalTaskCount: action.totalTaskCount
			};
		case type.SET_PRODUCTS:
			return {
				...state,
				products: action.products,
				totalProductCount: action.totalProductCount
			};

		case type.SET_PRICING:
			return {
				...state,
				specificPricing: action.specificPricing || []
			};

		case type.SET_CUSTOMERS:
			return {
				...state,
				customers: action.customers,
				totalcount: action.totalCount,
				url: action.url,
				advancedSearch: action.advancedSearch,
				advancedSearchQuery: action.advancedSearchQuery
			};
		case type.SET_CURRENT_CUSTOMER:

			let value = { ...action.currentCustomer, ...action.currentCustomer.address, ...action.currentCustomer.preference }
			value.timezone = value.timezoneId;
			const value2 = {
				...state,
				customerState: value
			};
      		value2.customerState.interestedInArAccount = value2.customerState.interestedInArAccount !== undefined && value2.customerState.interestedInArAccount.toString();
			value2.customerState.tradeshowExhibitor = value2.customerState.tradeshowExhibitor !== undefined && value2.customerState.tradeshowExhibitor.toString();
      return value2;
		case type.UPDATE_ADDRESS_PAGING:
			return {
				...state,
				addressPageNumber: action.addressPageNumber,

				addressPageSize: action.addressPageSize,
				addressSortColumn: action.addressSortColumn,
				addressSortOrder: action.addressSortOrder,
			}
		case type.UPDATE_PRODUCT_PAGING:
			return {
				...state,
				productPageNumber: action.productPageNumber,

				productPageSize: action.productPageSize,
				productSortColumn: action.productSortColumn,
				productSortOrder: action.productSortOrder,
			}
		case type.UPDATE_CONTACTLOG_PAGING:
			return {
				...state,
				logPageNumber: action.logPageNumber,

				logPageSize: action.logPageSize,
				logSortColumn: action.logSortColumn,
				logSortOrder: action.logSortOrder,
			}
		case type.CLEAR_ADDRESS_EDIT_FLAG:
			return { ...state, addressEditFlag: false, initialAddress: {} }
		case type.CLEAR_PRODUCT_EDIT_FLAG:
			return { ...state, productEditFlag: false, initialProduct: { measurementIn: 1 } }
		case type.CLEAR_INITIAL_DATA:
			return {
				...state,
				customerState: {customerType:1},
				initialCarrier: [],
				loadCarrierFlag: false
			}
		case type.SET_ACTIVE_AGENTS:
			return {
				...state,
				activeAgents: action.activeAgents
			}
		case type.SET_TEMPLATES:
			return{
				...state,
				templates:action.templateList
			}
		case type.SET_EMAIL_TEMPLATE_PREVIEW:
			return {
				...state,
				previewTemplate:action.templatePreview
			}
		case type.PRODUCT_BULK_UPLOADED:
			return {
				...state,
				bulkProductError:action.bulkProductError||[],
				bulkProductUploaded:action.bulkProductUploaded
			}
		case type.SET_REASSIGN_FLAG:
			return {
				...state,
				reassignFlag: action.reassignFlag
			};
		case type.SET_CUSTOMER_STORE_CREDIT_BALANCE:
			return {
				...state,
				customerStoreCreditBalance: action.customerStoreCreditBalance
			};
		case type.SET_STORE_CREDIT_LOGS:
			return {
				...state,
				storeCreditLogs: action.storeCreditLogs
			};

		case type.SET_LIVE_CUSTOMER:
			return {
				...state,
				liveCustomers: action.liveCustomers
			};

		case type.SET_ONLINE_CUSTOMER:
			return {
				...state,
				onlineCustomers: action.onlineCustomers,
				totalOnline: action.totalOnline
			};

		case type.SET_CUSTOMER_EMAIL_TEMPLATES:
			return {
				...state,
				emailTemplates: action.emailTemplates,
			};

		case type.SET_CUSTOMER_EMAIL_TEMPLATE_DATA:
			return {
				...state,
				emailTemplateData: action.emailTemplateData,
			};
		case type.SET_CUSTOMER_REWARDS_POINTS_BALANCE:
			return {
				...state,
				rewardPoints: action.rewardPoints
			};

		case type.SET_CUSTOMER_REWARDS_POINTS_LOGS:
			return {
				...state,
				customerRewardsPointsLogs: action.customerRewardsPointsLogs
			}

		default:
			return state;
	}
};

export default reducer;
