export const SET_STATES = 'customerManagement/SET_STATES';
export const SET_COUNTRIES = 'customerManagement/SET_COUNTRIES';
export const SET_TIMEZONES = 'customerManagement/SET_TIMEZONES';
export const SET_CUSTOMERS = 'customerManagement/SET_CUSTOMERS';
export const SET_PRODUCTS = 'customerManagement/SET_PRODUCTS';
export const SET_TASKS = 'customerManagement/SET_TASKS';
export const SET_CONTACTLOGS = 'customerManagement/SET_CONTACTLOGS'
export const SET_ADDRESS = 'customerManagement/SET_ADDRESS';
export const SET_CURRENT_CUSTOMER = 'customerManagement/SET_CURRENT_CUSTOMER';
export const CLEAR_ADDRESS_EDIT_FLAG = 'customerManagement/CLEAR_ADDRESS_EDIT_FLAG';
export const CLEAR_PRODUCT_EDIT_FLAG = 'customerManagement/CLEAR_PRODUCT_EDIT_FLAG';
export const SET_ADDRESS_PRODUCT_INITIAL_VALUE = 'customerManagement/SET_ADDRESS_PRODUCT_INITIAL_VALUE'
export const SET_COMMODITY_CATEGORY = 'customerManagement/SET_COMMODITY_CATEGORY';
export const SET_ENGAGEMENTTYPE = 'customerManagement/SET_ENGAGEMENTTYPE';
export const SET_PACKAGE_TYPE = 'customerManagement/SET_PACKAGE_TYPE';
export const SET_SHIPPING_PROVIDER_ISSUE = 'customerManagement/SET_SHIPPING_PROVIDER_ISSUE';
export const SET_CUSTOMER_MASTER = 'customerManagement/SET_CUSTOMER_MASTER';
export const SET_CARRIER = 'customerManagement/SET_CARRIER'
export const SET_LOADER = 'customerManagement/SET_LOADER';
export const SET_NOTES = 'customerManagement/SET_NOTES';
export const SET_INITIAL_CARRIER = 'customerManagement/SET_INITIAL_CARRIER'
export const CLEAR_INITIAL_DATA = 'customerManagement/CLEAR_INITIAL_DATA';
export const SET_AGENTS = 'customerManagement/SET_AGENTS';
export const SET_INITIAL_TASK = 'customerManagement/SET_INITIAL_TASK';
export const SET_ALL_CONTACTLOGS = 'customerManagement/SET_ALL_CONTACTLOGS'
export const UPDATE_ADDRESS_PAGING = 'customerManagement/UPDATE_ADDRESS_PAGING';
export const UPDATE_PRODUCT_PAGING = 'customerManagement/UPDATE_PRODUCT_PAGING';
export const SET_PRICING = 'customerManagement/SET_PRICING';
export const SET_PAYMENT_OPTIONS = 'customerManagement/SET_PAYMENT_OPTIONS';
export const SET_PAYMENT_AR_OPTIONS = 'customerManagement/SET_PAYMENT_AR_OPTIONS';
export const SET_PAYMENT_FLAG = 'customerManagement/SET_PAYMENT_FLAG';
export const UPDATE_CONTACTLOG_PAGING = 'customerManagement/UPDATE_CONTACTLOG_PAGING';
export const SET_SEARCH = 'customerManagement/SET_SEARCH';
export const SET_CUSTOMER_LOGS = 'customerManagement/SET_CUSTOMER_LOGS';
export const SET_ALL_CUSTOMER_LOGS = 'customerManagement/SET_ALL_CUSTOMER_LOGS';
export const SET_ACTIVE_AGENTS = 'customerManagement/SET_ACTIVE_AGENTS';
export const SET_ASSIGNEE_AGENTS = 'customerManagement/SET_ASSIGNEE_AGENTS';
export const SET_TEMPLATES = 'customerManagement/SET_TEMPLATES'
export const SET_EMAIL_TEMPLATE_PREVIEW = 'customerManagement/SET_EMAIL_TEMPLATE_PREVIEW'
export const PRODUCT_BULK_UPLOADED = 'customerManagement/PRODUCT_BULK_UPLOADED'
export const SET_MERGE_CUSTOMER = 'customerManagement/SET_MERGE_CUSTOMER'
export const SET_REASSIGN_FLAG = 'customerManagement/SET_REASSIGN_FLAG'
export const SET_CUSTOMER_STORE_CREDIT_BALANCE = 'admintool/SET_CUSTOMER_STORE_CREDIT_BALANCE';
export const SET_STORE_CREDIT_LOGS = 'admintool/SET_STORE_CREDIT_LOGS';

export const SET_ONLINE_CUSTOMER = 'customerManagement/SET_ONLINE_CUSTOMER';
export const SET_LIVE_CUSTOMER = 'customerManagement/SET_LIVE_CUSTOMER';

export const SET_CUSTOMER_EMAIL_TEMPLATES = 'customerManagement/SET_CUSTOMER_EMAIL_TEMPLATES';
export const SET_CUSTOMER_EMAIL_TEMPLATE_DATA = 'customerManagement/SET_CUSTOMER_EMAIL_TEMPLATE_DATA';
export const SET_CUSTOMER_REWARDS_POINTS_LOGS = 'rewardLogs/SET_CUSTOMER_REWARDS_POINTS_LOGS';
export const SET_CUSTOMER_REWARDS_POINTS_BALANCE = 'rewardLogs/SET_CUSTOMER_REWARDS_POINTS_BALANCE';
