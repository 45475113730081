import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, IconButton, withStyles } from '@material-ui/core';
import classNames from 'classnames';

interface Props {
    pageNumber: any,
    setPaging: any,
    pages: any,
    pageSize: any,
    pagingOption: any
}

class Pagination extends React.Component<Props> {
    constructor(props) {
        super(props)
        this.state = {

        };
    }


    scrollToTop = () => {
        if( this.props.refs &&  this.props.refs.scrollbars){
            const { scrollbars } = this.props.refs;
            if(scrollbars && scrollbars.scrollTop){
                scrollbars.scrollTop(0);
            }
            if(scrollbars && scrollbars.scrollTo){
                scrollbars.scrollTo(0)
            }
        }

    }

    getPagesToBeDisplayed = ()=>{
        let self = this;
        let currentPage = self.props.pageNumber;
        let totalPages = this.props.pages.length-1;
        let start = 0;
        let end = totalPages;
        if(totalPages<=5){
            start=0;
            end=totalPages;
        }
        else{
            start = Math.max(0,(currentPage-1))
            end = Math.min(totalPages, (currentPage + 4));
            if (start == 0) {
                end = 5;
            } else if (end ==totalPages) {
                start = (totalPages - 5);
            }
        }

        return {start,end}

    }

    render() {
        let {start,end} = this.getPagesToBeDisplayed()
        return (
            <div className="pagination-section">
                <div className="pagination-wraper">
                    <ul className="pagination">
                        <li onClick={() => { this.props.pageNumber != 1 ? (this.props.setPaging(1),this.scrollToTop()) : {} }} className="skip-previous"><IconButton><i className="zmdi zmdi-skip-previous"></i></IconButton></li>
                        <li onClick={() => { this.props.pageNumber != 1 ? (this.props.setPaging(this.props.pageNumber - 1),this.scrollToTop()) : {} }} className="previous"><IconButton><i className="zmdi zmdi-caret-left"></i></IconButton></li>
                        {this.props.pages.length>5 && start!=0 && end==this.props.pages.length-1 &&
                            <li key={1} onClick={() => { this.props.setPaging( 1), this.scrollToTop() }} className={this.props.pageNumber == (1) ? "current" : ''}><a href="#">{1}</a></li>
                        }
                        {this.props.pages.length>5 && start!=0 && end==this.props.pages.length-1 && <li> ... </li>}
                        {this.props.pages.length>5 && this.props.pages.slice(start,end).map((user, index) => (
                            <li key={index} onClick={() => { this.props.setPaging(index+start + 1), this.scrollToTop() }} className={this.props.pageNumber == (index+start + 1) ? "current" : ''}><a href="#">{index +start+ 1}</a></li>
                        ))}

                        {this.props.pages.length<=5 && this.props.pages.map((user, index) => (
                            <li key={index} onClick={() => { this.props.setPaging(index + 1), this.scrollToTop() }} className={this.props.pageNumber == (index + 1) ? "current" : ''}><a href="#">{index + 1}</a></li>
                        ))}
                        {/* show dot dot when end is less than pages length */}
                        {this.props.pages.length>5 && end!=this.props.pages.length-1 && <li> ... </li>}
                        {this.props.pages.length>5 &&
                            <li key={this.props.pages.length} onClick={() => { this.props.setPaging( this.props.pages.length), this.scrollToTop() }} className={this.props.pageNumber == (this.props.pages.length) ? "current" : ''}><a href="#">{this.props.pages.length}</a></li>
                        }

                        <li onClick={() => { this.props.pageNumber != this.props.pages.length ? (this.props.setPaging(this.props.pageNumber + 1),this.scrollToTop()) : {} }} className="next"><IconButton><i className="zmdi zmdi-caret-right"></i></IconButton></li>
                        <li onClick={() => { this.props.pageNumber != this.props.pages.length ? (this.props.setPaging(this.props.pages.length),this.scrollToTop()) : {} }} className="skip-next"><IconButton><i className="zmdi zmdi-skip-next"></i></IconButton></li>
                    </ul>
                    <div className="item-per-page selectoption">
                        <FormControl>
                            <Select native value={this.props.pageSize} onChange={(e) => {
                                this.props.setPaging(e);
                                this.scrollToTop();
                            }}>
                                {
                                    this.props.pagingOption.map((option) => (
                                        <option key={option} value={option}>{option}</option>
                                    ))
                                }
                            </Select>
                        </FormControl><span className="text-perpage">items per page</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pagination;
