import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import lodash from 'lodash'

const now = moment().format().substring(0, 10);

class CustomerLogList extends React.Component<any,any> {
    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            open: false,
            accountMenuOpen: false,
            hireDate: now,
            pageSize: 50,
            pageNumber: 1,
            sortColumn: 'firstName',
            sortOrder: 'desc',
            cutomerLogTable:-1
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleToggle = (value) => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        });
    };

    handleChange = event => {
        this.setState({ accountMenuOpen: !this.state.accountMenuOpen });
    };

    handleClose = event => {
        this.setState({ accountMenuOpen: false });
    };

    handleBulkUpdate = (values) => {
        this.handleClose(values)
        let checked = Object.assign([], this.state.checked)
        // this.props.handleBulkUpdate(values, checked)
        this.setState({ checked: [] })
    }

    setPaging = (e) => {
        if (e.target) {
            this.setState({ pageNumber: 1, pageSize: e.target.value })
            // this.props.callAPI('getUsers', { pageSize: e.target.value, pageNumber: 1, sortColumn: this.state.sortColumn, sortOrder: this.state.sortOrder })
        } else {
            if (e != this.state.pageNumber) {
                this.setState({ pageNumber: e })
                // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: e, sortColumn: this.state.sortColumn, sortOrder: this.state.sortOrder })
            }
        }
    }


    getPages = () => {
        let pages = (this.props.totalCount / this.state.pageSize)
        let mod = pages % 1
        if (mod > 0) {
            pages = parseInt(pages) + 1
        }
        let arr = []
        for (let i = 0; i < pages; i++) {
            arr.push({})
        }
        return arr
    }

    handleSort = (column) => {
        if (this.state.sortColumn == column) {
            if (this.state.sortOrder == 'desc') {
                this.setState({ sortOrder: 'asc' })
                // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'asc' })
            } else {
                this.setState({ sortOrder: 'desc' })
                // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'desc' })
            }
        } else {
            this.setState({ sortColumn: column, sortOrder: 'desc' })
            // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'desc' })
        }
    }


    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
          for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
     }
    cutomerLogTableDataCollapse = (index) =>{
        if(this.state.cutomerLogTable == index){
            this.setState({
                cutomerLogTable:-1
            })
        } else {
            this.setState({
                cutomerLogTable:index
            })
        }
    }

    render() {
        const { open, accountMenuOpen, checked } = this.state;
        let pages = this.getPages()

        return (
            <div className={"CustomerContractList"}>
                <table cellpadding="0" cellspacing="0" border="0" className="tbl-data responsiveTbl">
                        <thead>
                            <tr>
                                <td className="resCol100" width="20%"><div className="sort">Date & time</div></td>
                                <td className="resColHide" width="40%"><div className="sort">Old value(s)</div></td>
                                <td className="resColHide"><div className="sort">New value(s)</div></td>

                            </tr>
                        </thead>
                        {this.props.logs.map((log, index) => (
                        // <tbody className={!this.props.listView && this.props.currentUser &&  this.props.currentUser.userId  && this.props.currentUser.userId == log.id  ? 'active' : ''}
                        ((this.props.allowOnlyTen && index < 10) || (!this.props.allowOnlyTen)) &&
                            <tbody key={index}>
                                <tr key={log.masterUuid}>
                                    <td className="relativeRes">
                                        <span className="dateFormate1">{moment(log.modifiedDate).format('MM/DD/YYYY hh:mm:ss A')}</span>
                                        <span>Source:<strong>{log.source}</strong></span><br/>
                                        <span>By:<strong>{log.updatedBy}</strong></span><br/>
                                        <span>Action:<strong>{log.action}</strong></span>
                                        <div className="collapsible responsive" onClick={()=>{this.cutomerLogTableDataCollapse(index)}}><i className={this.state.cutomerLogTable == index ? "zmdi zmdi-minus" : "zmdi zmdi-plus"}/></div>
                                    </td>
                                    <td data-head="Old value(s)"
                                        className={this.state.cutomerLogTable == index ? "dataHead" : "resColcollapse"}>{getArr(log.Activities.old).map(x => (
                                        <div className="multiline"
                                             key={x}>{this.titleCase(x.split('_').join(' ')) == 'Oldvaluelogstatus' ? '' : this.titleCase(x.split('_').join(' '))}{this.titleCase(x.split('_').join(' ')) == 'Oldvaluelogstatus' ? '' : ' => '}{getTXT(log.Activities.old[x])}</div>))}</td>
                                    <td data-head="New value(s)"
                                        className={this.state.cutomerLogTable == index ? "dataHead" : "resColcollapse"}>{getArr(log.Activities.attributes).map(x => (
                                        <div className="multiline"
                                             key={x}>{this.titleCase(x.split('_').join(' ')) == 'Logstatus' ? '' : this.titleCase(x.split('_').join(' '))}{this.titleCase(x.split('_').join(' ')) == 'Logstatus' ? '' : ' => '}{getTXT(log.Activities.attributes[x])}</div>))}</td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
            </div>

        );
    }
}

const titlesCases = (str) => {
    var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
 }

const getArr = (obj) => {
    return Object.keys(obj)
}

const getTXT = (obj) => {
    if(lodash.isObject(obj)){
       return obj[Object.keys(obj)[0]];
    }else{
        return obj
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    totalCount: state.user.totalcount,
    currentUser: state.user.currentUser,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLogList);
