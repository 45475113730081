import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import CustomInputField from '../atoms/CustomInputField'
import utilCommon from '../../helpers/utilCommon'
import Button from '@material-ui/core/Button';


const Link = require('react-router-dom').Link;



const validate = (formProps) => {
    const errors: any = {};
    if (!formProps.password) {
        errors.password = 'Required';
    } else if (!utilCommon.Password_Regex.test(formProps.password)) {
        errors.password = 'The password should be alphanumeric with minimum of 8 characters and a special character.';
    }

    if (!formProps.email) {
        errors.email = 'Required';
    } else if (!utilCommon.Email_Regex.test(formProps.email)) {
        errors.email = 'Please enter a valid email address.';
    }

    return errors;
}

export class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <form name="LoginForm" onSubmit={handleSubmit}>
                <div className="form-group text-center row">
                    <Field label="Email Address" name="email" type='email' autoFocus component={CustomInputField} />
                </div>
                <div className="form-group text-center row lastrow">
                    <Field label="Password" type="password" name="password" component={CustomInputField} />
                </div>
                <div className="form-group txtCenter row btnWrap">
                    <br />
                    <Button type="submit" variant="contained" disabled={submitting || this.props.loading} color="primary" className="btnPrimary">
                        Reset Password
                 </Button>
                </div>
                <div className="form-group txtCenter row backLogin shortdes">
                    <p>Return to  <Link to="/"><a href="javascript:void()">Login Page</a></Link>  </p>
                </div>
            </form>
        )
    }
}

export default connect((state) => ({
    loading: state.common.loading,
}), {})(reduxForm({
    form: 'ResetPasswordForm',
    validate,
})(ResetPasswordForm));
