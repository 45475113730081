import React from "react";
import {connect} from "react-redux";
import Loader from '../../atoms/Loader'
import lodash from 'lodash';
import {Link} from "react-router-dom";
import {
    REWARD_TRANSACTION_REDEEM,
    REWARD_TRANSACTION_TYPE_ADDED_BY_SHIPMENT,
    REWARD_TRANSACTION_TYPE_EXPIRATION,
    REWARD_TRANSACTION_TYPE_MANUALLY_ADDED
} from "@helpers/enums";
import moment from "moment";
import {getFormatIncludeCommas} from '@helpers/utilCommon';
import rewardService from "@services/rewardService";
import customerService from "@services/customerService";

const rewardsPointsLogStyles = {
    link: {'textDecoration': 'unset', 'color': '#4a4a4a', 'fontSize': '12px'},
    negativeAmount: {color: '#ff6868'},
    customerBalance: {'float': 'right'},
    details: {fontSize: 12}
}

class RewardsPointsLogList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customerId: null
        }
    }

    componentDidMount() {
        this.props.getRewardSettings(this.props.currentLanguage, this.props.dictionaries);
        const customerId = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
        this.props.getCustomerData({customerUuid: customerId}, this.props.currentLanguage, this.props.dictionaries);

        this.setState({customerId});
        this.props.getCustomerRewardsPointsLogs(
            customerId,
            this.props.currentLanguage,
            this.props.dictionaries
        );
    }

    getDescription = (rewardsPoints) => {
        const type = rewardsPoints.transactionType;

        let description = '';
        switch (type) {
            case REWARD_TRANSACTION_TYPE_ADDED_BY_SHIPMENT:
                description = `${getFormatIncludeCommas(Math.abs(rewardsPoints.points))} shipment rewards points ${rewardsPoints.points > 0 ? 'added' : 'removed'}.`;
                break;
            case REWARD_TRANSACTION_TYPE_MANUALLY_ADDED:
                description = `${getFormatIncludeCommas(Math.abs(rewardsPoints.points))} rewards points ${rewardsPoints.points > 0 ? 'added' : 'removed'} manually.`;
                break;
            case REWARD_TRANSACTION_REDEEM:
                description = `${getFormatIncludeCommas(Math.abs(rewardsPoints.points))} rewards points used.`;
                break;
            case REWARD_TRANSACTION_TYPE_EXPIRATION:
                description = `${getFormatIncludeCommas(Math.abs(rewardsPoints.points))} rewards points expired`;
                break;
        }

        return description;
    }

    getShipmentId = (shipmentId) => {
        return shipmentId
            ? (
                <Link style={rewardsPointsLogStyles.link}
                      to={`/home/shipment-management/${shipmentId}`}>{shipmentId}
                </Link>
            )
            : ''
    }

    getAmount = (rewardsPoints) => {
        const amount = rewardsPoints.points > 0
            ? `${getFormatIncludeCommas(rewardsPoints.points)}`
            : `(${getFormatIncludeCommas(rewardsPoints.points)})`;
        const styles = rewardsPoints.points > 0
            ? {}
            : rewardsPointsLogStyles.negativeAmount

        return (<td style={styles}>{amount}</td>);
    }

    getExpirationDate = (rewardsPoints) => {
        return rewardsPoints.points > 0
            ? moment(rewardsPoints.createdAt).add(this.props.rewardSettings.days_to_expire, 'days').format('M/D/YYYY')
            : '';
    }

    render() {
        return (
            <div className="main-wraper mt30">
                <div className="main-content">
                    <div className="page-title"><h2>Rewards points logs</h2></div>
                    <div className="white-section search-cutomer">
                        <fieldset className={"tableFieldset p10 bdr0"}>
                            <div className="filter-title-row1 mb10 uppercase pl10">
                                <span className="font13 pl10">CUSTOMER ID# {this.state.customerId}</span>
                                <span style={rewardsPointsLogStyles.customerBalance} className="font13 pr10">REWARDS POINTS TOTAL BALANCE: {getFormatIncludeCommas(lodash.get(this.props, 'customerState.rewardPoints', 0))}</span>
                            </div>
                            <div className="searchbaar open">
                                <table cellpadding="0" cellspacing="0" border="0" className="tbl-data">
                                    <thead>
                                    <tr>
                                        <td width="10%">
                                            <div className="sort">Shipment</div>
                                        </td>
                                        <td width="20%">
                                            <div className="sort">Description</div>
                                        </td>
                                        <td width="10%">
                                            <div className="sort">Date and time</div>
                                        </td>
                                        <td width="10%">
                                            <div className="sort">Amount</div>
                                        </td>
                                        <td width="10%">
                                            <div className="sort">Expiration on</div>
                                        </td>
                                    </tr>
                                    </thead>
                                    {
                                        !lodash.isEmpty(this.props.customerRewardsPointsLogs) &&
                                        this.props.customerRewardsPointsLogs.map(rewardsPoints => (
                                                <tbody key={rewardsPoints.id}>
                                                <tr key={rewardsPoints.id}>
                                                    <td>{this.getShipmentId(rewardsPoints.shipmentUuid)}</td>
                                                    <td>
                                                        <div style={{whiteSpace: 'pre-line'}}>
                                                            <p style={rewardsPointsLogStyles.details}>{this.getDescription(rewardsPoints)}</p>
                                                        </div>
                                                    </td>
                                                    <td>{moment(rewardsPoints.createdAt).format('M/D/YYYY LTS')}</td>
                                                    {this.getAmount(rewardsPoints)}
                                                    <td>{this.getExpirationDate(rewardsPoints)}</td>
                                                </tr>
                                                </tbody>
                                            )
                                        )
                                    }
                                    {
                                        lodash.isEmpty(this.props.customerRewardsPointsLogs)
                                            ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan="8" className="error txtCenter">No Records Found</td>
                                                </tr>
                                                </tbody>
                                            )
                                            : null
                                    }
                                </table>
                            </div>
                            {this.props.loading && <Loader/>}
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    customerState: state.customer.customerState,
    customerRewardsPointsLogs: state.customer.customerRewardsPointsLogs,
    rewardSettings: state.reward.rewardSettings
});

const mapDispatchToProps = dispatch => ({
    getCustomerRewardsPointsLogs: (customerId, currentLanguage, dictionaries) => {
        rewardService.getCustomerRewardsPointsLogs(dispatch, currentLanguage, dictionaries, customerId);
    },
    getCustomerData: (inputDTO, currentLanguage, dictionaries) => {
        customerService.getCustomerData(dispatch, currentLanguage, dictionaries, inputDTO);
    },
    getRewardSettings: (currentLanguage, dictionaries) => {
        rewardService.getRewardSettings(dispatch, currentLanguage, dictionaries);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsPointsLogList);
