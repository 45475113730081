import axios from 'axios';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon, {convertObjectToQueryString} from '../../helpers/utilCommon'
import * as actions from '../../store/carrier/actions'
import * as commonActions from '../../store/common/actions'
import * as type from '../../store/carrier/actionTypes'
import contractService from './contractService';
import {getTLShipment} from "@services/shipmentService";
import * as commonAction from "@store/common/actions";




export const getCarrierLogs = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'carrierLogs');
    dispatch(commonActions.setLoader(true))
    dispatch({ type: type.SET_CARRIER_LOGS, logs: [], totalResults: 0})
    axios.get(url + "?id=" + inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CARRIER_LOGS, logs: [], totalResults: 0 })
                dispatch({ type: type.SET_CARRIER_LOGS, logs: response.data.content.activityLog, totalResults: response.data.content.totalResults })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// Add/Update Carriers
export const addCarrrier = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'addCarrier');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.carrierId ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (!inputDTO.carrierId) {
                    dispatch(actions.setContracts([]))
                    utilCommon.setSession('CarrierId', response.data.success.responseData ? response.data.success.responseData.carrierId : '')
                    utilCommon.setSession('CarrierName', inputDTO.carrierName ? inputDTO.carrierName : '')
                    contractService.getCarriers(dispatch, lang, dictionary);
                }
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getCarrriers(dispatch, lang, dictionary, { pageSize: 50, pageNumber: 1, sortColumn: 'carrierName', sortOrder: 'asc' })
                if(inputDTO.carrierUuid) {
                    getCarrierLogs (dispatch, lang, dictionary, inputDTO.carrierUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};

export const getAllCarriers = (dispatch) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'getCarriers')

    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_CARRIERS, carriers: response.data.content.carrierList })
            }
        })
        .catch((err) => {});
}

export const getCarrriers = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('carrier', 'getCarriers');
    dispatch({ type: type.SET_LOADER, flag: true })
    url = url + '?pageSize=' + inputDTO.pageSize + '&pageNumber=' + (inputDTO.pageNumber) + '&sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder
    if (inputDTO.keyword) {
        url = url + '&keyword=' + (inputDTO.keyword.includes('&') ? inputDTO.keyword.split('&')[0]+encodeURIComponent('&')+inputDTO.keyword.split('&')[1]:inputDTO.keyword)
    }
    dispatch(commonActions.setLoader(true))
    dispatch({ type : type.SET_KEYWORD , searchKeyword : null })
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (inputDTO.keyword && response.data.content.carrierList && response.data.content.carrierList.length == 0) {
                    utilCommon.showNotification(9002, dictionary, lang)
                }
                if(inputDTO.keyword && inputDTO.keyword.length > 0){
                    dispatch({ type : type.SET_KEYWORD , searchKeyword : inputDTO.keyword })
                 }
                dispatch(actions.setCarriers(response.data.content.carrierList, response.data.content.count))
                dispatch({type:type.SET_CARRIER_PAGINATION,pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder });
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
    });
};

export const getContracts = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('carrier', 'getContract');
    dispatch({ type: type.SET_LOADER, flag: true })
    dispatch(commonActions.setLoader(true))
    dispatch(actions.setContracts([]))
    url = url + inputDTO + '&pageSize=' + 100 + '&pageNumber=' + 1
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch(actions.setContracts(response.data.content.contractList))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const bulkUpdateCarrriers = (dispatch, lang, dictionary, inputDTO) => {
    if (inputDTO && inputDTO.carrierId.length == 0) {
        return
    }
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'bulkUpdateCarriers');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getCarrriers(dispatch, lang, dictionary, { pageSize: 50, pageNumber: 1, sortColumn: 'carrierName', sortOrder: 'asc' })
                getCarrriers(dispatch, lang, dictionary, { pageSize: 50, pageNumber: 1, sortColumn: 'carrierName', sortOrder: 'asc' })
                if(inputDTO.carrierUuid) {
                    getCarrierLogs (dispatch, lang, dictionary, inputDTO.carrierUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const deleteCarrier = (dispatch, lang, dictionary, inputDTO) => {
    if (inputDTO && inputDTO.carrierId.length == 0) {
        return
    }
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'bulkUpdateCarriers');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification(3025, dictionary, lang)
                getCarrriers(dispatch, lang, dictionary, { pageSize: 50, pageNumber: 1, sortColumn: 'carrierName', sortOrder: 'asc' })
                getCarrriers(dispatch, lang, dictionary, { pageSize: 50, pageNumber: 1, sortColumn: 'carrierName', sortOrder: 'asc' })
                if(inputDTO.carrierUuid) {
                    getCarrierLogs (dispatch, lang, dictionary, inputDTO.carrierUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getTLCarriers = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('carrier', 'getTLContract');

    dispatch(commonActions.setLoader(true))
    dispatch(actions.setContracts([]))
    url = `${url}/?${convertObjectToQueryString(inputDTO)}`;

    return axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                return response.data.data;
                //dispatch(actions.setContracts(response.data.data))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            err.response.data.errors.map((error) => {
                utilCommon.showNotification(error, dictionary, lang)
            })
            dispatch(commonActions.setLoader(false))
        });
};


export const addTLCarrier = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'addTLCarrier');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentId);
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            err.response.data.errors.map((error) => {
                utilCommon.showNotification(error, dictionary, lang)
            })
            dispatch(commonActions.setLoader(false))
        });
};

export const removeTLCarrier = (dispatch, lang, dictionary, shipmentId) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'addTLCarrier');
    dispatch(commonActions.setLoader(true))
    axios.delete(`${url}/${shipmentId}`, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, shipmentId);
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            err.response.data.errors.map((error) => {
                utilCommon.showNotification(error, dictionary, lang)
            })
            dispatch(commonActions.setLoader(false))
        });
};

export const addDriver = (dispatch, lang, dictionary, payload) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'driver');

    dispatch(commonAction.setLoader(true))

    axios.post(`${url}`, payload, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, payload.shipmentId);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
};

export const updateDriver = (dispatch, lang, dictionary, payload, driverId) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'driver');

    dispatch(commonAction.setLoader(true))

    axios.put(`${url}/${driverId}`, payload, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, payload.shipmentId);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
};

export const removeDriver = (dispatch, lang, dictionary, driverId, shipmentId) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('carrier', 'driver');

    dispatch(commonAction.setLoader(true))

    axios.delete(`${url}/${driverId}`, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification(response.data.message, dictionary, lang)
                getTLShipment(dispatch, lang, dictionary, shipmentId);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        })
};


export default {
    getAllCarriers,
    addCarrrier,
    getCarrriers,
    getContracts,
    bulkUpdateCarrriers,
    deleteCarrier,
    getCarrierLogs,
    getTLCarriers,
    addTLCarrier,
    removeTLCarrier,
    addDriver,
    removeDriver,
    updateDriver
};


