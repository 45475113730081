import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import * as commonActions from '../../../store/common/actions';
const DebugModal = ({ open = false, header = '', message = '', scroll, handleClose, logData=[] }) => (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={undefined}
        aria-labelledby="scroll-dialog-title"
        maxWidth={'md'}
        className={"DialogBox large"}
    >
        <DialogTitle className="DialogTitle">
            <h2>{header} <i className="zmdi zmdi-close" onClick={handleClose} /></h2>
        </DialogTitle>
        <DialogContent className="DialogBoxcontent">
            <DialogContentText id="alert-dialog-description">
                <fieldset>
                    {logData.map((item, index) =>
                        <div key={index}>
                            <b>{item.name}</b>:
                            <br/>
                            <pre>{item.data}</pre>
                        </div>
                    )}
                </fieldset>
            </DialogContentText>
        </DialogContent>
    </Dialog>
)

const mapStateToProps = state => ({
    open: state.common.debugOpen||false,
    logData: state.common.logData||[]
});
const mapDispatchToProps = dispatch => ({
    handleClose: () => {
        dispatch(commonActions.closeConsole())
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugModal);

