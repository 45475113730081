import * as type from './actionTypes';

export const setUsers = (users,totalCount,append) => ({
  type: type.SET_USERS,
  users,
  totalCount,
  append
});

export const setCurrentUser = (user) => ({
  type: type.SET_USER,
  user
});

export const setModules = (modules) => ({
  type: type.SET_MODULES,
  modules
});
