import * as type from './actionTypes';


export const setMessage = (messageType,content) => ({
  type: type.SET_MESSAGE,
  messageType,
  content
});

export const setLoader = (flag) => ({
  type: type.SET_LOADER,
  flag
});

export const showConsole = () => ({
  type: type.SHOW_CONSOLE,
  debugOpen: true
});

export const closeConsole = () => ({
  type: type.SHOW_CONSOLE,
  debugOpen: false
});

export const addLog = (data, name = '') => ({
  type: type.CONSOLE_ADD,
  name,
  data
})





