import React from "react";
import { connect } from "react-redux";
import * as type from '../../../store/login/actionTypes';
import loginService from "../../../services/api/loginService";

const Link = require('react-router-dom').Link

// component
class HealthCheck extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            healthy: "Healthy",
            unhealthy: "Unhealthy"
        };
    }

    componentWillMount() {
        this.props.healthCheck(this.props.currentLanguage, this.props.dictionaries);
    }

    render() {
        return (
            <div>
                {this.props.messageStatus == 200 ? this.state.healthy : this.state.unhealthy}
            </div>
        );
    }
}

// informs component what props will be added
const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    messageStatus: state.login.messageStatus || null
});

// update data in store
const mapDispatchToProps = dispatch => ({
    healthCheck: (currentLanguage, dictionaries) => {
        loginService.healthCheck(dispatch, currentLanguage, dictionaries)
    }
});

//connect to map the stores state and dispatch to the props of a component
export default connect(mapStateToProps, mapDispatchToProps)(HealthCheck);