// @flow
import * as type from './actionTypes';

const initialState = {
  leads: [
    {
      leadUuid: "1",
      customerUuid: "2",
      agentUserUuid: "3",
      assignedTo: "Anil",
      isContacted: "1",
      lastContacted: "2018-10-04 17:22:01",
      contactedOn: "2018-10-04 17:22:01",
      contacts: "3",
      addedDate: "2018-10-04 17:22:01",
      timezone: "timexone",
      leadModeType: "2",
      leadType: "1"
    },
    {
      leadUuid: "1",
      customerUuid: "2",
      agentUserUuid: "3",
      assignedTo: "Anil",
      isContacted: "1",
      lastContacted: "2018-10-04 17:22:01",
      contactedOn: "2018-10-04 17:22:01",
      contacts: "3",
      addedDate: "2018-10-04 17:22:01",
      timezone: "timexone",
      leadModeType: "2",
      leadType: "1"
    }
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    default:
      return state;
  }
};

export default reducer;
