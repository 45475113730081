import React from 'react';

const Toaster = ({
    msg, type,textType
}) => (
    <div>
        <h1><i
            className={type === 'error' ? "zmdi zmdi-alert-triangle white" : "zmdi zmdi-alert-circle-o white"}></i> {type === 'error' ? ((textType && textType == 1) ? "Shipment Alert" : "Error!") : "Info"}
            <span>1 m ago</span></h1>
        <p dangerouslySetInnerHTML={{__html: msg}}></p>
    </div>

    );

export default Toaster;
