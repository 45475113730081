let apiUrls = {
    baseUrl: process.env.REACT_APP_API_URL,
    localBaseUrl: process.env.REACT_APP_API_URL,
    live: {
        login: {
            userLogin: {
                url: '/tms-login',
                version: '/v1'
            },
            forgotPassword: {
                url: '/forgot-password',
                version: '/v1'
            },
            resetPassword: {
                url: '/reset-password',
                version: '/v1'
            },
            getMasterData: {
                url: '/composite-master?status=1',
                version: '/v1'
            },
            logout:{
                url: '/logout',
                version: '/v1'
            },
        },
        carrier: {
            addCarrier: {
                url: '/carrier',
                version: '/v1'
            },
            addTLCarrier: {
                url: '/tl/shipment/carriers',
                version: '/v1'
            },
            getCarriers: {
                url: '/carrier',
                version: '/v1'
            },
            getContract: {
                url: '/contract?carrierUuid=',
                version: '/v1'
            },
            getTLContract: {
                url: '/carrier/preview',
                version: '/v1'
            },
            bulkUpdateCarriers: {
                url: '/carrier/bulk-update',
                version: '/v1'
            },
            getDocuments: {
                url: '/master/document-type',
                version: '/v1'
            },
            carrierLogs: {
                url: '/carrier/activity-log',
                version: '/v1'
            }
        },
        contract: {
            getAccessorial: {
                url: '/accessorial',
                version: '/v1'
            },
            generalInformation: {
                url: '/contract',
                version: '/v1'
            },
            getCarriers: {
                url: '/carrier',
                version: '/v1'
            },
            getCustomers: {
                url: '/customer',
                version: '/v1'
            },
            getCountry: {
                url: '/country',
                version: '/v1'
            },
            getState: {
                url: '/state',
                version: '/v1'
            },
            contractGeneralInfo: {
                url: '/contract/general-info',
                version: '/v1'
            },
            contractBillOfLading: {
                url: '/contract/bill-of-lading',
                version: '/v1'
            },
            contractProfitMargin: {
                url: '/contract/profit-margin',
                version: '/v1'
            },
            contractFuelSurcharge: {
                url: '/contract/fuel-surcharge',
                version: '/v1'
            },
            contractContractSpecifics: {
                url: '/contract/contract-specifics',
                version: '/v1'
            },
            contractLiabilityCovrage: {
                url: '/liability-coverage',
                version: '/v1'
            },
            accessorial: {
                url: '/accessorial',
                version: '/v1'
            },
            excludeFreightClass: {
                url: '/exclude-freight-class',
                version: '/v1'
            },
            negotiatedLane: {
                url: '/negotiated-lane',
                version: '/v1'
            },
            commodityType: {
                url: '/commodity-type',
                version: '/v1'
            },
            negotiatedGlane: {
                url: '/negotiated-g-lane',
                version: '/v1'
            },
            contractLogs: {
                url: '/contract/activity-log',
                version: '/v1'
            },
            duplicateContract: {
                url: '/contract/contract-duplicate',
                version: '/v1'
            }
        },
        additionalSettings: {
            financialSettings: {
                url: '/master/financial-setting',
                version: '/v1'
            }
        },
        dashboard: {
            trendingSalesAgents: {
                url: '/dashboard/trending-sales-agents',
                version: '/v1'
            },
            customerTrending: {
                url: '/dashboard/customer-trending',
                version: '/v1'
            },
            notifications: {
                url: '/dashboard/notifications',
                version: '/v1'
            },
            notificationDismiss: {
                url: '/dashboard/notification-dismiss',
                version: '/v1'
            },
            taskList: {
                url: '/dashboard/tasks-dashlet',
                version: '/v1'
            },
            leadList: {
                url: '/dashboard/leads-dashlet',
                version: '/v1'
            },
            leadListSalesfloor: {
                url: '/dashboard/leads-tdashlet',
                version: '/v1'
            },
            performanceSnapshot: {
                url: '/dashboard/agent-performance-snapshot',
                version: '/v1'
            },
            shipmentAlert: {
                url: '/dashboard/shipment-alert',
                version: '/v1'
            },
            revenueByTeam: {
                url: '/dashboard/revenue-by-team',
                version: '/v1'
            },
            revenueByNewExisting: {
                url: '/dashboard/revenue-existing-vs-new',
                version: '/v1'
            },
            revenueByCustomerClass: {
                url: '/dashboard/revenue-by-customer-class',
                version: '/v1'
            },
            goalRankingData: {
                url: '/dashboard/consolidated-data',
                version: '/v1'
            },
            trendingAgents: {
                url: '/dashboard/trending-agents',
                version: '/v1'
            },
            baReportExport: {
                url: '/dashboard/ba-report-export',
                version: '/v1'
            }
        },
        reports:{
            registrationReports: {
                url: '/customer/registration-report',
                version: '/v1'
            },
            customerDiscountReports: {
                url: '/customer/discount-report',
                version: '/v1'
            },
            users: {
                url: '/user',
                version: '/v1'
            },
            statisticalReports: {
                url: '/reports/shipment-statistical',
                version: '/v1'
            },
            bookingReports:{
                url:'/report/daily-booking-insurance',
                version: '/v1'
            },
            salesTracking:{
                url:'/reports/sales-tracking',
                version: '/v1'
            },
            carrierPerformanceReport:{
                url:'/reports/carrier-performance',
                version: '/v1'
            },
            topDogReports:{
                url:'/reports/top-dog-report',
                version: '/v1'
            },
            agentBonusReport:{
                url:'/report/agent-bonus',
                version: '/v2'
            },
            agentBonusDetailedReport:{
                url:'/report/agent-bonus/detailed',
                version: '/v2'
            },
            coveredReport:{
                url:'/report/covered',
                version: '/v2'
            },
            agentRevenueComparisonReports: {
                url:'/reports/agent-revenue-comparison',
                version: '/v1'
            },
            customerTrendReports: {
                url:'/reports/customer-trend-report',
                version: '/v1'
            },
            dailySalesReports: {
                url:'/reports/daily-sales',
                version: '/v1'
            },
            agentConversionReport:{
                url:'/reports/agent-conversion-report',
                version: '/v1'
            },
            fcQuoteResultsReport:{
                url:'/reports/fc-quote-results-report',
                version: '/v1'
            }
        },
        applicationSettings: {
            transitTime: {
                url: '/master/transit-time',
                version: '/v1'
            },
            fuelSurcharge: {
                url: '/master/fuel-surcharge-index',
                version: '/v1'
            },
            fuelCostIndex: {
                url: '/master/fuel-cost-index',
                version: '/v1'
            },
            globalDiscount: {
                url: '/master/global-discount',
                version: '/v1'
            },
            loadingFacility: {
                url: '/master/loading-facility',
                version: '/v1'
            },
            accessorials: {
                url: '/master/accessorial',
                version: '/v1'
            },
            loadingFacilityAccessorial: {
                url: '/master/loading-facility-accessorial',
                version: '/v1'
            },
            bulkupdateFacilityTypes: {
                url: '/master/bulk-update-loading-facility',
                version: '/v1'
            },
            manageCoupon: {
                url: '/master/coupon-code',
                version: '/v1'
            },
            systemWideNotification: {
                url: '/master/system-notification',
                version: '/v1'
            },
            falveyTermsConditions: {
                url: '/master/falvey-terms-condition',
                version: '/v1'
            },
            tmsTermsConditions: {
                url: '/master/terms-condition',
                version: '/v1'
            },
            teams: {
                url: '/master/teams',
                version: '/v1'
            },
            teamUserCount: {
                url: '/master/team-user',
                version: '/v1'
            },
            ipAddress: {
                url: '/master/ip-address',
                version: '/v1'
            },
            getSupervisior: {
                url: '/user',
                version: '/v1'
            },
            popUpContent: {
                url: '/master/pop-up-content',
                version: '/v2'
            },
            carrierMarginAdjustments: {
                url: '/master/carrier-margin-adjustments',
                version: '/v2'
            },
            carrierMarginAdjustmentLogs: {
                url: '/master/carrier-margin-adjustment/logs',
                version: '/v2'
            },
        },
        user: {
            addUser: {
                url: '/user',
                version: '/v1'
            },
            getUsers: {
                url: '/user',
                version: '/v1'
            },
            bulUpdateUsers: {
                url: '/user/bulk-update',
                version: '/v1'
            },
            salesTeam: {
                url: '/sales-team',
                version: '/v1'
            },
            department: {
                url: '/department',
                version: '/v1'
            },
            payType: {
                url: '/pay-type',
                version: '/v1'
            },
            timeZone: {
                url: '/timezone',
                version: '/v1'
            },
            role: {
                url: '/role',
                version: '/v1'
            },
            updateUserInfo: {
                url: '/user/update-password',
                version: '/v1'
            },
            securityLevels: {
                url: '/master/level',
                version: '/v1'
            },
            securityRoles: {
                url: '/master/user-role',
                version: '/v1'
            },
            getModules: {
                url: '/master/modules',
                version: '/v1'
            },
            getMasterInfo: {
                url: '/composite-master?status=1',
                version: '/v1'
            }
        },
        customer: {
            getCountry: {
                url: '/country',
                version: '/v1'
            },
            getState: {
                url: '/state',
                version: '/v1'
            },
            getTimezone: {
                url: '/timezone',
                version: '/v1'
            },
            addCustomer: {
                url: '/customer',
                version: '/v1'
            },
            customer: {
                url: '/customer',
                version: '/v1'
            },
            preference: {
                url: '/customer/preference',
                version: '/v1'
            },
            note: {
                url: '/customer/note',
                version: '/v1'
            },
            forgotPassword: {
                url: '/customer/forgot-pwd',
                version: '/v1'
            },
            resetPassword: {
                url: '/customer/reset-pwd',
                version: '/v1'
            },
            address: {
                url: '/customer/address',
                version: '/v1'
            },
            product: {
                url: '/customer/product',
                version: '/v1'
            },
            carrier: {
                url: '/customer/carrier-preference',
                version: '/v1'
            },
            getCarriers: {
                url: '/carrier',
                version: '/v1'
            },
            customerMaster: {
                url: '/master/composite-customer',
                version: '/v1'
            },
            detailInfo: {
                url: '/customer/detailed-info',
                version: '/v1'
            },
            task: {
                url: '/user/task',
                version: '/v1'
            },
            agents: {
                url: '/user',
                version: '/v1'
            },
            contactlogs: {
                url: '/customer/contact-log',
                version: '/v1'
            },
            contactPricing: {
                url: '/customer/specific-pricing',
                version: '/v1'
            },
            payment: {
                url: '/payment/card',
                version: '/v1'
            },
            paymentAR: {
                url: '/payment/ar-info',
                version: '/v1'
            },
            creditApp: {
                url: '/fc/credit-app',
                version: '/v1'
            },
            customerLogs: {
                url: '/customer/activity-log',
                version: '/v1'
            },
            assignNewLead: {
                url: '/assign-new-lead',
                version: '/v1'
            },
            agentsList: {
                url: '/user/agent-list',
                version: '/v1'
            },
            getTemplates: {
                url: '/get-email-template',
                version: '/v1'
            },
            getPreviewTemplate: {
                url: '/send-email-template',
                version: '/v1'
            },
            sendMarketingTemplate: {
                url: '/customer/send-marketing-email',
                version: '/v1'
            },
            deleteProduct:{
                url: '/customer/product-bulk-del',
                version: '/v1'
            },
            merge: {
                url: '/customer/merge',
                version: '/v1'
            },
            sendMarketingEmails: {
                url: '/send-marketing-emails',
                version: '/v2'
            },
            reindex: {
                url: '/customer/reindex',
                version: '/v2'
            }
        },
        shipment: {
            shipment: {
                url: '/shipment',
                version: '/v1'
            },
            processRefund: {
                url: '/process-shipment-refund',
                version: '/v1'
            },
            getAccessorial: {
                url: '/master/accessorial?status=1',
                version: '/v1'
            },
            facility: {
                url: '/master/loading-facility',
                version: '/v1'
            },
            updateShipmentInfo: {
                url: '/shipment-info',
                version: '/v1'
            },
            note: {
                url: '/shipment/note',
                version: '/v1'
            },
            pinNote: {
                url: '/shipment/pin-note',
                version: '/v1'
            },
            product: {
                url: '/shipment/product',
                version: '/v1'
            },
            master: {
                url: '/master/composite-shipment',
                version: '/v1'
            },
            alert: {
                url: '/shipment/alert',
                version: '/v1'
            },
            deactivate: {
                url: '/shipment/deactivate',
                version: '/v1'
            },
            activate: {
                url: '/shipment/activate',
                version: '/v1'
            },
            transaction: {
                url: '/shipment/transaction',
                version: '/v1'
            },
            transactionDetail: {
                url: '/shipment/transaction-detail',
                version: '/v1'
            },
            transactionLog: {
                url: '/shipment/transaction-log',
                version: '/v1'
            },
            payment: {
                url: '/shipment/payment',
                version: '/v1'
            },
            shipmentPayment: {
                url: '/shipment-payment',
                version: '/v1'
            },
            shipmentRefund: {
                url: '/add-shipment-refund',
                version: '/v1'
            },
            transactionDetails: {
                url: '/shipment/transaction-detail',
                version: '/v1'
            },
            repeatShipment: {
                url: '/shipment/repeat',
                version: '/v1'
            },
            coupon: {
                url: '/master/validate-coupon-code',
                version: '/v1'
            },
            validateInsurance: {
                url: '/shipment/calculate-insurance',
                version: '/v1'
            },
            insurance: {
                url: '/shipment/cancel-insurance',
                version: '/v1'
            },
            processPayment: {
                url: '/payment-process',
                version: '/v1'
            },
            submitToProcess: {
                url: '/shipment/submit-to-process',
                version: '/v1'
            },
            documents: {
                url: '/shipment/documents',
                version: '/v1'
            },
            documentEmail: {
                url: '/shipment/documents/mail',
                version: '/v1'
            },
            documentBol: {
                url: '/shipment/documents/bol',
                version: '/v1'
            },
            documentPreview: {
                url: '/shipment/documents-preview',
                version: '/v1'
            },
            shipmentCharges: {
                url: '/shipment/shipment-charges',
                version: '/v1'
            },
            customBroker: {
                url: '/shipment/custom-broker',
                version: '/v1'
            },
            cancelShipment: {
                url: '/shipment/cancel',
                version: '/v1'
            },
            cancelRefundShipment: {
                url: '/shipment/cancel-and-refund',
                version: '/v2'
            },
            fetchDocument: {
                url: '/shipment/fetch-document',
                version: '/v1'
            },
            bookShipment: {
                url: '/booking-shipment',
                version: '/v1'
            },
            tracking: {
                url: '/shipment-tracking',
                version: '/v1'
            },
            trackingDetails: {
                url: '/shipment-tracking-details',
                version: '/v1'
            },
            calcuateRefund: {
                url: '/shipment/calculate-refund',
                version: '/v1'
            },
            covertShipment: {
                url: '/shipment/convert',
                version: '/v1'
            },
            falvey: {
                url: '/customer-falvey-term',
                version: '/v1'
            },
            termCondition: {
                url: '/customer/terms-and-conditions',
                version: '/v1'
            },
            shipmentLogs: {
                url: '/shipment/activity-log',
                version: '/v1'
            },
            invoice:{
                url: '/shipment/invoice',
                version: '/v1'
            },
            loadPostingDatCom: {
                url: '/load-posting/datcom',
                version: '/v1'
            },
            loadPostingTruckstop: {
                url: '/load-posting/truckstop',
                version: '/v1'
            },
            tlAddress: {
                url: '/tl/shipment/address',
                version: '/v1'
            },
            tlShipment: {
                url: '/shipments',
                version: '/v2'
            },
            getMode:{
                url: '/shipment/shipping-mode',
                version: '/v1'
            },
            getTlTrackingOrderStatuses: {
                url: '/tl-tracking/get-order-statuses',
                version: '/v2'
            },
            getTlTrackingTripEvents: {
                url: '/tl-tracking/get-trip-events',
                version: '/v2'
            },
            getTlTrackingLocationUpdates: {
                url: '/tl-tracking/get-location-updates',
                version: '/v2'
            },
            getTlTrackingScheduleAlerts: {
                url: '/tl-tracking/get-schedule-alerts',
                version: '/v2'
            },
            createTripEvents : {
                url : '/shipment/trip-events',
                version: '/v2'
            },
            createLocationUpdates : {
                url : '/shipment/location-updates',
                version: '/v2'
            },
            bookTlShipment: {
                url: '/tl/shipment/book',
                version: '/v2'
            },
            reassignTransactions: {
                url: '/shipment/transactions/reassign',
                version: '/v2'
            },
            invalidateShouldRefreshFlag: {
                url: '/shipment/refreshed',
                version: '/v2'
            },
            flipShipmentMode: {
                url: '/shipment/flip-shipment-mode',
                version: '/v2'
            },
            processCostAdjustment: {
                url: '/shipment/process-cost-adjustment',
                version: '/v2'
            },
            processPaymentAndBook: {
                url: '/process-payment-and-book',
                version: '/v1'
            },
            cancellationInfo: {
                url: '/shipment/cancellation-info',
                version: '/v2'
            },
            uploadProductImage: {
                url: '/shipment/upload-image',
                version: '/v2'
            }
        },
        quote: {
            getFaciltyTypes: {
                url: '/master/facility-type-accessorial?status=1&visible=2',
                version: '/v1'
            },
            getQuoteResults: {
                url: '/get-rate-quote',
                version: '/v1'
            },
            createLTLShipment: {
                url: '/shipment',
                version: '/v1'
            },
            reRunQuote: {
                url: '/shipment/re-run',
                version: '/v1'
            },
            getContracts: {
                url: '/contract',
                version: '/v1'
            },
            createTLShipment:{
                url: '/tl/shipment',
                version: '/v1'
            },
            updateTLShipment:{
                url: '/tl/shipment',
                version: '/v1'
            }
        },
        leads: {
            getLeadSettings: {
                url: '/lead-settings-params',
                version: '/v1'
            },
            setLeadSettings: {
                url: '/lead-settings',
                version: '/v1'
            },
            getAllLeads: {
                url: '/lead/list',
                version: '/v1'
            },
            bulkUpdateLeads: {
                url: '/lead',
                version: '/v1'
            },
            getAllLeadsStatusMaster: {
                url: '/lead-status-params',
                version: '/v1'
            },
            getAllManagementReviewReason: {
                url: '/lead-management-review-reasons',
                version: '/v1'
            },
            updateLeadSettings: {
                url: '/user/lead-filter-settings',
                version: '/v1'
            },
            getAdminLeadQuota: {
                url: '/lead/lead-quota-list',
                version: '/v1'
            },
            getAdminLeads: {
                url: '/lead/admin-lead-list',
                version: '/v1'
            },
            updateAdminLead: {
                url: '/lead/admin-lead',
                version: '/v1'
            },
            getLeadsContactedReport: {
                url: '/lead/leads-contacted-report',
                version: '/v2'
            },
            purgeLeads: {
                url: '/lead/purge',
                version: '/v2'
            }
        },
        report: {
            processingReviewQueue: {
                url: '/shipment/processing-queue',
                version: '/v1'
            },
            processingReviewHeader: {
                url: '/shipment/processing-data',
                version: '/v1'
            },
            customerARReport: {
                url: '/customer/report-ar-customer',
                version: '/v1'
            },
            frequentshippersByArea: {
                url: '/reports/freq-shippers',
                version: '/v1'
            },
            salesTracking:{
                url:'/reports/sales-tracking',
                version: '/v1'
            },
            agentRevenueComparisonReports: {
                url:'/reports/agent-revenue-comparison',
                version: '/v1'
            },
            customerTrendReports: {
                url:'/reports/customer-trend-report',
                version: '/v1'
            },
            dailySalesReports: {
                url:'/reports/daily-sales',
                version: '/v1'
            }
        },
        notifications: {
            salesAgentNotification: {
                url: '/user/notification-read',
                version: '/v1'
            }
        },
        accounting:{
            shipmentWithoutCarrierBill:{
                url : '/shipment/without-carrier-queue',
                version: '/v1'
            },
            reconcileShipment:{
                url :  '/shipment/reconcile-shipment',
                version: '/v1'
            },
            newBAQueue:{
                url :  '/shipment/new-ba-queue',
                version: '/v1'
            },
            inProgressBAQueue:{
                url :  '/shipment/inprogress-queue',
                version: '/v1'
            },
            processedBAQueue:{
                url :  '/shipment/processed-ba-queue',
                version: '/v1'
            },
            carrierDispute:{
                url :  '/shipment/dispute-queue',
                version: '/v1'
            },
            carrierBill:{
                url :  '/shipment/carrier-bill-preview',
                version: '/v1'
            },
            assignCarrierBill:{
                url:'/shipment/billing-adjustment-queue',
                version: '/v1'
            },
            getReviewBADetails:{
                url:'/shipment/preview-billing-adjustment',
                version: '/v1'
            },
            newBAFCError:{
                url:'/shipment/newba-fcerror',
                version: '/v1'
            },
            inProgressFCError:{
                url:'/shipment/inprog-fcerror',
                version: '/v1'
            },
            carrierDisputeFCError:{
                url:'/shipment/dispute-fcerror',
                version: '/v1'
            },
            newBADispute:{
                url:'/shipment/newba-to-dispute',
                version: '/v1'
            },
            approveNewBA:{
                url:'/shipment/newba-approve',
                version: '/v1'
            },
            inProgressDispute:{
                url:'/shipment/inprog-to-dispute',
                version: '/v1'
            },
            approveInProgress:{
                url:'/shipment/inprog-approve',
                version: '/v1'
            },
            chargeNow:{
                url:'/shipment/inprog-charge-now',
                version: '/v1'
            },
            removeFromInProgressBaQueue:{
                url:'/shipment/inprog-remove-from-queue',
                version: '/v1'
            },
            resolveDispute:{
                url:'/shipment/dispute-bill-update',
                version: '/v1'
            },
            customerError:{
                url:'/shipment/dispute-customer-error',
                version: '/v1'
            },
            baNoteRequired:{
                url:'/shipment/dispute-ba-not-required',
                version: '/v1'
            },
            newBaNoteRequired:{
                url:'/shipment/new-ba-not-required',
                version: '/v1'
            },
            removeFromNewBaQueue:{
                url:'/shipment/remove-from-new-ba-queue',
                version: '/v1'
            },
            invoiceData:{
                url:'/shipment/ba-invoice',
                version: '/v1'
            },
            saveAndPreviewInvoice:{
                url:'/shipment/update-ba-queue',
                version: '/v1'
            },
            refreshNewBaQueueDetailsDescription:{
                url:'/shipment/refresh-ba-queue-details-description',
                version: '/v1'
            },
            removeCarrierBill:{
                url:'/shipment/remove-carrier-bill',
                version: '/v1'
            },
            saveNote:{
                url:'/shipment/save-note',
                version: '/v1'
            },
            addNewBa: {
                url:'/accounting/add-new-ba',
                version: '/v2'
            }
        },
        adminTools:{
            billingAdjustment:{
                url:'/shipment',
                version: '/v1'
            },
            changeQuoteStatus:{
                url:'/shipment',
                version: '/v1'
            },
            deleteShipmentCharge:{
                url:'/shipment/delete-charge',
                version: '/v1'
            },
            updatePayment:{
                url:'/shipment/payment',
                version: '/v1'
            },
            deletePayment:{
                url:'/shipment/payment',
                version: '/v1'
            },
            insertInsurance:{
                url:'/shipment/update-insurance',
                version: '/v1'
            },
            removeInsurance:{
                url:'/shipment/remove-insurance',
                version: '/v1'
            },
            updateTlDocs: {
                url: '/tl/shipment/update-tl-docs',
                version: '/v2'
            },
        }
    },
    local: {
        login: {
            userLogin: {
                url: '/tms-login',
                version: '/v1'
            },
            forgotPassword: {
                url: '/forgot-password',
                version: '/v1'
            },
            resetPassword: {
                url: '/reset-password',
                version: '/v1'
            },
            getMasterData: {
                url: '/composite-master',
                version: '/v1'
            }
        },
        carrier: {
            addCarrier: {
                url: '/carrier',
                version: '/v1'
            },
            addTLCarrier: {
                url: '/tl/shipment/carriers',
                version: '/v1'
            },
            getCarriers: {
                url: '/carrier',
                version: '/v1'
            },
            bulkUpdateCarriers: {
                url: '/carrier/bulk-update',
                version: '/v1'
            },
            carrierLogs: {
                url: '/carrier/activity-log',
                version: '/v1'
            },
            driver:{
                url: '/tl/shipment/carriers/drivers',
                version: '/v1'
            }
        },
        contract: {
            getAccessorial: {
                url: '/accessorial',
                version: '/v1'
            },
            generalInformation: {
                url: '/contract',
                version: '/v1'
            },
            getCarriers: {
                url: '/carrier',
                version: '/v1'
            },
            getCustomers: {
                url: '/customer',
                version: '/v1'
            },
            getCountry: {
                url: '/country',
                version: '/v1'
            },
            getState: {
                url: '/state',
                version: '/v1'
            },
            contractGeneralInfo: {
                url: '/contract/general-info',
                version: '/v1'
            },
            contractBillOfLading: {
                url: '/contract/bill-of-lading',
                version: '/v1'
            },
            contractProfitMargin: {
                url: '/contract/profit-margin',
                version: '/v1'
            },
            contractFuelSurcharge: {
                url: '/contract/fuel-surcharge',
                version: '/v1'
            },
            contractContractSpecifics: {
                url: '/contract/contract-specifics',
                version: '/v1'
            },
            contractLogs: {
                url: '/contract/activity-log',
                version: '/v1'
            },
            duplicateContract: {
                url: '/contract/contract-duplicate',
                version: '/v1'
            }
        },
        dashboard: {
            trendingSalesAgents: {
                url: '/dashboard/trending-sales-agents',
                version: '/v1'
            }
        },
        user: {
            addUser: {
                url: '/user',
                version: '/v1'
            },
            getUsers: {
                url: '/user',
                version: '/v1'
            },
            bulUpdateUsers: {
                url: '/user/bulk-update',
                version: '/v1'
            },
            salesTeam: {
                url: '/sales-team',
                version: '/v1'
            },
            department: {
                url: '/department',
                version: '/v1'
            },
            payType: {
                url: '/pay-type',
                version: '/v1'
            },
            timeZone: {
                url: '/timezone',
                version: '/v1'
            },
            role: {
                url: '/role',
                version: '/v1'
            },
            updateUserInfo: {
                url: '/user/update-password',
                version: '/v1'
            }
        },
        customer: {
            getCountry: {
                url: '/country',
                version: '/v1'
            },
            getState: {
                url: '/state',
                version: '/v1'
            },
            getTimezone: {
                url: '/timezone',
                version: '/v1'
            },
            addCustomer: {
                url: '/customer',
                version: '/v1'
            },
            customer: {
                url: '/customer',
                version: '/v1'
            },
            preference: {
                url: '/customer/preference',
                version: '/v1'
            },
            detailInfo: {
                url: '/customer/detailed-info',
                version: '/v1'
            },
            note: {
                url: '/customer/note',
                version: '/v1'
            },
            address: {
                url: '/customer/address',
                version: '/v1'
            },
            product: {
                url: '/customer/product',
                version: '/v1'
            },
            detailedInfo: {
                url: '/customer/detailed-info',
                version: '/v1'
            },
            listCommodityCategory: {
                url: '/master/commodity-category',
                version: '/v1'
            },
            listEngagementType: {
                url: '/master/engagement-type',
                version: '/v1'
            },
            listIndustryCategory: {
                url: '/master/industry-category',
                version: '/v1'
            },
            listIndustry: {
                url: '/master/industry',
                version: '/v1'
            },
            listPackageType: {
                url: '/master/package-type',
                version: '/v1'
            },
            listResolution: {
                url: '/master/resolution',
                version: '/v1'
            },
            listShippingProviderIssue: {
                url: '/master/shipping-provider-issue',
                version: '/v1'
            },
            listTaskAction: {
                url: '/master/task-action',
                version: '/v1'
            },
            storeCredit: {
                url: `/customer/store-credit`,
                version: '/v2'
            },
            storeCreditLog: {
                url: '/customer/store-credit-logs',
                version: '/v2'
            },
        },
        zipCode: {
            list: {
                url: '/zipcode/list',
                version: '/v1'
            }
        },
        doc:{
            'o-doc':{
                url: '/o-doc/swagger.json',
                version: '/v1'
            }
        },
        reward: {
            settings: {
                url: '/reward/settings',
                version: '/v1'
            },
            points: {
                url: '/reward/points',
                version: '/v1'
            },
            rewardLogs: {
                url: '/reward/activity-log',
                version: '/v1'
            },
            customerRewardsPointsLogs: {
                url: '/reward/logs',
                version: '/v2'
            }
        },
        emailTemplates: {
            saveEmailTemplate: {
                url: '/email-templates',
                version: '/v2'
            },
            updateEmailTemplate: {
                url: '/email-templates',
                version: '/v2'
            },
            deleteEmailTemplate: {
                url: '/email-templates',
                version: '/v2'
            },
            deleteEmailTemplateAttachment: {
                url: '/email-templates/attachment',
                version: '/v2'
            },
            getEmailTemplatesList: {
                url: '/email-templates',
                version: '/v2'
            }
        },
        configuration: {
            saveRegistrationReportSettings: {
                url: '/registration-report-settings',
                version: '/v2'
            },
            getRegistrationReportSettings: {
                url: '/registration-report-settings',
                version: '/v2'
            }
        }
    }
}

function getApiUrl(key, name) {
    let url
    if (apiUrls.baseUrl != null) {
        if (apiUrls.live[key] && apiUrls.live[key][name]) {
            url = apiUrls.baseUrl + apiUrls.live[key][name]['version'] + apiUrls.live[key][name]['url']
        } else {
            if (apiUrls.local[key] && apiUrls.local[key][name]) {
                url = apiUrls.localBaseUrl + apiUrls.local[key][name]['version'] + apiUrls.local[key][name]['url']
            }
        }
    } else {
        if (apiUrls.local[key] && apiUrls.local[key][name]) {
            url = apiUrls.localBaseUrl + apiUrls.local[key][name]['version'] + apiUrls.local[key][name]['url']
        }
    }
    return url;
}

export default getApiUrl
