// @flow
import React from 'react';
import { connect } from 'react-redux';

import AdminDashboard from '../components/pages/AdminDashboard';


export class HomePageContainer extends React.Component {

    render() {
        return <AdminDashboard  {...this.props} />;
    }
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect((state) => ({

}), mapDispatchToProps)(HomePageContainer);
