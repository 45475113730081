import React from "react";
import { connect } from "react-redux";
import loginService from '../../../services/api/loginService'
import * as type from '../../../store/login/actionTypes';
import ForgotPasswordForm from '../../forms/forgotPasswordForm'
import Footer from '../../atoms/Footer'
import Loader from '../../atoms/Loader'
import loginLogoImage from '../../../assets/images/login-logo.png'
import mailImage from '../../../assets/images/mail-sent.png';
const Link = require('react-router-dom').Link;



class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: null
    };
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }

  componentWillMount() {
    this.props.setEmailSent(false)
  }

  handleForgotPassword(values) {
    this.setState({ loading: true, email: values.email })
    this.props.forgotPassword({ emailId: values.email }, this.props.currentLanguage, this.props.dictionaries)
  }

  componentWillUnmount() {
    this.props.setEmailSent(false)
  }

  render() {
    return (
      <div className="forgotPasswordPage">
        <div className="loginwrapper">
          <div className="innWrapper">
            <div className="loginLogo">
              <a href="javascript:void()"><img src={loginLogoImage} alt="FreightCenter" title="FreightCenter" /></a>
            </div>
            {
              this.props.emailSent && <div className="resetHeader">
                <h1>Mail Sent Successfully!</h1>
                <p>A link to reset your password has been sent to {this.state.email}</p>
                <img src={mailImage} alt="FreightCenter" title="FreightCenter" />
              </div>
            }
            {
              !this.props.emailSent && <div className="fogotHeader">
                <h1>Forgot your password?</h1>
                <p>Please enter your email address</p>
                <ForgotPasswordForm onSubmit={this.handleForgotPassword} />
              </div>
            }
            <div className="form-group txtCenter row backLogin shortdes">
              <br />
              <p>Return to  <Link to="/" >Login Page</Link></p>
            </div>
          </div>
        </div>
        <Footer />
        {this.props.loading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentLanguage: state.i18nReducer.currentLanguage,
  dictionaries: state.i18nReducer.dictionaries,
  emailSent: state.login.emailSent,
  loading: state.common.loading
});

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: (inputDTO, currentLanguage, dictionaries) => {
      loginService.forgotPassword(dispatch, currentLanguage, dictionaries, inputDTO)
    },
    setEmailSent: (flag) => {
      dispatch({ type: type.EMAIL_SENT, flag })
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

