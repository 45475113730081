import React from "react";
import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';
import { connect } from "react-redux";
import { Button, InputAdornment, } from '@material-ui/core';
import Loader from '../../atoms/Loader'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomerContactList from '../CustomerContactList';
import customerService from '../../../services/api/customerService';

class CustomerAllContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortColumn: 'addedDate',
            sortOrder: 'desc'
        };


    }
    componentWillMount() {
        this.props.getContactLogs({ sortColumn: 'addedDate', sortOrder: 'desc', customerUuid: this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1] }, this.props.currentLanguage, this.props.dictionaries)
    }
    handleSort = (column) => {
        if (this.state.sortColumn == column) {
            if (this.state.sortOrder == 'desc') {
                this.setState({ sortOrder: 'asc' })
                this.props.getContactLogs({ customerUuid: this.props.customerUuid, sortColumn: column, sortOrder: 'asc' })
            } else {
                this.setState({ sortOrder: 'desc' })
                this.props.getContactLogs({ customerUuid: this.props.customerUuid, sortColumn: column, sortOrder: 'desc' })
            }
        } else {
            this.setState({ sortColumn: column, sortOrder: 'desc' })
            this.props.getContactLogs({ customerUuid: this.props.customerUuid, sortColumn: column, sortOrder: 'desc' })
        }
    }
    render() {
        return (
            <div className="main-wraper mt30">
                <div className="main-content">

                    <div className="page-title"><h2>Contact logs</h2></div>
                    <div className="white-section search-cutomer">
                        <fieldset className={"tableFieldset p10 bdr0"}>



                            <div className="searchbaar open">

                                <CustomerContactList handleSort={this.handleSort} showAll={true} />
                            </div>
                            {this.props.loading && <Loader />}
                        </fieldset>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    totalCount: state.customer.totalcount,
    loading: state.common.loading,
    contactLogs: state.customer.contactLogs
});

const mapDispatchToProps = dispatch => ({
    getContactLogs: (inputDTO, currentLanguage, dictionaries) => {
        customerService.getContactLogs(dispatch, currentLanguage, dictionaries, inputDTO)
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAllContacts);