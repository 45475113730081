import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInputField from '../atoms/CustomInputField'
import CustomSelectBox from '../atoms/CustomSelectBox'
import DatePicker from '../atoms/DatePicker'
import utilCommon from '../../helpers/utilCommon'
import { resetPassword } from "../../services/api/customerService";

const validate = (formProps) => {

    const errors = {};

    if (formProps.email && !utilCommon.Email_Regex.test(formProps.email)) {
        errors.email = 'Please enter a valid email address.';
    }

    if (formProps && formProps.fromDate && formProps.toDate && (new Date(formProps.fromDate) > new Date(formProps.toDate))) {
        errors.fromDate = 'Date registered from cannot be after date registered to.'
        errors.toDate = 'Date registered to cannot be before date registered from.'
    }

    if (formProps.phoneNumber && formProps.phoneNumber.length  != 10) {
        errors.formattedPhoneNumber = 'Please enter a valid phone number.'
    }

    return errors;
}

export class CustomerAdvancedSearchForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    setPhone = (e, field) => {
        this.props.change(field, e.target.value.replace(/[^\d]/g, ''));
    }

    render() {
        const { handleSubmit, submitting, valid, open, handleClose, formProps, initialValues, reset } = this.props;
        return (
            <Dialog open={open} onClose={handleClose} aria-labelledby="scroll-dialog-title" maxWidth={'md'} className="DialogBox large" >
                <form name="CustomerAdvancedSearchForm" onSubmit={valid ? handleSubmit : handleSubmit(validate)} className="mdlScroll">
                <DialogTitle className="DialogTitle">Search customer <i className="zmdi zmdi-close" onClick={() => this.props.handleClose(0,true)}></i></DialogTitle>
                    <DialogContent className="DialogBoxcontent">
                        <DialogContentText>
                            <fieldset className="row-margin-b20">
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Customer number" autoFocus  maxLength={36} name="customerNumber" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field label="Company" name="companyName" component={CustomInputField} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="First name" name="firstName" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field label="Last name" name="lastName" component={CustomInputField} />
                                    </div>
                                </div>
                                <div className="col-grid2">
                                    <div className="col">
                                        <Field label="Email address" type="email" name="email" component={CustomInputField} />
                                    </div>
                                    <div className="col">
                                        <Field
                                            label="Phone number"
                                            type="tel"
                                            name="formattedPhoneNumber"
                                            onChange={(e) => this.setPhone(e, 'phoneNumber')}
                                            component={CustomInputField}
                                        />
                                    </div>
                                </div>
                                { utilCommon.isPermitted(2, 84, 1) &&
                                    <>
                                        <div className="col-grid2">
                                            <div className="col">
                                                <Field label="Zip code" maxLength={7} name="postalCode" component={CustomInputField} />
                                            </div>
                                            <div className="col">
                                                <Field label="State" name="customerStateId" options={this.props.states} component={CustomSelectBox} />
                                            </div>
                                        </div>
                                        <div className="col-grid2">
                                            <div className="col">
                                                <Field label="Date registered from" name="fromDate" component={DatePicker} />
                                            </div>
                                            <div className="col">
                                                <Field label="Date registered to" name="toDate" component={DatePicker} />
                                            </div>
                                        </div>
                                    </>
                                }
                            </fieldset>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="DialogBoxfooter">
                        <Button onClick={() => { handleClose(), reset() }} className="btnCancel">Reset</Button>
                        <Button type="submit" disabled={submitting || !valid} className={(submitting || !valid) ? "btnInactive" : "btnPrimary"}>Search</Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

export default connect(state => {
    return {
        initialValues: {},
        states: utilCommon.nullCheck(state.common.masterData) ? state.common.masterData.stateList : [],
        formProps: state.form.CustomerAdvancedSearchForm ? state.form.CustomerAdvancedSearchForm.values : {}
    }
}, {})(reduxForm({
    form: 'CustomerAdvancedSearchForm',
    validate, enableReinitialize: true
})(CustomerAdvancedSearchForm));
