// @flow
import * as type from './actionTypes';

const initialState = {
  email: null,
  accessToken: null,
  refreshToken: null,
  emailSent: false,
  accountView: false,
  roles:[],
  showRole:false,
  // Health Check
  messageStatus: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN:
      return {
        ...state,
        login: [],
      };


    case type.EMAIL_SENT:
      return {
        ...state,
        emailSent: action.flag
      };

    case type.SET_ACCOUNT_VIEW:
      return {
        ...state,
        accountView: {
          firstName: sessionStorage.getItem('fname'),
          lastName: sessionStorage.getItem('lname')
        }
      };

    case type.SET_CREDENTIALS:
      return {
        ...state,
        accessToken: action.token,
        email: action.email,
        refreshToken: action.refreshToken
      }
    case type.SET_ROLES:
      return {
        ...state,
        roles:action.roles,
        showRole:action.showRole
      }

    case type.SET_HEALTH_CHECK:
      return {
        ...state,
        messageStatus: action.messageStatus
      }

    default:
      return state;
  }
};

export default reducer;
