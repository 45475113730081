import * as actions from './actionTypes'
import {SET_REWARD_LOGS} from "./actionTypes";

const initialState = {
    rewardSettings: {},
    rewardLogs: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actions.SET_REWARD_SETTINGS:
            return {
                ...state,
                rewardSettings: action.rewardSettings
            }

        case actions.SET_REWARD_LOGS:
            return {
                ...state,
                rewardLogs: action.rewardLogs
            }

        default:
            return state;
    }
}

export default reducer;