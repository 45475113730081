import getApiUrl from "@helpers/apiUrls";
import axios from "axios";
import {setCustomerStoreCreditBalance, setStoreCreditLogs} from "@store/customer/actions";
import * as commonActions from "@store/common/actions";
import utilCommon from "@helpers/utilCommon";
import authHeader from "@helpers/authHeader";
import * as commonAction from "@store/common/actions";

export const getCustomerStoreCreditBalance = (dispatch, customerId) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('customer', 'storeCredit') + `/${customerId}`;

    axios.get(url, params)
        .then(res => {
            const data = res.data;
            const isSuccess = data.success;

            dispatch(setCustomerStoreCreditBalance(isSuccess ? data.data.balance : null));
        })
        .catch(err => dispatch(setCustomerStoreCreditBalance(null)));
};

export const updateStoreCredit = (dispatch, currentLanguage, dictionaries, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('customer', 'storeCredit');

    dispatch(commonActions.setLoader(true));

    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Store credit successfully added', dictionaries, currentLanguage);
                getCustomerStoreCreditBalance(dispatch, inputDTO.customerId)
            } else {
                utilCommon.showAgentNotification('Something went wrong during add store credit');
            }
        }).catch(err => dispatch(commonActions.setLoader(false)));
}

export const getStoreCreditLogList = (dispatch, lang, dictionaries, customerId) => {
    const params = {headers: authHeader()};

    let url = getApiUrl('customer', 'storeCreditLog') + ('?customerId=' + customerId + "&sortColumn=created_at&sortOrder=desc");

    dispatch(commonAction.setLoader(true));

    axios.get(url, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false));

            if (response.data.success) {
                dispatch(setStoreCreditLogs(response.data.data))
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionaries, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonAction.setLoader(false))
        });
}

export default {
    updateStoreCredit,
    getCustomerStoreCreditBalance,
    getStoreCreditLogList
}
