import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import CustomInputField from '../atoms/CustomInputField'
import CustomSelectBox from '../atoms/CustomSelectBox'
import utilCommon from '../../helpers/utilCommon'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import * as type from '../../store/login/actionTypes'
const Link = require('react-router-dom').Link;

const validate = (formProps) => {
    const errors: any = {};
    if (!formProps.password) {
        errors.password = 'Required';
    }

    if (!formProps.email) {
        errors.email = 'Required';
    } // proxy login or normal login
    else if (!formProps.email.includes(' ') && !utilCommon.Email_Regex.test(formProps.email)) {
        errors.email = 'Please enter a valid email address.';
    }
    else if(formProps.email.includes(' ') && formProps.email.indexOf(' ') !=-1) {
        let emails = formProps.email.split(' ')
        if(emails && emails.length!=3){
            errors.email = 'Please enter a valid email address.';
        }
        else if(emails && emails.length==3 && (emails[1]!='as' || !utilCommon.Email_Regex.test(emails[2]) || !utilCommon.Email_Regex.test(emails[0]))){
            errors.email = 'Please enter a valid email address(es).';
        }
    }
    if(!formProps.setRoleId){
        errors.setRoleId = 'Please select a role'
    }

    return errors;
}

export class LoginForm extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
          showPassword: false,
        }
    }
    componentWillUnmount(){
        this.props.updateShowRoles()
    }

    removeRoles(){
        this.props.updateShowRoles()
    }

    handleClickShowPassword = () => {
      this.setState({showPassword: !this.state.showPassword});
    }

    handleMouseDownPassword = event => {
      event.preventDefault();
    }

    render() {
        const { handleSubmit, submitting, valid, pristine, callAPI } = this.props;
        return (
            <form name="LoginForm" onSubmit={handleSubmit}>
                <div className="form-group text-center row">
                    <Field label="Email Address" autoFocus type='email' name="email" onChange={()=>this.removeRoles()} component={CustomInputField} />
                </div>
                <div className="form-group text-center row lastrow">
                    <Field label="Password" type={this.state.showPassword ? 'text' : 'password'} name="password" showPassword={this.state.showPassword} handleClickShowPassword={this.handleClickShowPassword.bind(this)} handleMouseDownPassword={this.handleMouseDownPassword.bind(this)} component={CustomInputField} />
                </div>
                {this.props.showRoles && <div className="form-group text-center row lastrow">
                    <Field label="Role" options={this.props.roles} name="setRoleId" component={CustomSelectBox} />
                </div>}
                <div className="form-group text-center row">
                    <div className="col-left floatLeft">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.rememberMe}
                                />
                            }
                            label="Remember me"
                            onChange={this.props.handleRememberMe}
                            className="checkBoxWrap"
                        />
                    </div>
                    <div className="col-right floatRight">
                        <Link className="forgot-psw" to="/forgotpassword">Forgot Password?</Link>
                    </div>
                </div>
                <div className="formAction txtCenter btnWrap">
                    <Button type="submit" variant="contained" disabled={submitting || this.props.loading} className={(submitting || pristine || this.props.loading) ? "btnInactive" : "btnPrimary"} className="btnPrimary">
                        Login
                    </Button>
                </div>
            </form>
        )
    }
}


const getValues = () => {
    if (utilCommon.getCookie('rememberme') && utilCommon.getCookie('username') && utilCommon.getCookie('username') != undefined && utilCommon.getCookie('username') != 'undefined' && utilCommon.getCookie('password') && utilCommon.getCookie('password') != undefined && utilCommon.getCookie('password') != 'undefined') {
        return { email: utilCommon.getCookie('username'), password: utilCommon.getCookie('password') }
    } else {
        return {}
    }
}


// get mapped roles
const setRoles = (roles)=>{
    let opt = [];
    if(roles && Array.isArray(roles)){
        opt = roles.map((x,index)=>{
            return {id:x.roleId,name:x.roleName}
        })
    }
    return opt;
}


const mapDispatchToProps = (dispatch)=>({
    updateShowRoles :()=>{
        dispatch({type:type.SET_ROLES,roles:[],showRole:false})
    }
})
export default connect((state) => ({
    initialValues: getValues(),
    loading: state.common.loading,
    showRoles:state.login.showRole,
    roles:setRoles(state.login.roles)
}), mapDispatchToProps)(reduxForm({
    form: 'LoginForm',
    validate,
})(LoginForm));
