import React from "react";
import { connect } from "react-redux";

// import { Button, Grow, Paper, Modal, ClickAwayListener, MenuItem, MenuList, Radio, RadioGroup, FormControl, FormControlLabel, Select, InputLabel, IconButton, ListItemSecondaryAction, ListItem, List } from '@material-ui/core';

import customerService from '../../../services/api/customerService';

import moment from 'moment';
import Pagination from '../../molecules/Pagination'
import Loader from '../../atoms/Loader';
import sortImage from '../../../assets/images/sort.svg'

const now = moment().format().substring(0, 10);


class CustomerContactList extends React.Component<any,any> {
    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            open: false,
            accountMenuOpen: false,
            hireDate: now,
            pageSize: 10,
            pageNumber: 1,
            sortColumn: 'addedDate',
            sortOrder: 'desc',
            contactlogTableIndex:-1
        };


    }

    componentWillReceiveProps(nextProps,props){
        if(this.state.pageNumber!=nextProps.pageNumber || this.state.pageSize!=nextProps.pageSize ||this.state.sortOrder!=nextProps.sortOrder || this.state.sortColumn!=nextProps.sortColumn ){
            this.setState({pageNumber:nextProps.pageNumber,pageSize:nextProps.pageSize ,sortColumn:nextProps.sortColumn,sortOrder:nextProps.sortOrder})
        }
    }


    setPaging = (e) => {
        if (e.target) {
            this.setState({ pageNumber: 1, pageSize: e.target.value })
            this.props.getContactLogs({ customerUuid:this.props.customerUuid,pageSize: e.target.value, pageNumber: 1, sortColumn: this.state.sortColumn, sortOrder: this.state.sortOrder })
        } else {
            if (e != this.state.pageNumber) {
                this.setState({ pageNumber: e })
                this.props.getContactLogs({ customerUuid:this.props.customerUuid,pageSize: this.state.pageSize, pageNumber: e, sortColumn: this.state.sortColumn, sortOrder: this.state.sortOrder })
            }
        }
    }


    getPages = () => {
        let pages = (this.props.totalCount / this.state.pageSize)
        let mod = pages % 1
        if (mod > 0) {
            pages = parseInt(pages) + 1
        }
        let arr = []
        for (let i = 0; i < pages; i++) {
            arr.push({})
        }
        return arr
    }

    handleSort = (column) => {
        if (this.state.sortColumn == column) {
            if (this.state.sortOrder == 'desc') {
                this.setState({ sortOrder: 'asc' })
                this.props.getContactLogs({ customerUuid:this.props.customerUuid,pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'asc' })
            } else {
                this.setState({ sortOrder: 'desc' })
                this.props.getContactLogs( { customerUuid:this.props.customerUuid,pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'desc' })
            }
        } else {
            this.setState({ sortColumn: column, sortOrder: 'desc' })
            this.props.getContactLogs( { customerUuid:this.props.customerUuid,pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'desc' })
        }
    }
    contactlogTableDataCollapse = (index) =>{
        if(this.state.contactlogTableIndex == index){
            this.setState({
                contactlogTableIndex:-1
            })
        } else {
            this.setState({
                contactlogTableIndex:index
            })
        }
    }

    render() {
        let pages = this.getPages()
        return (
            <div className={"CustomerContractList"} >
                    <table cellPadding="0" cellSpacing="0" border="0" className="tbl-data responsiveTbl">
                        <thead>
                            <tr>
                                <td width="120" data-head="Logged date" className="resCol40"><div onClick={() => { !this.props.showAll ? this.handleSort('addedDate') :{}}} className="sort">Logged date{!this.props.showAll &&<img src={sortImage} className="sortImg" />}</div></td>
                                <td  width="140" data-head="Shipment Id" className="txtLeft resCol60"><div onClick={() => { !this.props.showAll ? this.handleSort('shipmentUuid') :{}}} className="sort">Shipment Id{!this.props.showAll &&<img src={sortImage} className="sortImg" />}</div></td>
                                <td className="txtLeft" data-head="Resolution" className="resColHide"><div onClick={() => { !this.props.showAll ? this.handleSort('resolutionId') :{} }} className="sort">Resolution{!this.props.showAll && <i className="zmdi zmdi-sort-asc"></i>}</div></td>
                                <td className="txtLeft" data-head="Description" className="resColHide"><div onClick={() => { !this.props.showAll ? this.handleSort('contactDescription') :{}}} className="sort">Description {!this.props.showAll && <i className="zmdi zmdi-sort-asc"></i>}</div></td>
                                <td width="200" className="txtLeft" data-head="Agent" className="resColHide"><div onClick={() => { !this.props.showAll ? this.handleSort('userUuid'): {}}} className="sort">Agent{!this.props.showAll && <i className="zmdi zmdi-sort-asc"></i>}</div></td>
                            </tr>
                        </thead>
                        {this.props[this.props.showAll ? 'allContactLogs':'contactLogs'].map((contact,index) => (
                        <tbody className={!this.props.listView && this.props.currentUser &&  this.props.currentUser.userId  && this.props.currentUser.userId == contact.shipmentId  ? 'active' : ''}
                        key={contact.shipmentId + index.toString()}>
                            <tr key={contact.shipmentId}>
                                <td className="lh16 resCol40">{contact.addedDate ? moment(contact.addedDate).format('MM/DD/YYYY'):''}<br/><span className="txtGray7E">{contact.addedDate ? moment(contact.addedDate).format('hh:mm:ss A'):''}</span></td>
                                <td className="txtLeft textNowrap resCol60 relativeRes">{contact.shipmentUuid ? contact.shipmentUuid : ''} <div className="collapsible responsive" onClick={()=>{this.contactlogTableDataCollapse(index)}}><i className={this.state.contactlogTableIndex == index ? "zmdi zmdi-minus" : "zmdi zmdi-plus"}></i></div></td>
                                <td data-head="Resolution" className={this.state.contactlogTableIndex  == index ? "txtLeft dataHead":"txtLeft resColcollapse"}>{contact.resolutionId ? contact.resolutionId.name :''}</td>
                                <td data-head="Description" className={this.state.contactlogTableIndex  == index ? "txtLeft dataHead" : "txtLeft resColcollapse"}>{contact.contactDescription}</td>
                                <td  data-head="Agent" className={this.state.contactlogTableIndex  == index ? "txtLeft dataHead" : "txtLeft resColcollapse"}>{contact.addedBy ? (contact.addedBy.id <= 1 ? 'System' : contact.addedBy.name) : ''}</td>
                            </tr>
                        </tbody>

                            ))}
                            {!this.props.showAll && this.props.contactLogs.length==0 && <tbody className={''}
                        >
                            <tr >
                                <td colSpan="5" style={{textAlign:'center'}}>No Records Found</td>
                            </tr>
                        </tbody>}
                    </table>
                    {true ? '':<div className="paginations-set">
                        <div className="floatLeft">
                            {
                                this.props.contactLogs.length > 0 && <Pagination pageSize={this.state.pageSize} pageNumber={this.state.pageNumber} pagingOption={[10, 20, 30, 50, 100]} pages={pages} setPaging={this.setPaging} />
                            }
                        </div>
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    totalCount: state.customer.contactLogCount,
    allContactLogs:state.customer.allContactLogs,
    contactLogs:state.customer.contactLogs,
    pageNumber:state.customer.logPageNumber,
    pageSize:state.customer.logPageSize,
    sortColumn:state.customer.logSortColumn,
    sortOrder:state.customer.logSortOrder,
    // contactLogCount:state.customer.contactLogCount
});

const mapDispatchToProps = dispatch => ({
    getContactLogs :(inputDTO,currentLanguage,dictionaries)=>{
        customerService.getContactLogs(dispatch,currentLanguage,dictionaries,inputDTO)
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContactList);
