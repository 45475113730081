// @flow

// /file/{fileId}
export const SET_TRANSITTIMES = 'applicationSettings/SET_TRANSITTIMES';
export const SET_CURRENT_TRANSITTIME = 'applicationSettings/SET_CURRENT_TRANSITTIME';
export const SET_FUELSURCHARGES = 'applicationSettings/SET_FUELSURCHARGES';
export const SET_CURRENT_FUELSURCHARGE = 'applicationSettings/SET_CURRENT_FUELSURCHARGE';
export const SET_FACILITYTYPES = 'applicationSettings/SET_FACILITYTYPES';
export const SET_CURRENT_FACILITYTYPE = 'applicationSettings/SET_CURRENT_FACILITYTYPE';
export const SET_CURRENT_ACCESSORIAL = 'applicationSettings/SET_CURRENT_ACCESSORIAL';
export const SET_FUELCOSTINDEXES = 'applicationSettings/SET_FUELCOSTINDEXES';
export const SET_CURRENT_FUELCOSTINDEX = 'applicationSettings/SET_CURRENT_FUELCOSTINDEX';
export const SET_GLOBAL_DISCOUNT = 'applicationSettings/SET_GLOBAL_DISCOUNT';
export const SET_ACCESSORIALS = 'applicationSettings/SET_ACCESSORIALS';
export const SET_FACILITY_TYPE_ACCESSORIALS = 'applicationSettings/SET_FACILITY_TYPE_ACCESSORIALS';
export const SET_MANAGE_COUPON = 'applicationSettings/SET_MANAGE_COUPON';
export const SET_CURRENT_COUPON = 'applicationSettings/SET_CURRENT_COUPON';
export const SET_SYSTEM_WIDE_NOTIFICATION = 'applicationSettings/SET_SYSTEM_WIDE_NOTIFICATION';
export const SET_CURRENT_SYSTEM_WIDE_NOTIFICATION = 'applicationSettings/SET_CURRENT_SYSTEM_WIDE_NOTIFICATION';
export const SET_TEAMS = 'applicationSettings/SET_TEAMS';
export const SET_CURRENT_TEAMS = 'applicationSettings/SET_CURRENT_TEAMS';
export const SET_TEAM_USER_COUNT = 'applicationSettings/SET_TEAM_USER_COUNT';
export const SET_SUPERVISIORS = 'applicationSettings/SET_SUPERVISIORS';
export const SET_FALVEY_TERMS_CONDITIONS = 'applicationSettings/SET_FALVEY_TERMS_CONDITIONS';
export const SET_CURRENT_FALVEY_TERMS_CONDITIONS = 'applicationSettings/SET_CURRENT_FALVEY_TERMS_CONDITIONS';
export const SET_TMS_TERMS_CONDITIONS = 'applicationSettings/SET_TMS_TERMS_CONDITIONS';
export const SET_CURRENT_TMS_TERMS_CONDITIONS = 'applicationSettings/SET_CURRENT_TMS_TERMS_CONDITIONS';
export const SET_FINANCIAL_SETTINGS = 'applicationSettings/SET_FINANCIAL_SETTINGS';
export const SET_APPROVED_IP = 'applicationSettings/SET_APPROVED_IP';
export const SET_CURRENT_APPROVED_IP = 'applicationSettings/SET_CURRENT_APPROVED_IP';
export const SET_PAGING = 'applicationSettings/SET_PAGING';
export const SET_CURRENT_PAGE = 'applicationSettings/SET_CURRENT_PAGE';
export const SET_MODE = 'applicationSettings/SET_MODE';
export const SET_PAGING_SORTING = 'applicationSettings/SET_PAGING_SORTING';
export const SET_EMAIL_TEMPLATES = 'applicationSettings/SET_EMAIL_TEMPLATES'
export const SET_CURRENT_EMAIL_TEMPLATE = 'applicationSettings/SET_CURRENT_EMAIL_TEMPLATES'
export const SET_POP_UP_CONTENT_LIST = 'applicationSettings/SET_POP_UP_CONTENT_LIST'
export const SET_REGISTRATION_REPORT_SETTINGS = 'applicationSettings/SET_REGISTRATION_REPORT_SETTINGS'
export const SET_CARRIER_MARGIN_ADJUSTMENTS = 'applicationSettings/SET_CARRIER_MARGIN_ADJUSTMENTS'
export const SET_CARRIER_MARGIN_ADJUSTMENT_LOGS = 'applicationSettings/SET_CARRIER_MARGIN_ADJUSTMENT_LOGS'
