import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import CustomRadioButton from '../atoms/CustomRadioButton'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

const validate = (formProps) => {
    const errors: any = {};
    let requiredFields = []
    requiredFields.map((x) => {
        if (!formProps[x]) {
            errors[x] = 'Required';
        }
    })

    return errors;
}

export class UserBulEditForm extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { handleSubmit, submitting, pristine, valid, checked, customerUpdate } = this.props;
        return (
            <form name="UserBulEditForm" className="gridsBase addUserForm" onSubmit={handleSubmit}>
                <div className="radioGroupWrap rowWrap">
                    <h5>Status </h5>
                    <Field autoFocus options={ customerUpdate ? [{ text: 'Activate', value: '1' }, { text: 'Suspend', value: '0' }] : [{ text: 'Active', value: '1' }, { text: 'Inactive', value: '0' }]} requiredClass name="status" component={CustomRadioButton} />
                </div>
                {
                    !customerUpdate && <div className="radioGroupWrap rowWrap">
                        <h5>Restrict By IP </h5>
                        <Field options={[{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }]} requiredClass name="restrictedByIp" component={CustomRadioButton} />
                    </div>
                }
                <div className="dropDownbtn txtCenter">
                    <Button onClick={this.props.closeModal} className="btnCancel">
                        Cancel
                    </Button>
                    <Button type="submit" disabled={(submitting || pristine || checked.length == 0)} className={(submitting || pristine || checked.length == 0) ? "btnInactive" : "btnPrimary"}>
                        Save
                    </Button>
                </div>
            </form>
        )
    }
}

UserBulEditForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect((state) => ({

}), {})(reduxForm({ form: 'UserBulEditForm', validate })(withStyles(styles)(UserBulEditForm)));
