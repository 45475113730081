import React from "react";
import { connect } from "react-redux";
import customerService from "../../../services/api/customerService";
import shipmentService from "../../../services/api/shipmentService";
import Loader from '../../atoms/Loader';
import moment from 'moment';
import CustomerLogList from './../CustomerLogList';
import LogsList from './../LogsList';
import carrierService from '../../../services/api/carrierService';

class CarrierLogs extends React.Component<any,any> {
    constructor(props) {
        super(props);
        this.state = {
            isCustomerPage:false
        };
       
    }

    componentWillMount(){
            this.props.getLogs(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1],this.props.currentLanguage, this.props.dictionaries)
        
    }

    

    render() {
        const { open, accountMenuOpen, checked } = this.state;
        
        return (
                    <div className="main-wraper mt30">
                        <div className="main-content">
                        
                        <div className="page-title"><h2>Carrier logs</h2></div>
                    <div className="white-section search-cutomer">
                    <fieldset className={"tableFieldset p10 bdr0"}>
                        <div className="filter-title-row1 mb10 uppercase pl10"><span className="font13 pl10">Carrier Id# {this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]}</span></div>
                        <LogsList logs={this.props.carrierLogs}/>
                        {this.props.loading && <Loader />}
                    </fieldset>    
                        </div>  
                        </div>
                    </div>        
        );
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    carrierLogs:state.carrier.carrierLogs
});

const mapDispatchToProps = dispatch => ({
    getLogs:(inputDTO,lang,dictionaries)=>{
        carrierService.getCarrierLogs(dispatch,lang,dictionaries,inputDTO)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CarrierLogs);
