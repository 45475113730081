import axios from 'axios';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon from '../../helpers/utilCommon'
import * as actions from '../../store/login/actions'
import * as type from '../../store/login/actionTypes'
import * as commonActions from '../../store/common/actions'
import * as commonActionTypes from '../../store/common/actionTypes'
import {encrypt} from '../../helpers/crypto'

// Permissions interceptor
axios.interceptors.response.use(function (response) {
  if (response && response.data && response.data.message && response.data.message === 1028) {
    let flag = false;
    if(utilCommon.getSession('accessToken')){
      flag = true;
    }
    utilCommon.removeSession('accessToken')
    if (window.location.pathname.length > 5) {
      localStorage.setItem('redirectionUrl', window.location.pathname)
    }
    if(flag){
      login(Window['dispatch'], {}, {}, {
        emailId: utilCommon.getCookie('username'),
        password: utilCommon.getCookie('password')
      },true);
    }
    Window['dispatch'](commonActions.setLoader(true))
  } else if (response && response.data && response.data.message && response.data.message === 401) {
    login(Window['dispatch'], {}, {}, {
      refreshToken: utilCommon.getSession('refreshToken')
    },true);
  } else if (response && response.data && response.data.message && response.data.message === 1030) {
    utilCommon.removeSession('refreshToken')
    utilCommon.removeSession('accessToken')
    utilCommon.clearSession()
    if (window.location.pathname.length > 5) {
      localStorage.setItem('redirectionUrl', window.location.pathname)
    }
    window.location.replace('/')
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});


export const login = (dispatch, lang, dictionary, inputDTO,refreshFlag) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('login', 'userLogin');
  if (utilCommon.getCookie('rememberme')) {
    utilCommon.setCookie('username', inputDTO.emailId)
    // utilCommon.setCookie('password', inputDTO.password)
    utilCommon.setCookie('rememberme', 'true')
  }
  dispatch(commonActions.setLoader(true))
  axios.post(url, inputDTO, params)
    .then((response) => {
      dispatch(commonActions.setLoader(false))
      if (response.data.success) {
        if (response.data.content.responseData.refreshToken) {
          if(!refreshFlag){
            utilCommon.setSession('refreshToken', response.data.content.responseData.refreshToken)
            utilCommon.setSession('dbType', response.data.content.responseData.userDesignation ? encrypt(response.data.content.responseData.userDesignation):'')
          }
          utilCommon.setSession('accessToken', response.data.content.responseData.accessToken)
          utilCommon.setSession('fname', response.data.content.responseData.firstName)
          utilCommon.setSession('lname', response.data.content.responseData.lastName)
          utilCommon.setSession('userId', response.data.content.responseData.userId)
          utilCommon.setSession('userUuid', response.data.content.responseData.userUuid)
          utilCommon.setSession('rolePermission',JSON.stringify(response.data.content.responseData.rolePer ? response.data.content.responseData.rolePer.permission :''))
          utilCommon.setSession('username', response.data.content.responseData.firstName)
          let currentTimezone =  response.data.content.responseData.timezoneName;
          let tzId = response.data.content.responseData.timezoneId
          currentTimezone = (tzId==26 || tzId==27 ) ? '' :(currentTimezone ? (currentTimezone.split('GMT')[1] ? currentTimezone.split('GMT')[1].substring(0,6) :'') : '')
          utilCommon.setSession('timezone', currentTimezone)
          utilCommon.setSession('teamUuid', response.data.content.responseData.teamUuid)
          utilCommon.setSession('timezoneId', response.data.content.responseData.timezoneId)
          dispatch(actions.setCredentials(response.data.content.responseData.accessToken, response.data.content.responseData.refreshToken, response.data.content.responseData.email_id))
          // getMasterData(dispatch)
          if(refreshFlag){
            dispatch({ type: type.SET_ROLES, roles: [], showRole: false })
            window.location.reload()
          }
        } else {
          dispatch({ type: type.SET_ROLES, roles: response.data.content.responseData.userRoles, showRole: true })
          utilCommon.clearSession()
        }
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
        utilCommon.clearSession()
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false))
    });
};

export const forgotPassword = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('login', 'forgotPassword');
  dispatch(commonActions.setLoader(true))
  axios.post(url, inputDTO, params)
    .then((response) => {
      dispatch(commonActions.setLoader(false))
      if (response.data.success) {
        dispatch({ type: type.EMAIL_SENT, flag: true })
        utilCommon.showNotification(response.data.message, dictionary, lang)
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false))
    });
};

export const resetPassword = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: {} };
  const url = getApiUrl('login', 'resetPassword');
  dispatch(commonActions.setLoader(true))
  axios.post(url, inputDTO, params)
    .then((response) => {
      dispatch(commonActions.setLoader(false))
      if (response.data.success) {
        utilCommon.showNotification(response.data.message, dictionary, lang)
        Window['history'].push('/')
      } else {
        response.data.errors.map((error) => {
          utilCommon.showNotification(error, dictionary, lang)
        })
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false))
    });
};


export const updateNotificationFeed = (inputDTO) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('notifications', 'salesAgentNotification');
  axios.put(url, inputDTO, params)
    .then((response) => {
      if (response.data.success) {} else {}
    })
    .catch((err) => {});
}


export const getMasterData = (dispatch) => {
  const params = { headers: authHeader() };
  const url = getApiUrl('login', 'getMasterData');
  dispatch(commonActions.setLoader(true))
  axios.get(url, params)
    .then((response) => {
      dispatch(commonActions.setLoader(false))
      if (response.data.success) {
        dispatch({ type: commonActionTypes.SET_MASTER_DATA, masterData: response.data.content.masterDataList })
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false))
    });



  // States
  // let url2 = getApiUrl('contract', 'getState');
  // axios.get(url2, params)
  //   .then((response) => {
  //     if (response.data.success) {
  //       dispatch({ type: contractType.SET_STATES, states: response.data.content.stateList })
  //     }
  //   })
  //   .catch((err) => {

  //   });
};


//logout
export const logout = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers:  authHeader()  };
  const url = getApiUrl('login', 'logout');
  dispatch(commonActions.setLoader(true))
  axios.post(url, {},params)
    .then((response) => {
      dispatch(commonActions.setLoader(false))
        utilCommon.clearSession()
        Window['history'].push('/')
        window.location.reload()
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false))
      utilCommon.clearSession()
        Window['history'].push('/')
        window.location.reload()
    });
};


// export const healthCheck = (dispatch, lang, dictionary, inputDTO) => {
//   const params = { headers: authHeader() };
//   dispatch(commonActions.setLoader(true))
//   axios.get(url, params)
//       .then((response) => {
//           dispatch(commonActions.setLoader(false))
//           if (response.data.success) {
//               dispatch({ type: type.SET_HEALTH_CHECK, messageStatus: response.data.content })
//           } else {
//               response.data.errors.map((error) => {
//                   utilCommon.showNotification(error, dictionary, lang)
//               })
//           }
//       })
//       .catch((err) => {
//           dispatch(commonActions.setLoader(false))
//   });
// };

export const healthCheck = (dispatch, lang, dictionary, inputDTO) => {
  const params = { headers: authHeader() };
  dispatch(commonActions.setLoader(true))
  dispatch({ type: type.SET_HEALTH_CHECK, messageStatus: 200 })
};


export default {
  login,
  forgotPassword,
  resetPassword,
  getMasterData,
  updateNotificationFeed,
  logout,
  healthCheck,
};
