import * as type from './actionTypes';


export const loginUser = () => ({
  type: type.LOGIN
});

export const setCredentials = (token,refreshToken,email) => ({
  type: type.SET_CREDENTIALS,
  token,
  refreshToken,
  email
});