import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const renderCustomRadioButton = ({
	input, label, options, disabled, meta: { touched, error, warning },customChecked
}) => (
		<div>
			{
				!disabled &&  <RadioGroup
					className="radioGroup"
					value={input.value ? input.value.toString() :input.value}
					onChange={(e) => input.onChange(e)}
				>
					{options.map((option, index) => (
						<FormControlLabel key={index} value={option.value ? option.value.toString() :option.value} control={<Radio />} label={option.text} className={option.value == input.value ? "input.value radio-col checked":"radio-col"} />
					))}
				</RadioGroup>
			}
			{
				disabled && <RadioGroup
					className="radioGroup"
					value={input.value.toString()}
					onChange={(e) => input.onChange(e)}
				>
					<span className="radioViewlabel">{options.filter(x => x.value == input.value) && options.filter(x => x.value == input.value).length > 0 ? options.filter(x => x.value == input.value)[0].text : ''}</span>
				</RadioGroup>
			}
			{!disabled && touched && error && (input.value === "" && (<span className="invalidTxt">
				This is a required field
            </span>
			))}
			{!disabled && error === "Please provide atleast one valid IP address" && (<span className="invalidTxt">
				{error}
			</span>
			)}
		</div>
	);

export default renderCustomRadioButton;
