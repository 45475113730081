import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Select ,{components} from 'react-select';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const customStyles = {
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? '#e6e6e6' : '#ffffff',
        backgroundColor: state.isFocused ? '#e6e6e6' : '#ffffff'
    })
}


const getOptionss = (options) => {
    let opt = []
    options.map(x => {
        opt.push({
            id: x.id,
            name: x.name
        })
    })
    return opt
}


const getValues = (value, options) => {
    return getOptionss(options).filter(x => x.value = value.value)[0]



}

class SelectContainer extends React.Component {
    render() {
        return (<div onKeyUp={this.props.inputChange}>{this.props.children}</div>)
    }
}

const getOptions = (options)=>{
    let optionArr = options.map((x,index)=>{
        return {id:x.id,name:x.name}
    })
    return optionArr
}

const getValue = (value,options)=>{
    if(!value){
        return ''
    }
    if(value&& Array.isArray(value)){
        return value.map((y,index)=>{
            return getOptions(options||[]).find(x=>x.id==y)
        })
    }
    else if(value && typeof(value)=='object'){
        return value.id;
    }
    else{
        return getOptions(options||[]).find(x=>x.id==value)
    }
}

const customFilterOption = (option, rawInput) => {
    const words = rawInput ? rawInput.split(' ') :[];
    return words.reduce(
      (acc, cur) => acc && (option.label||'').toLowerCase().startsWith((cur||'').toLowerCase()),
      true,
    );
};

const renderSelectField = ({ label, isZip,tableSelect,autoFocus, input, isCustomizedText,customizedText,updateCustomerFilter, fc,  multiple, options, readOnly, meta: { touched, error } }) => (
    <div>
        {/* used for rendering auto suggest */}
        {
            !fc && !readOnly && <FormControl className={touched && error ? "formSelectBoxImp formControl form-inputFieldError autosuggest" : "formSelectBoxImp formControl autosuggest"} >
                <InputLabel className={touched && error ? 'invalidTxt' : ''} htmlFor="select-multiple">{label}</InputLabel>
                <SelectContainer>
                    <Select filterOption={customFilterOption}
                        styles={customStyles}
                        value={getValue(input.value,options)}
                        onChange={(e) => input.onChange(e ? e.id : '')}
                        options={getOptions(options)}
                        noOptionsMessage={(e)=>{return (isCustomizedText ? customizedText:'No options')}}
                        isMulti={multiple}
                        
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        autoFocus={autoFocus}
                    
                    />
                </SelectContainer>
            </FormControl>
        }

        {/*  disabled mode */}
        {
           !fc && readOnly && <FormControl className={touched && error ? "formSelectBoxImp zipViewMode formControl form-inputFieldError autosuggest" : "zipViewMode formSelectBoxImp formControl autosuggest"} >
            <InputLabel className={touched && error ? 'invalidTxt' : ''} htmlFor="select-multiple">{label}</InputLabel>
            <SelectContainer>
                <Select
                    styles={customStyles}
                    value={input.value ?(getOptions(options||[])).find(x=>x.id==input.value):''}
                    onChange={(e) => input.onChange(e ? e.id : '')}
                    options={getOptions(options)}
                    isDisabled={true}
                    noOptionsMessage={(e)=>{return isCustomizedText ? customizedText:'No options'}}
                    isMulti={multiple}
                    
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        
                   
            
                />
            </SelectContainer>
        </FormControl>

        }

        


        {!readOnly && touched && !tableSelect && (error && (<span className="invalidTxt">
            {error == ' is a required field' ? label + ' is a required field' : error}
        </span>))}
        {!readOnly && tableSelect && (error && (<span className="invalidTxt">
            {error == ' is a required field' ? label + ' is a required field' : error}
        </span>))}
    </div>
);
export default renderSelectField;
