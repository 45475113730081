import React, {Component} from "react";
import {connect} from "react-redux";
import Loader from '../../atoms/Loader'
import rewardService from "../../../services/api/rewardService";
import RewardLogsList from "../../forms/rewards/logs";

class RewardLogs extends Component {
    componentWillMount() {
        this.props.getRewardLogs(this.props.currentLanguage, this.props.dictionaries);
    }

    render() {
        return (
            <div className="main-wraper mt30">
                <div className="main-content">

                    <div className="page-title"><h2>Rewards logs</h2></div>
                    <div style={{padding: 0}} className="white-section search-cutomer">
                        <fieldset className="tableFieldset p10 bdr0">
                            <RewardLogsList logs={this.props.rewardLogs}/>
                            {this.props.loading && <Loader/>}
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    rewardLogs: state.reward.rewardLogs ? state.reward.rewardLogs : [],
});

const mapDispatchToProps = dispatch => ({
    getRewardLogs: (lang, dictionaries) => {
        rewardService.getRewardLogs(dispatch, lang, dictionaries);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardLogs);
