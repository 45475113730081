// @flow
import * as type from './actionTypes';
import moment from 'moment';

const now = moment(new Date()).add(1, 'days').format().substring(0, 10);


const initialState = {
    billingAdjustmentConsoleData: {},
    paymentData: [],
    paymentDatas: [],
    totalShipmentPaymentAmount: 0,
    masterData: {},
    shipmentMasterData: {},
    isInsuranceValid: false,
    isCouponValid: false,
    transactionDetails: [],
    totalCost: 0,
    totalCharge: 0,
    discountAmount: 0.00,
    insuranceAmount: 0.00,
    shipmentProducts: [],
    insuranceExcludedId: "",
    insuranceExcludedItemName: "",
    transactions: [],
    transactionCount: 0,
    shipmentCharges: {},
    shipmentCharge: 0,
    shipmentCost: 0,
    shipmentPayment: 0,
    currentTransactionDetail: {},
    currentTotalCost: 0,
    currentTotalCharge: 0,
    customerARInfo: {
        arStatus: false,
        arCreditLimit: 0,
        arGraceAmount: 0,
        defaultPaymentMethod: false,
    },
    paymentTypeFlag: '1',
    customerState: {
        "customerType": "2",
        "customerShippingFrequency": "",
        "customerFirstName": "",
        "customerCompany": "",
        "customerDepartment": "",
        "customerLastName": "",
        "customerPosition": "",
        "customerCountry": "",
        "customerState": "",
        "customerCity": "",
        "customerAddress": "",
        "customerApt": "",
        "customerZip": "",
        "customerPhone": "",
        "customerAlternatePhone": "",
        "customerAltExt": "",
        "customerCell": "",
        "customerEmailId": "",
        "customerBillingEmailId": "",
    },
    customerCreditCardsListing: [],
    currentShipmentPayment: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case type.BILLING_ADJUSTMENT_CONSOLE:
            return {
                ...state,
                billingAdjustmentConsoleData: action.billingAdjustmentConsoleData,
            };
        case type.SET_PAYMENTS:
            return {
                ...state,
                paymentData: action.paymentData,
                paymentDatas: action.paymentDatas,
                totalShipmentPaymentAmount: action.totalShipmentPaymentAmount,
                currentShipmentPayment: action.currentShipmentPayment,
            };
        case type.SET_SHIPMENT_MASTER:
            return {
                ...state,
                shipmentMasterData: action.shipmentMasterData,
            };
        case type.SET_MASTER_DATA:
            return {
                ...state,
                masterData: action.masterData
            };
        case type.SET_COUPON_VALIDATE_FLAG:
            return {
                ...state,
                isCouponValid: action.isCouponValid,
                discountAmount: action.discountAmount,
                discountType: action.discountType
            }
        case type.SET_INSURANCE_VALIDATE_FLAG:
            return {
                ...state,
                isInsuranceValid: action.isInsuranceValid,
                insuranceAmount: action.insuranceAmount,

            };
        case type.SET_TRANSACTION_DETAILS:
            return {
                ...state,
                transactionDetails: action.transactionDetails,
                totalCost: action.totalCost,
                totalCharge: action.totalCharge
            };
        case type.SET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.transactions,
                transactionCount: action.transactionCount
            }
        case type.SET_SHIPMENT_PRODUCTS:
            return {
                ...state,
                shipmentProducts: action.shipmentProducts,

            };
        case type.SET_EXCLUDE_ITEM:
            return {
                ...state,
                insuranceExcludedId: action.insuranceExcludedId,
                insuranceExcludedItemName: action.insuranceExcludedItemName
            };
        case type.SET_SHIPMENT_CHARGES:
            return {
                ...state,
                shipmentCharges: action.shipmentCharges,
                shipmentCharge: action.shipmentCharges.shipmentCharge,
                shipmentCost: action.shipmentCharges.shipmentCost,
                shipmentPayment: action.shipmentCharges.shipmentPayment,
            };
        case type.SET_CURRENT_TRANSACTION_DETAILS:
            return {
                ...state,
                currentTransactionDetail: action.currentTransactionDetail,
                currentTotalCost: action.currentTotalCost,
                currentTotalCharge: action.currentTotalCharge,
                isInsuranceValid: action.isInsuranceValid,
            };
        case type.SET_PAYMENT_AR_OPTIONS:
            return {
                ...state,
                customerARInfo: action.customerARInfo,
                paymentTypeFlag: action.flag ? '0' : '1'
            };
        case type.SET_PAYMENT_OPTIONS:
            return {
                ...state,
                customerCreditCardsListing: action.customerCreditCardsListing,
                paymentTypeFlag: action.flag ? '1' : '0'
            };
        case type.SET_CURRENT_CUSTOMER:
            let value = {...action.customerState, ...action.customerState.address, ...action.customerState.preference}
            value.timezone = value.timezoneId;
            return {
                ...state,
                customerState: value
            };
        default:
            return state;
    }
};

export default reducer;
