// @flow
import * as type from './actionTypes';

const initialState = {
  carriers: [],
  totalcount: 0,
  currentCarrier: null,
  loading: false,
  contracts: [],
  carrierLogs: [],
  carrierLogsCount: 0,
  searchKeyword: null,
  pageSize: 50,
	sortColumn: 'carrierName',
	sortOrder: 'asc',
	pageNumber: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_CARRIERS:
      return {
        ...state,
        carriers: action.carriers,
        currentCarrier: action.carriers[0],
        totalcount: action.totalCount,
        loading: false
      };

    case type.SET_CARRIER_LOGS:
			return {
				...state,
				carrierLogs: action.logs,
				carrierLogsCount:action.totalResults
			}

    case type.SET_CONTRACTS:
      return {
        ...state,
        contracts: action.contracts,
        loading: false
      };

    case type.SET_CARRIER:
      return {
        ...state,
        currentCarrier: action.carrier,
        loading: false
      };

    case type.SET_KEYWORD:
      return {
        ...state,
        searchKeyword: action.searchKeyword
      };


    case type.SET_LOADER:
      return {
        ...state,
        loading: action.flag
      };
    case type.SET_CARRIER_PAGINATION:
      return {
        ...state,
        pageNumber: action.pageNumber,

      pageSize: action.pageSize,
      sortColumn: action.sortColumn,
      sortOrder: action.sortOrder,
      }

    default:
      return state;
  }
};

export default reducer;
