// @flow
import * as type from './actionTypes';

const initialState = {
	baQueue:[],
	baQueueURL: null,
	totalCount: 0,
	customers:[],
	carriers:[],
	activeAgents:[],
	inProgressBAQueue:[],
	inProgressTotalCount:0,
	inProgressBAQueueURL: null,
	processedBAQueue:[],
	processedTotalCount:0,
	processedBAQueueURL: null,
	carrierDisputes:[],
	carrierDisputeCount:0,
	carrierDisputeURL: null,
	shipmentsWithoutCarrierCount:0,
	shipmentsWithoutCarrier:[],
	notes:[],
	notesCount:0,
	carrierBillDetails:{},
	reviewDetails:{},
	documents:[],
	documentUpdatedFlag:false,
	loading:false,
	invoiceData:''
	
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case type.SET_BA_QUEUE:
			return {
				...state,
				baQueue: action.baQueue,
				totalCount: action.count,
				baQueueURL: action.baQueueURL
			};
		case type.SET_CUSTOMERS:
			return {
				...state,
				customers:action.customers
			}
		case type.SET_CARRIERS:
			return {
				...state,
				carriers:action.carriers
			}
		case type.SET_ACTIVE_AGENTS:
			return {
				...state,
				activeAgents:action.activeAgents
			}
		case type.SET_INPROGRESS_BA_QUEUE:
			return {
				...state,
				inProgressBAQueue: action.inProgressBAQueue,
				inProgressTotalCount: action.inProgressCount,
				inProgressBAQueueURL: action.inProgressBAQueueURL
			};
		case type.SET_PROCESSED_BA_QUEUE:
			return {
				...state,
				processedBAQueue: action.processedBAQueue,
				processedTotalCount: action.processedCount,
				processedBAQueueURL: action.processedBAQueueURL
			};
		case type.SET_CARRIER_DISPUTES:
			return {
				...state,
				carrierDisputes: action.carrierDisputes,
				carrierDisputeCount: action.carrierDisputeCount,
				carrierDisputeURL: action.carrierDisputeURL
			}
		case type.SET_SHIPMENT_WITHOUT_CARRIER_BILL:
			return {
				...state,
				shipmentsWithoutCarrier:action.shipmentsWithoutCarrier,
				shipmentsWithoutCarrierCount:action.shipmentWithoutCarrierCount
			}
		case type.SET_NOTES:
			return {
				...state,
				notes:action.notes,
				notesCount:action.notesCount
			}
		case type.SET_CARRIER_BILL_DETAILS:
			return {
				...state,
				carrierBillDetails:action.carrierBillDetails
			}
		case type.SET_BA_REVIEW_DETAILS:
			return {
				...state,
				reviewDetails:action.reviewDetails
			}
		case type.SET_BA_DOCUMENTS:
			return {
				...state,
				documents:(action.documents||[]).filter(x=>x.documentCategoryId && x.documentCategoryId.id==1)
			}
		case type.SET_RESET_DOCUMENT_FLAG:
			return {
				...state,
				documentUpdatedFlag:action.documentUpdatedFlag
			}
		case type.SET_LOADER:
			return {...state,
				loading:action.loading
			}
		case type.SET_INVOICE_DATA:
			return {
				...state,
				invoiceData:action.invoiceData
			}
		default:
			return state;
	}

};

export default reducer;
