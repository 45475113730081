// @flow
import * as type from './actionTypes';
import moment from 'moment';
import {DefaultItemInShipmentLTL, DefaultItemInShipmentTL} from '../../helpers/enums';

const now = new Date();
let amount = 1;
switch (now.getDay()) {
  case 5: amount = 3; break;
  case 6: amount = 2; break;
}
const shippingFromPickUpDate = moment(now).add(amount,'days').format().substring(0, 10);


export const initialQuoteState = {
  quoteData: {},
  filterText: '',
  facilityTypes: [],
  quoteStep: 1,
  contracts: [],
  customers: [],
  quoteResults: [],
  quoteQuery: {
    shipmentType: 1,
    ltl:{
      items: [{
        ...DefaultItemInShipmentLTL
      }],
      manualShipment: false,
      shippingFromPickUpDate,
      originFacilityType: '2',
      destinationFacilityType: '2',
    },
    tl:{
      items:[{
        ...DefaultItemInShipmentTL
      }],
      equipmentTypeId: 2,
      truckloadType: 1,
      accessorials:{
        escorts: false,
        strapschains: false,
        hazmat: false,
        tarps: false,
        oversized: false,
        team: false,
        permits: false
      }
    }
  },
  quoteRequestUuid: null,
  shipmentUuid: null,
  selectedCustomer: null,
  originCode: null,
  destinationCode: null
};

const reducer = (state = {...initialQuoteState}, action) => {
  switch (action.type) {
    case type.SET_QUOTE:
      return {
        ...state,
        quoteData: action.quoteData
      };

    case type.SET_FACILITY_TYPES:
      return {
        ...state,
        facilityTypes: action.facilityTypes
      };

    case type.SET_USER_FILTER:
      return {
        ...state,
        filterText: action.filterText
      }

    case type.SET_CUSTOMERS:
      let selectedCustomer = state.selectedCustomer ? action.customers.filter(x => x.customerId == state.selectedCustomer) : null
      return {
        ...state,
        customers: action.customers,
      }

    case type.SET_QUOTE_STEP:
      return {
        ...state,
        quoteStep: action.quoteStep
      }

    case type.SET_CONTRACTS:
      return {
        ...state,
        contracts: action.contracts
      }

    case type.SET_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.selectedCustomer
      }

    case type.SET_QUOTE_RESULTS:
      return {
        ...state,
        quoteResults: action.quoteResults,
        quoteRequestUuid: action.quoteRequestUuid,
        shipmentUuid: action.shipmentUuid
      }

    case type.SET_QUOTE_QUERY:
      return {
        ...state,
        quoteQuery: action.quoteQuery
      }

    case type.SET_ORIGIN_CODE:
      return {
        ...state,
        originCode: action.code
      }

    case type.SET_DESTINATION_CODE:
      return {
        ...state,
        destinationCode: action.code
      }

    default:
      return state;
  }
};

export default reducer;
