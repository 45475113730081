import {SET_QUOTE_RESULTS} from "../quote/actionTypes";

export const SET_ALERTS = 'reportManagement/SET_ALERTS';
export const SET_AGENTS = 'reportMangement/SET_AGENTS';
export const SET_LOADER = 'reportManagement/SET_LOADER';
export const SET_TEAMS = 'reportManagement/SET_TEAMS';
export const SET_SHIPPERS_BY_AREA = 'reportManagement/SET_SHIPPERS_BY_AREA';
export const SET_MASTER_DATA = 'reportManagement/SET_MASTER_DATA'
export const SET_PROCESSING_REVIEW_SHIPMENTS = 'reportManagement/SET_PROCESSING_REVIEW_SHIPMENTS';
export const SET_CUSTOMER_DISCOUNT_REPORT = 'reportManagement/SET_CUSTOMER_DISCOUNT_REPORT';
export const SET_PROCESSING_REVIEW_DATA = 'reportManagement/SET_PROCESSING_REVIEW_DATA';
export const SET_PAGING = 'reportManagement/SET_PAGING';
export const SET_REGISTRATION_REPORT = 'reportManagement/SET_REGISTRATION_REPORT';
export const SET_USERS = 'reportManagement/SET_USERS';
export const SET_DAILY_BOOKING_REPORT = 'reportManagement/SET_DAILY_BOOKING_REPORT'
// export const SET_CUSTOMER_TYPE = 'reportManagement/SET_CUSTOMER_TYPE';
export const SET_CARRIER_REPORT = 'reportManagement/SET_CARRIER_REPORT';
// export const SET_CUSTOMER_TYPE = 'reportManagement/SET_CUSTOMER_TYPE';
export const SET_AR_CUSTOMERS = 'reportManagement/SET_AR_CUSTOMERS';
export const SET_STATISTICAL_REPORT = 'reportManagement/SET_STATISTICAL_REPORT';
export const SET_CARRIERS = 'reportManagement/SET_CARRIERS';
export const SET_SALES_TRACKING = 'reportManagement/SET_SALES_TRACKING';
export const SET_TOP_DOG_REPORT_HEADER_DATA = 'reportManagement/SET_TOP_DOG_REPORT_HEADER_DATA';
export const SET_TOP_DOG_REPORT = 'reportManagement/SET_TOP_DOG_REPORT';
export const SET_PAGING_SORTING = 'reportManagement/SET_PAGING_SORTING';
export const UPDATE_PROCESSQ_PAGING = 'reportManagement/UPDATE_PROCESSQ_PAGING';
export const SET_CUSTOMER_TREND_REPORT = 'reportManagement/SET_CUSTOMER_TREND_REPORT';
export const SET_AGENT_COMPARISON_REPORT = 'reportManagement/SET_AGENT_COMPARISON_REPORT';
export const SET_DAILY_SALES_REPORT = 'reportManagement/SET_DAILY_SALES_REPORT';
export const SET_AGENT_CONVERSION_REPORT = 'reportManagement/SET_AGENT_CONVERSION_REPORT';
export const SET_FC_QUOTE_RESULTS_REPORT = 'reportManagement/SET_FC_QUOTE_RESULTS_REPORT';
export const SET_COVERED_REPORT_DATA = 'reportManagement/SET_COVERED_REPORT_DATA';
export const SET_AGENT_BONUS_REPORT_DATA = 'reportManagement/SET_AGENT_BONUS_REPORT_DATA';
export const SET_AGENT_BONUS_DETAILED_REPORT_DATA = 'reportManagement/SET_AGENT_BONUS_DETAILED_REPORT_DATA';