export const SET_TEAMS = 'dashboardManagement/SET_TEAMS';
export const SET_TRENDING_SALES_AGENTS = 'dashboardManagement/SET_TRENDING_SALES_AGENTS';
export const SET_DASHBOARD_DATES = 'dashboardManagement/SET_DASHBOARD_DATES';
export const SET_TRENDING_CUSTOMER = 'dashboardManagement/SET_TRENDING_CUSTOMER';
export const SET_NOTIFICATIONS = 'dashboardManagement/SET_NOTIFICATIONS';
export const SET_TASKLIST = 'dashboardManagement/SET_TASKLIST';
export const SET_LEADLIST = 'dashboardManagement/SET_LEADLIST';
export const SET_ACTIVE_AGENTS = 'dashboardManagement/SET_ACTIVE_AGENTS';
export const SET_PERFORMANCE_SNAPSHOT = 'dashboardManagement/SET_PERFORMANCE_SNAPSHOT';
export const SET_SHIPMENT_ALERT_STATUS = 'dashboardManagement/SET_SHIPMENT_ALERT_STATUS';
export const SET_REVENUE_BY_TEAM = 'dashboardManagement/SET_REVENUE_BY_TEAM';
export const SET_REVENUE_BY_NEW_EXISTING = 'dashboardManagement/SET_REVENUE_BY_NEW_EXISTING';
export const SET_LEADLISTSALESFLOOR = 'dashboardManagement/SET_LEADLISTSALESFLOOR';
export const SET_PERCENTAGE_TRENDING_AGENTS = 'dashboardManagement/SET_PERCENTAGE_TRENDING_AGENTS';
export const SET_REVENUE_BY_CUSTOMER = 'dashboardManagement/SET_REVENUE_BY_CUSTOMER';
export const SET_GOAL_RANKING = 'dashboardManagement/SET_GOAL_RANKING';
export const SET_CUSTOMERS = 'dashboardManagement/SET_CUSTOMERS';

export const SET_FILTER_AGENT = 'dashboardManagement/SET_FILTER_AGENT';
export const SET_FILTER_TEAM = 'dashboardManagement/SET_FILTER_TEAM';

//Loader
export const SET_TASK_LOADER = 'dashboardManagement/SET_TASK_LOADER';
export const SET_LEAD_LOADER = 'dashboardManagement/SET_LEAD_LOADER';
export const SET_PERFORMANCE_LOADER = 'dashboardManagement/SET_PERFORMANCE_LOADER';
export const SET_TRENDING_CUSTOMER_LOADER = 'dashboardManagement/SET_TRENDING_CUSTOMER_LOADER';
export const SET_SHIPMENT_LOADER = 'dashboardManagement/SET_SHIPMENT_LOADER';
export const SET_NOTIFICATIONS_LOADER = 'dashboardManagement/SET_NOTIFICATIONS_LOADER';
export const SET_DATE_COMPARE_FLAG = 'dashboardManagement/SET_DATE_COMPARE_FLAG'



