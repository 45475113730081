// @flow
import * as type from './actionTypes';
import lodash from 'lodash'

const initialState = {
  loading: false,
  accessorialData: [],
  carriers: [],
  customers: [],
  states: [],
  countries: [],
  currentContract: {},
  liabilityCoverageList: [],
  commodityTypes: [],
  geographicLanes: [],
  currentLCoverage: null,
  includedCommodityTypes: [],
  totalCount: 0,
  exludedFreightClasses: [],
  contractLogs: [],
  contractLogsCount: 0,
  rerdirectToNegotiatedLane: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_ACCESSORIAL_DATA:
      return {
        ...state,
        accessorialData: action.accessorialData,
        loading: false
      };

    case type.SET_CONTRACT_LOGS:
			return {
				...state,
				contractLogs: action.logs,
				contractLogsCount:action.totalResults
			}

    case type.SET_CURRENTLIABILITYCOVERAGE:
      return {
        ...state,
        currentLCoverage: action.currentLiabilityCoverage,
      };

    case type.SET_EXCLUDED_FREIGHT_CLASSES:
      return {
        ...state,
        exludedFreightClasses: action.excludedFreightClasses,
      };



    case type.SET_CURRENTCOMMODITYTYPE:
      return {
        ...state,
        currentCommodityType: action.currentCommodityType,
      };

    case type.SET_CONTRACTINFO:
      return {
        ...state,
        currentContract: action.contract,
        includedCommodityTypes: action.contract && action.contract.contractDensityBaseRating && action.contract.contractDensityBaseRating == '0' ? [] : state.includedCommodityTypes,
        loading: false
      };


    case type.CLEAR_FORM:
      return {
        ...state,
        accessorialData: [],
        currentContract: null,
        liabilityCoverageList: [],
        commodityTypes: [],
        geographicLanes: [],
        includedCommodityTypes: [],
        exludedFreightClasses: []
      };

    case type.SET_GEOGRAPHICLANE:
      return {
        ...state,
        geographicLanes: action.geographicLane,
      };

    case type.SET_COMMODITYTYPE:
      return {
        ...state,
        includedCommodityTypes: lodash.sortBy(action.commodityTypes, 'freightMinDensity')
      }

    case type.SET_LIABILITYCOVERAGE:
      return {
        ...state,
        liabilityCoverageList: action.liabilityCoverageList,
        loading: false
      };


    case type.SET_NEGOTIATEDLANE:
      return {
        ...state,
        negotiatedLanes: action.negotiatedLanes,
        totalCount: action.totalCount
      };

    case type.SET_CARRIERS:
      return {
        ...state,
        carriers: action.carriers,
        loading: false
      };
    case type.SET_CUSTOMERS:
      return {
        ...state,
        customers: action.customers,
        loading: false
      };
    case type.SET_STATES:
      return {
        ...state,
        states: action.states,
      };

    case type.SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
      case type.REDIRECT_NEGOTIATEDLANE:
      return {
        ...state,
        rerdirectToNegotiatedLane: action.rerdirectToNegotiatedLane,
      };
    default:
      return state;
  }
};

export default reducer;
