
import React from "react";
import { connect } from "react-redux";
import loginService from '../../../services/api/loginService';
import utilCommon from '../../../helpers/utilCommon';
import LoginForm from '../../forms/loginForm';
import Footer from '../../atoms/Footer';
import Loader from '../../atoms/Loader';
import loginLogoImage from '../../../assets/images/login-logo.png';
import applicationSettingsService from '../../../services/api/applicationSettingsService';
import ReCAPTCHA from "react-google-recaptcha";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.state = {
      loading: false,
      rememberMe: utilCommon.getCookie('rememberme') || false
    }

    this.recaptchaRef = React.createRef();
  }

  handleLogin = async(values) => {
    let recaptchaValue = '';
    try {
      recaptchaValue = await this.recaptchaRef.current.executeAsync();
    }catch (e){
      return;
    }

    let payload = {
      emailId: values.email,
      password: values.password,
      setRoleId: values.setRoleId,
      recaptcha: recaptchaValue,
    };

    this.setState({ loading: true })
    this.props.login(payload, this.props.currentLanguage, this.props.dictionaries).then(res => {
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
      }
    });
  }

  rememberme = () => {
    let self = this
    if (!self.state.rememberMe) {
      utilCommon.setCookie('rememberme', 'true')
    } else {
      utilCommon.removeCookie('rememberme')
      utilCommon.removeCookie('username')
      utilCommon.removeCookie('password')
    }
    self.setState({ rememberMe: !self.state.rememberMe })
  }

  render() {
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
    return (
      <div>
        <div className="recaptchaWrapper">
          <ReCAPTCHA
              ref={this.recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_KEY}
              badge="bottomleft"
          />
        </div>
        <div className="loginwrapper">
          <div className="innWrapper">
            <div className="loginLogo">
              <a href="javascript:void()"><img src={loginLogoImage} alt="FreightCenter" title="FreightCenter" /></a>
            </div>
            <LoginForm history={this.props.history} handleRememberMe={this.rememberme} rememberMe={this.state.rememberMe} onSubmit={this.handleLogin} />
          </div>
        </div>
        <Footer />
        {this.props.loading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentLanguage: state.i18nReducer.currentLanguage,
  dictionaries: state.i18nReducer.dictionaries,
  loading: state.common.loading
});

const mapDispatchToProps = (dispatch) => ({
  login: (inputDTO, currentLanguage, dictionaries) => {
    loginService.login(dispatch, currentLanguage, dictionaries, inputDTO)
  },
  getNotification: (currentLanguage, dictionaries) => {
    applicationSettingsService.getNotification(dispatch, currentLanguage, dictionaries)
}
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

