// @flow
import * as type from './actionTypes';

const initialState = {
	securityRolesList: [],
	securityRolesCount: 0,
	pageNumber: 1,
	pageSize: 50,
	sortColumn: 'roleName',
	sortOrder: 'asc',
	currentSecurityRoles: null,
	listView:false,
	roleModuleList:[]


};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case type.SET_ROLES:
			return {
				...state,
				securityRolesList: action.securityRolesList,
				securityRolesCount: action.securityRolesCount,
			};
		case type.SET_PAGING:
			return {
				...state,
				pageNumber: action.pageNumber,
				pageSize: action.pageSize,
				sortColumn: action.sortColumn,
				sortOrder: action.sortOrder,
			}
		case type.SET_CURRENT_SECURITY_ROLE:
			return {
				...state,
				currentSecurityRoles :action.currentSecurityRoles
			}
		case type.SET_LIST_VIEW:
			return {
				...state,
				listView:action.listView
			}
		case type.SET_MODULES:
			return {
				...state,
				roleModuleList:action.roleModuleList
			}
		default:
			return state;
	}
};

export default reducer;
