import * as type from './actionTypes';
import moment from 'moment';

const initialState = {
    salesTeam:[],
    teams:[],
    trendingSalesAgentsList: [],
    trendingCustomerList: [],
    trendingCustomerCount: 0,
    trendingCustomerUpward: 0,
    trendingCustomterDownward: 0,
    trendingSalesAgentsCount: 0,
    trendingSalesAgentsUpward: 0,
    trendingSalesAgentsDownward: 0,
    percentageTrendingAgentsUp: 0,
    percentageTrendingAgentsDown:0,
    // currentPeriodFrom: moment().startOf('month').format('YYYY-MM-DD'),
    // currentPeriodTo: moment().endOf('month').format('YYYY-MM-DD'),
    // comparePeriodFrom: moment().subtract(1,'month').startOf('month').format('YYYY-MM-DD'),
    // comparePeriodTo: moment().subtract(1,'month').endOf('month').format('YYYY-MM-DD'),
    currentPeriodFrom: '',
    currentPeriodTo: '',
    comparePeriodFrom: '',
    comparePeriodTo: '',
    notificationList: [],
    notificationCount: 0,
    taskListIndv:[],
    taskListAll:[],
    leadsDashletAll:[],
    leadsDashletIndv:[],
    activeAgents:[],
    agentPerformanceSnapshotData: {},
    agentPerformanceSnapshotStatus: null,
    agentPerformanceSnapshotDataRange: null, 
    shipmentAlertStatusList:[],
    // Revenue By Team
    revenueByTeamRange1Data: [],
    revenueByTeamRange2Data: [],
    revenueByTeamInputDTO: {},
    // Revenue By New Existing
    revenueByNewExistingRange1Arr: [],
    revenueByNewExistingRange2Arr: [],
    revenueByNewExistingInputDTO: {},
    leadsDashletAllSalesFloor:[],
    percentageTrendingAgentsList: [],
    //revenue by customer classification
    revenueByCustomerRange1Arr: [],
    revenueByCustomerRange2Arr: [],
    revenueByCustomerInputDTO: {},
    // Goal Percentage & Ranking
    goalRanking: {},
    customers:[],
    filterAgents:[],
    filterTeams:[],
    taskLoader:false,
    leadLoader:false,
	performanceLoader:false,
	trendingCustomerLoader:false,
	shipmentLoader:false,
	notificationLoader:false,
    compareToFlag:false,
    performanceSnapshotPayload: {}
}
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case type.SET_TEAMS:			
            return {
                ...state,
                teams:action.teams
                        
            };

        case type.SET_TRENDING_SALES_AGENTS:			
            return {
                ...state,
                trendingSalesAgentsList: action.trendingSalesAgentsList,
                trendingSalesAgentsCount: action.trendingSalesAgentsCount,
                trendingSalesAgentsUpward: action.trendingSalesAgentsUpward,
                trendingSalesAgentsDownward: action.trendingSalesAgentsDownward,        
            };

        case type.SET_DASHBOARD_DATES:			
            return {
                ...state,
                currentPeriodFrom: action.currentPeriodFrom,
                currentPeriodTo: action.currentPeriodTo,
                comparePeriodFrom: action.comparePeriodFrom,
                comparePeriodTo: action.comparePeriodTo,
            };
            case type.SET_TRENDING_CUSTOMER:			
            return {
                ...state,
                trendingCustomerList: action.trendingCustomerList,
                trendingCustomerCount: action.trendingCustomerCount,
                trendingCustomerUpward: action.trendingCustomerUpward,
                trendingCustomterDownward: action.trendingCustomterDownward,  
            };      
            case type.SET_NOTIFICATIONS:	
            if(action.append == true){	
                return   {
                    ...state,
                    notificationList: state.notificationList.concat(action.notificationList),
                    notificationCount: action.notificationCount
                }
        }
        else {
            return   {
                ...state,
                notificationList: action.notificationList,
                notificationCount: action.notificationCount

            }

        }
              
        case type.SET_TASKLIST:			
            return {
                ...state,
                taskListIndv:action.taskListIndv,
                taskListAll:action.taskListAll
            };
        case type.SET_LEADLIST:			
            return {
                ...state,
                leadsDashletIndv:action.leadsDashletIndv,
                leadsDashletAll:action.leadsDashletAll
            };
        case type.SET_ACTIVE_AGENTS:			
            return {
                ...state,
                activeAgents:action.activeAgents
            };
            case type.SET_SHIPMENT_ALERT_STATUS:			
            return {
                ...state,
                shipmentAlertStatusList: action.shipmentAlertStatusList, 
            };   

        case type.SET_PERFORMANCE_SNAPSHOT:			
            return {
                ...state,
                agentPerformanceSnapshotData:action.agentPerformanceSnapshotData,
                agentPerformanceSnapshotStatus:action.agentPerformanceSnapshotStatus,
                agentPerformanceSnapshotDataRange: action.agentPerformanceSnapshotDataRange,
                performanceSnapshotPayload: action.performanceSnapshotPayload
            };

        case type.SET_REVENUE_BY_TEAM:			
            return {
                ...state,
                revenueByTeamRange1Data: action.revenueByTeamRange1Data,
                revenueByTeamRange2Data: action.revenueByTeamRange2Data,
                revenueByTeamInputDTO: action.revenueByTeamInputDTO,
            };

        case type.SET_REVENUE_BY_NEW_EXISTING:			
            return {
                ...state,
                revenueByNewExistingRange1Arr: action.revenueByNewExistingRange1Arr,
                revenueByNewExistingRange2Arr: action.revenueByNewExistingRange2Arr,
                revenueByNewExistingInputDTO: action.revenueByNewExistingInputDTO
            };
        case type.SET_LEADLISTSALESFLOOR:			
            return {
                ...state,               
                leadsDashletAllSalesFloor:action.leadsDashletAllSalesFloor
            };
        case type.SET_PERCENTAGE_TRENDING_AGENTS:			
            return {
                ...state,
                percentageTrendingAgentsUp: action.percentageTrendingAgentsUp,
                percentageTrendingAgentsDown: action.percentageTrendingAgentsDown,
                percentageTrendingAgentsList: action.percentageTrendingAgentsList, 
            };  
        case type.SET_REVENUE_BY_CUSTOMER:			
            return {
                ...state,
                revenueByCustomerRange1Arr: action.revenueByCustomerRange1Arr,
                revenueByCustomerRange2Arr: action.revenueByCustomerRange2Arr,
                revenueByCustomerInputDTO: action.revenueByCustomerInputDTO,
            };

        case type.SET_GOAL_RANKING:			
            return {
                ...state,
                goalRanking: action.goalRanking,
            };
        case type.SET_CUSTOMERS:			
            return {
                ...state,
                customers: action.customers,
            };
        case type.SET_FILTER_AGENT:			
            return {
                ...state,
                filterAgents: action.filterAgents,
            };
        case type.SET_FILTER_TEAM:			
            return {
                ...state,
                filterTeams: action.filterTeams,
            };
        case type.SET_TASK_LOADER:
            return {
                ...state,
                taskLoader:action.taskLoader
            };
        case type.SET_LEAD_LOADER:
            return {
                ...state,
                leadLoader:action.leadLoader
            };
		case type.SET_PERFORMANCE_LOADER:
            return {
                ...state,
                performanceLoader:action.performanceLoader
            };
		case type.SET_TRENDING_CUSTOMER_LOADER:
            return {
                ...state,
                trendingCustomerLoader:action.trendingCustomerLoader
            };
		case type.SET_SHIPMENT_LOADER:
            return {
                ...state,
                shipmentLoader:action.shipmentLoader
            };
		case type.SET_NOTIFICATIONS_LOADER:
            return {
                ...state,
                notificationLoader:action.notificationLoader
            };
		case type.SET_LEAD_LOADER:
            return {
                ...state,
                leadLoader:action.leadLoader
            };
        case type.SET_DATE_COMPARE_FLAG:
            return {
                ...state,
                compareToFlag:action.compareToFlag
            }
        default:
			return state;
    }
}

export default reducer;

