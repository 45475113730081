import React from "react";
import {connect} from "react-redux";
import Loader from '../../atoms/Loader'
import lodash from 'lodash';
import storeCreditService, {getCustomerStoreCreditBalance} from "@services/storeCreditService";
import {Link} from "react-router-dom";
import {
    STORE_CREDIT_TYPE_EXPIRATION,
    STORE_CREDIT_TYPE_MANUAL_ADDING,
    STORE_CREDIT_TYPE_PAYMENT,
    STORE_CREDIT_TYPE_REFUND
} from "@helpers/enums";
import moment from "moment";
import {getFinancialSettings} from "@services/applicationSettingsService";
import { convertToUsaCurrencyFormat } from '@helpers/utilCommon';

const storeCreditLogStyles = {
    link: {'textDecoration': 'unset', 'color': '#4a4a4a', 'fontSize': '12px'},
    negativeAmount: {color: '#ff6868'},
    customerBalance: {'float': 'right'},
    details: {fontSize: 12}
}

const SHIPMENT_ID_INDEX = 0;
const MANUAL_ID_INDEX = 0;
const AMOUNT_INDEX = 1;

class StoreCreditLogList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customerId: null
        }
    }

    componentWillMount() {
        const customerId = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
        this.props.getFinancialSettings(this.props.currentLanguage, this.props.dictionaries);
        this.props.getCustomerStoreCreditBalance(customerId);

        this.setState({customerId})
        this.props.getStoreCreditLogList(
            customerId,
            this.props.currentLanguage,
            this.props.dictionaries
        )
    }

    getAmount = (storeCredit) => {
        const isPlusAmountStoreCreditTransaction = [STORE_CREDIT_TYPE_REFUND, STORE_CREDIT_TYPE_MANUAL_ADDING].includes(storeCredit.type);
        const formattedAmount = convertToUsaCurrencyFormat(storeCredit.amount);
        const amount = isPlusAmountStoreCreditTransaction
            ? `$ ${formattedAmount}`
            : `$ (${formattedAmount})`;
        const styles = isPlusAmountStoreCreditTransaction
            ? {}
            : storeCreditLogStyles.negativeAmount

        return (<td style={styles}>{amount}</td>);
    }

    getDescription = (storeCredit) => {
        const storeCreditType = storeCredit.type;

        let description = '';
        switch (storeCreditType) {
            case STORE_CREDIT_TYPE_REFUND:
                description = `Refund or\nShipment cancelled. Store credit issued as refund method.`;
                break;
            case STORE_CREDIT_TYPE_MANUAL_ADDING:
                description = `Store credit added manually.`;
                break;
            case STORE_CREDIT_TYPE_PAYMENT:
                description = `Store credit used as payment method.`;
                break;
            case STORE_CREDIT_TYPE_EXPIRATION:
                description = `Store credit expired`;
                break;
        }

        return description;
    }

    getShipmentId = (shipmentId) => {
        return shipmentId
            ? (
                <Link style={storeCreditLogStyles.link}
                      to={`/home/shipment-management/${shipmentId}`}>{shipmentId}
                </Link>
            )
            : ''
    }

    getShipmentDetails = storeCredit => {
        return Object.entries(JSON.parse(storeCredit.details || '[]')).filter(detail => detail[0] !== 'manual')
    }

    getManualDetails = storeCredit => {
        const manualDetails = Object.entries(JSON.parse(storeCredit.details || '[]')).filter(detail => detail[0] === 'manual');

        if (lodash.isEmpty(manualDetails)) {
            return [];
        }

        return Object.entries(manualDetails[0][1]);
    }

    render() {
        return (
            <div className="main-wraper mt30">
                <div className="main-content">
                    <div className="page-title"><h2>Store credit logs</h2></div>
                    <div className="white-section search-cutomer">
                        <fieldset className={"tableFieldset p10 bdr0"}>
                            <div className="filter-title-row1 mb10 uppercase pl10">
                                <span className="font13 pl10">CUSTOMER ID# {this.state.customerId}</span>
                                <span style={storeCreditLogStyles.customerBalance} className="font13 pr10">STORE CREDIT TOTAL BALANCE: ${this.props.customerStoreCreditBalance}</span>
                            </div>
                            <div className="searchbaar open">
                                <table cellpadding="0" cellspacing="0" border="0" className="tbl-data">
                                    <thead>
                                    <tr>
                                        <td width="10%">
                                            <div className="sort">Shipment</div>
                                        </td>
                                        <td width="20%">
                                            <div className="sort">Description</div>
                                        </td>
                                        <td width="10%">
                                            <div className="sort">Date and time</div>
                                        </td>
                                        <td width="10%">
                                            <div className="sort">Amount</div>
                                        </td>
                                        <td width="10%">
                                            <div className="sort">Expiration on</div>
                                        </td>
                                        <td width="10%">
                                            <div className="sort">Assigned To</div>
                                        </td>
                                    </tr>
                                    </thead>
                                    {
                                        !lodash.isEmpty(this.props.storeCreditLogs) &&
                                        this.props.storeCreditLogs.map(storeCredit => (
                                                <tbody key={storeCredit.id}>
                                                <tr key={storeCredit.id}>
                                                    <td>{this.getShipmentId(storeCredit.shipmentId)}</td>
                                                    <td>
                                                        <div style={{whiteSpace: 'pre-line'}}>
                                                            {this.getDescription(storeCredit)}
                                                        </div>
                                                        {
                                                            this.getShipmentDetails(storeCredit).map(detail => {
                                                                return <p style={storeCreditLogStyles.details}>$ {detail[AMOUNT_INDEX]} used from shipment {detail[SHIPMENT_ID_INDEX]}</p>
                                                            })
                                                        }
                                                        {
                                                            this.getManualDetails(storeCredit).map(manualDetail => {
                                                                return <p style={storeCreditLogStyles.details}>$ {manualDetail[AMOUNT_INDEX]} used from manually added store credit, ID {manualDetail[MANUAL_ID_INDEX]}</p>
                                                            })
                                                        }
                                                    </td>
                                                    <td>{moment(storeCredit.createdAt).format('M/D/YYYY LTS')}</td>
                                                    {this.getAmount(storeCredit)}
                                                    <td>{storeCredit.expirationDate && moment(storeCredit.expirationDate).format('M/D/YYYY')}</td>
                                                    <td>{storeCredit.createdByName || ''}</td>
                                                </tr>
                                                </tbody>
                                            )
                                        )
                                    }
                                    {
                                        lodash.isEmpty(this.props.storeCreditLogs)
                                            ? (
                                                <tbody>
                                                <tr>
                                                    <td colSpan="8" className="error txtCenter">No Records Found</td>
                                                </tr>
                                                </tbody>
                                            )
                                            : null
                                    }
                                </table>
                            </div>
                            {this.props.loading && <Loader/>}
                        </fieldset>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    storeCreditLogs: state.customer.storeCreditLogs,
    storeCreditExpDays: state.applicationSettings.financialSettings.storeCreditExpDays,
    customerStoreCreditBalance: state.customer.customerStoreCreditBalance
});

const mapDispatchToProps = dispatch => ({
    getStoreCreditLogList: (customerId, lang, dictionaries) => {
        storeCreditService.getStoreCreditLogList(dispatch, lang, dictionaries, customerId);
    },
    getFinancialSettings: (lang, dictionary) => {
        getFinancialSettings(dispatch, lang, dictionary);
    },
    getCustomerStoreCreditBalance: (customerId) => {
        getCustomerStoreCreditBalance(dispatch, customerId);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreCreditLogList);
