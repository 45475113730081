// @flow

// /file/{fileId}

export const SET_MESSAGE = 'common/SET_MESSAGE';
export const SET_LOADER = 'common/SET_LOADER';
export const SET_ACCESORIAL = 'common/SET_ACCESORIAL';
export const SET_MASTER_DATA = 'common/SET_MASTER_DATA';
export const DELETE_KEYWORD = 'common/DELETE_KEYWORD';
export const SHOW_CONSOLE = 'common/SHOW_CONSOLE';
export const CONSOLE_ADD = 'common/CONSOLE_ADD';

