export const notesDisplayCount = 4;
export const freightCenterId = 1;
export const conventionCenterId = 2;
export const mapURL = 'https://maps.googleapis.com/maps/api/geocode/json?address=zipCode&sensor=true&key=googleKey'
export const zipPredictionsURL = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=inputValue&types=(regions)&key=googleKey'
export const SHIPPING_PICKUP_STATUS = 5;
export const SHIPPING_IN_TRANSIT_STATUS = 6;
export const SHIPPING_DELIVERED_STATUS = 7;
export const PACKAGE_TYPE_PALLET_DEAFULT = 2;
export const SHIPMENT_ALERT_PROBLEM_OTHER_ID = 10;
export const SHIPMENT_ALERT_PROBLEM_MISSING_ID = 31;
export const SHIPMENT_ALERT_TEXT =  `Shipment alert: problemDescription`;
export const SHIPMENT_DEACTIVATE_STATUS_ID = 10;
export const SHIPMENT_REACTIVATE_TEXT = "This shipment has been deactivated. Click here to reactivate it"
export const SHIPMENT_DEACTIVATION_REASON_OTHER_ID = 5;
export const SHIPMENT_TRANSACTION_FREIGHT_ID =1;
export const SHIPMENT_TRANSACTION_BILLING_ID = 2;
export const SHIPMENT_TRANSACTION_ADJUST_ID = 3;
export const SHIPMENT_TRANSACTION_THIRDPARTY_ID = 4;
export const SHIPMENT_TRANSACTION_DISCOUNT_ID = 6;
export const SHIPMENT_TRANSACTION_REFUND_ID = 5;
export const SHIPMENT_TRANSACTION_PAYMENT_AR_ID = 159;
export const SHIPMENT_TRANSACTION_ADDITIONAL_WEIGHT_ID = 46;
export const SHIPMENT_TRANSACTION_COUPON_ID = 95;
export const SHIPMENT_TRANSACTION_BUSINESS_TECH_FEE_ID = 1019;
export const SHIPMENT_TRANSACTION_ONLINE_BOOKING_DISCOUNT_ID = 1020;
export const SHIPMENT_TRANSACTION_CONCIERGE_FEE_ID = 1021;
export const SHIPMENT_TRANSACTION_BA_MISC_DUP_ID = 276;
export const SHIPMENT_TRANSACTION_BA_MISC_LTL_ID = 307;
export const SHIPMENT_TRANSACTION_AA_MISC_LTL_ID = 74;
export const SHIPMENT_TRANSACTION_BA_MISC_TL_ID = 1025;
export const SHIPMENT_TRANSACTION_AA_MISC_TL_ID = 1026;
export const SHIPMENT_TRANSACTION_INSURANCE_ADMIN_FEE_ID = 1027;
export const SHIPMENT_TRANSACTION_CARRIER_RATE_ADJ_ID = 1039;
export const SHIPMENT_TRANSACTION_CARRIER_RATE_ADJ_DESCRIPTION = 'Carrier rate adjustment';
export const SHIPMENT_TRANSACTION_CC_PROCESSING_FEE = 1031;
export const SHIPMENT_PAYMENT_REFUND_ID = 2;
export const SHIPMENT_PAYMENT_ID = 1;
export const FACILITY_TYPE_BUSINESS_WTOUT_LIFT = 3;
export const FACILITY_TYPE_BUSINESS_WITH_LIFT = 4;
export const FACILITY_TYPE_TRADE_SHOW_OR_CONVENTION = 6;
export const LTL_SHIPMENT_MODE = 1;
export const TL_SHIPMENT_MODE = 3;
export const SHIPMENT_ESTIMATED_STATUS =1;
export const SHIPMENT_PAYMENT_TYPE = 1;
export const PACKAGING_TYPE_PALLET = [1,2,3,4];
export const PACKAGING_TYPE_CRATE = [6];
export const SHIPMENT_PROCESS_REVIEW = 2;
export const SHIPMENT_PAYMENT_PENDING = 3;
export const SHIPMENT_PAYMENT_RECEIVED = 4;
export const COUPON_TYPE_FLAT =1
export const COUPON_TYPE_PERC =2
export const SHIPMENT_CANCEL_STATUS = 8
export const SHIPMENT_CANCELED_STATUS = 9
export const CANCELLATION_PERCENTAGE_ID = 1
export const SHIPMENT_DEACTIVATE_TYPE = 2;
export const AR_ACCOUNT_ID = 7;
export const IMPORTER_OTHER_PARTY = 3;
export const USA_COUNTRY_ID = 228;
export const CANADA_COUNTRY_ID = 39;
export const MEXICO_COUNTRY_ID =170;
export const PAYMENT_INITIATED_STATUS = 0;
export const PAYMENT_APPROVED_STATUS = 1;
export const BUSINESS_TYPE_FIELD = [3,4]
export const LTL_MARKUP = 2;
export const TL_MARKUP = 3;
export const OPEN_OVERDUE_TASK = [1,5];
export const REACT_APP_MIGRATION_DATE="2019-12-31 00:00:00";
export const SHIPMENT_PAYMENT_STATUS_ID_APPROVED = 1;
export const TRUCKLOAD_SALES_DEPARTMENT = 24;
export const NAM_SALES_DEPARTMENT = 29;
export const USER_PERMISSION = 4;
export const TEAM_PERMISSION = 5;
export const COMPANY_PERMISSION = 6;

export const SHIPMENT_TL_ESTIMATE_SAVED_STATUS = 11;
export const SHIPMENT_TL_OPEN_STATUS = 12;
export const SHIPMENT_TL_PROCESSING_REVIEW_STATUS = 13;
export const SHIPMENT_TL_COVERED_STATUS = 14;
export const SHIPMENT_TL_BOOKED_STATUS = 15;
export const SHIPMENT_TL_ARRIVED_AND_PICKUP_STATUS = 16;
export const SHIPMENT_TL_LOADED_STATUS = 17;
export const SHIPMENT_TL_IN_TRANSIT_STATUS = 18;
export const SHIPMENT_TL_AT_DESTINATION_STATUS = 19;
export const SHIPMENT_TL_DELIVERED_STATUS = 20;
export const SHIPMENT_TL_DEACTIVATED_STATUS = 21;
export const SHIPMENT_TL_CANCELED_STATUS = 22;

export const DATA_SOURCE_TMS = 2;

export const STORE_CREDIT_TYPE_REFUND = 1;
export const STORE_CREDIT_TYPE_MANUAL_ADDING = 2;
export const STORE_CREDIT_TYPE_PAYMENT = 3;
export const STORE_CREDIT_TYPE_EXPIRATION = 4;

export const CARD_PAYMENT_METHOD = 3;
export const AR_PAYMENT_METHOD = 7;
export const ACH_PAYMENT_METHOD = 6;
export const WIRE_PAYMENT_METHOD = 5;
export const CHECK_PAYMENT_METHOD = 4;
export const OTHER_PAYMENT_METHOD = 15;
export const STORE_CREDIT_PAYMENT_METHOD = 16;
export const CC_MANUAL_PAYMENT_METHOD = 18;

export const SHIPMENT_SOURCE_FC = 1;
export const SHIPMENT_SOURCE_TMS = 2;

export const CUSTOMER_TYPE_RESIDENTIAL = 1;
export const CUSTOMER_TYPE_COMMERCIAL = 2;
export const CUSTOMER_TYPE_COMMERCIAL_ONE_TIME = 2;
export const CUSTOMER_TYPE_COMMERCIAL_ONE_TO_TEN_TIME = 3;
export const CUSTOMER_TYPE_COMMERCIAL_TEN_PLUS_TIME = 4;
export const CARRIER_CONTRACT_ADDITIONAL_FEE = 715;
export const CARRIER_CHANGE_ADJUSTMENT = 1002;
export const RETAIN_PROFIT_TRANSACTION = 1044;
export const RETAIN_PROFIT_TRANSACTION_DESCRIPTION = 'Retain profit';
export const CARRIER_CHANGE_ADJ_TRANSACTION_DESCRIPTION = 'Carrier-change adjustment';

export const DefaultItemInShipmentLTL = {
  weightUnit: 2,
  dimUnit: 2,
  itemType: '1',
  dimensionType: true,
  palleteType: 2,
  length: 48,
  width: 40,
};

export const DefaultItemInShipmentTL = {
  weightMeasurement: 2,
  productMeasurement: 2,
  noOfItem: 1,
  palleteType: 2,
  length: 48,
  width: 40,
};

export const COUNTRIES_WITH_STATES = [
  USA_COUNTRY_ID,
  CANADA_COUNTRY_ID,
  MEXICO_COUNTRY_ID
];
export const CREDIT_APP_STATUS_DECLINED = 4;
export const CREDIT_APP_STATUS_NAME_DECLINED = 'Declined';
export const COMMODITY_TYPE_ID_USED = 1001;
export const COMMODITY_CATEGORY_ID_NOT_ALLOWED = [12, 13, 18, 35, 36, 37, 38]; // Household Goods
export const INSURANCE_QUOTE_EXPIRES_IN_HOURS = 24000; // FGL-1951
export const REWARD_TRANSACTION_TYPE_MANUALLY_ADDED = 1;
export const REWARD_TRANSACTION_TYPE_ADDED_BY_SHIPMENT = 2;
export const REWARD_TRANSACTION_REDEEM = 3;
export const REWARD_TRANSACTION_TYPE_EXPIRATION = 4;

export const FinancialSettingsMapping = {
  cancelFeeLTL: 1,
  cancelFeeTL: 11,
  manualPricingLTL: 2,
  manualPricingTL: 3,
  insuranceMargin: 4,
  minInsuranceCost: 12,
  minInsuranceCharge: 7,
  billingThreshold: 5,
  billingFee: 6,
  conciergeFee: 13,
  businessTechFee: 14,
  marketingFee: 15,
  cancelFeeLTLStoreCredit: 16,
  cancelFeeTLStoreCredit: 17,
  storeCreditExpDays: 18,
  maxInsuredValue: 19,
  lateFeeAmount: 20,
  lateFeeTiming: 21,
  monthlyFinChargeAmount: 22,
  creditCardProcessingFee: 23,
  creditCardProcessingFeeThreshold: 24
}

export const HAZMAT_ACCESSORIAL_ID = 104;

export const SHIPMENT_MODE = {
  [LTL_SHIPMENT_MODE]: 'ltl',
  [TL_SHIPMENT_MODE]: 'tl'
}

export const SHIPMENT_STATUS_ID_TO_TEXT = {
  11: 'TL Estimate saved',
  12: 'TL Open',
  13: 'TL Processing review',
  14: 'TL Covered',
  15: 'TL Booked',
  16: 'TL Arrived at Pick-up',
  17: 'TL Loaded',
  18: 'TL In-transit',
  19: 'TL At Destination',
  20: 'TL Delivered',
  21: 'TL Deactivated',
  22: 'TL Cancelled'
}

export const SHIPMENT_STATUS_ID_TO_TEXT_STATUS_ROW = {
  11: 'Estimate saved',
  12: 'Open',
  13: 'Processing review',
  14: 'Covered',
  15: 'Booked',
  16: 'Arrived at Pick-up',
  17: 'Loaded',
  18: 'In-transit',
  19: 'Arrived at destination',
  20: 'Delivered',
}

export const SHIPMENT_MODE_TL = 'tl';
export const SHIPMENT_MODE_LTL = 'ltl';

export const DOCUMENT_TYPE_ID_CA = 10;
export const DOCUMENT_TYPE_ID_CRA = 11;
export const DOCUMENT_TYPE_ID_PRODUCT_IMAGE = 13;
export const DOCUMENT_CATEGORY_ID_PUBLIC = 1;
export const DOCUMENT_CATEGORY_ID_PRIVATE = 2;
export const MAX_INSURED_VALUE_CEIL = 100000.00;
export const OVER_LENGTH_ACC_CODE = 'ELS';
export const OVER_LENGTH_ACC_ID = 68;
export const LIFTGATE_PICKUP_ACC_CODE = 'LGPU';
export const LIFTGATE_PICKUP_ACC_ID = 15;
export const LIFTGATE_DELIVERY_ACC_CODE = 'LGDEL';
export const LIFTGATE_DELIVERY_ACC_ID = 42;

export const ADDRESS_TYPE_PICKUP = 1;
export const ADDRESS_TYPE_DROP = 2;

export const VIEWPORT_BASIC_SECTION = 1;
export const VIEWPORT_PRODUCT_SECTION = 2;

export const CUSTOMER_SHIPPING_FREQUENCY_TO_TEMPLATE = {
  [CUSTOMER_TYPE_RESIDENTIAL]: 'SingleRate_v2',
  [CUSTOMER_TYPE_COMMERCIAL_ONE_TIME]: 'SingleRate_v3',
  [CUSTOMER_TYPE_COMMERCIAL_ONE_TO_TEN_TIME]: 'Full',
  [CUSTOMER_TYPE_COMMERCIAL_TEN_PLUS_TIME]: 'Full',
};

export const INSURANCE_TIER_OPTIONS = [
  {id: 99, name: 'No insurance available'},
  {id: 1, name: 'Tier 1'},
  {id: 2, name: 'Tier 2'},
  {id: 3, name: 'Tier 3'},
]

export const BA_PROCESSING_FEE_TRANSACTION_TYPE_NAME = 'BA Processing Fee';
export const BA_PROCESSING_FEE_TRANSACTION_DESCR = 'Processing fee';
