// @flow
import * as type from './actionTypes';
import {SET_AGENT_BONUS_DETAILED_REPORT_DATA} from "./actionTypes";

const initialState = {
	alerts: [],
	agents: [],
	teams: [],
	shipments: [],
	totalProcessingQueueCount: 0,
	// Frequent Shippers By Area
	totalCount: 0,
	shipperByAreaReport: [],
	shipperByAreaReportInputDTO: {},
	shipperByAreaReportURL: null,
	// Customer Discount Report
	customerDiscountReportsList: [],
	customerDiscountReportsCount: 0,
	customerDiscountReportsURL:null,
	inputDTO: null,
	users: [],
	registrationReportList: [],
	registrationReportCount: 0,
	registrationReportsURL: null,
	registrationReportinputDTO: {},
	customerType:[],
	masterData: [],
	dailyBookingReportCount: 0,
	dailyBookingReportList: [],
	dailyBookingReportURL: null,
	carrierReportList: [],
	carrierReportCount: 0,
	carrierReportURL: null,
	carrierReportInputDTO: {},
	carrierCount: 0,
	carrierList: [],
	processReviewData: {},
	arCustomers : [],
	arCustomerTotalCount : 0,
	arURL: null,
	statisticalReportsList: [],
	statisticalReportsCount: 0,
	statisticalReportsURL: null,
	statisticalReportsInputDTO: {},
	salesList:[],
	salesTrackingReportURL: null,
	// Top Dog Report
	topDogReportList:[],
	topDogReportCount: 0,
	topDogReportHeaderData: [],
	topDogReportURL: null,
	topDogReportInputDTO: {},
	totalPaidSales: 0,
	totalPaidProfit: 0,
	// Agent Revenue Comparison Report
	agentRevenueComparisonList:[],
	agentRevenueComparisonInputDTO:{},
	agentRevenueComparisonCount: 0,
	agentRevenueComparisonURL:null,
	// Paging & Sorting
	paging: false,
	pageNumber: 1,
	pageSize: 50,
	sortColumn: '',
	sortOrder: '',
	alertURL: null,
	// Customer Trend export const
	customerTrendReportList: [],
	customerTrendReportInputDTO: {},
	customerTrendReportCount: 0,
	customerTrendReportURL: null,
	totalBookings1: 0,
	totalProfit1: 0,
	totalProfitPercentage1: 0,
	totalBookings2: 0,
	totalProfit2: 0,
	totalProfitPercentage2: 0,
	totalCustomers: 0,
	totalDifference: 0,
	// Processing Review Queue
	processQPageSize: 50,
	processQSortColumn: 'addedDate',
	processQSortOrder: 'asc',
	processQPageNumber: 1,
	// Daily Sales Report
	dailySalesReportList: [],
	dailySalesReportInputDTO: {},
	dailySalesReportCount: 0,
	dailySalesReportURL: null,
	dailySalesReportTeamWiseData: [],
	// Agent Conversion Report
	agentConversionReportList: [],
	agentConversionReportInputDTO: {},
	agentConversionReportCount: 0,
	agentConversionReportURL: null,
	fcQuoteResultsReportList: [],
	fcQuoteResultsReportCount: 0,
	fcQuoteResultsReportURL: null,
	fcQuoteResultsReportInputDTO: {},
	agentBonusReportCount: 0,
	agentBonusReportList: [],
	agentBonusReportTotalPaidTransactions: 0,
	agentBonusReportTotalPaidProfit: 0
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case type.SET_ALERTS:
			return {
				...state,
				alerts: action.alerts,
				totalCount: action.totalCount,
				alertURL: action.alertURL
			};
		case type.SET_MASTER_DATA:
			return {
				...state,
				masterData: action.masterData,
			};
		case type.SET_AR_CUSTOMERS:
		return {
			arCustomers  :action.arCustomers,
			arCustomerTotalCount : action.arCustomerTotalCount,
			arURL: action.arURL,
		}	

		case type.SET_LOADER:
			return {
				...state,
				sectionLoading: action.loading,
			};
		case type.SET_AGENTS:
			return {
				...state,
				agents: action.agents,
			};
		case type.SET_TEAMS:
			return {
				...state,
				teams: action.teams,
			};
		case type.SET_PROCESSING_REVIEW_SHIPMENTS:
			return {
				...state,
				shipments: action.shipments,
				totalProcessingQueueCount: action.totalProcessingQueueCount,
			};
		case type.SET_SHIPPERS_BY_AREA:
			return {
				...state,
				shipperByAreaReport: action.shipperByAreaReport,
				shipperTotalCount : action.totalCount,
				shipperByAreaReportInputDTO : action.shipperByAreaReportInputDTO,
				shipperByAreaReportURL: action.shipperByAreaReportURL,
			};
		case type.SET_ALERTS:
			return {
				...state,
				alerts: action.alerts,
				totalCount: action.totalCount
			};
		case type.SET_MASTER_DATA:
			return {
				...state,
				masterData: action.masterData
			}

		case type.SET_USERS:
			return {
				...state,
				users: action.users
			}

		case type.SET_LOADER:
			return {
				...state,
				sectionLoading: action.loading
			}
		case type.SET_AGENTS:
			return {
				...state,
				agents: action.agents
			}
		case type.SET_TEAMS:
			return {
				...state,
				teams: action.teams
			}
		case type.SET_PROCESSING_REVIEW_SHIPMENTS:
			return {
				...state,
				shipments: action.shipmentData,
				totalProcessingQueueCount: action.totalProcessingQueueCount
			}
		case type.SET_PROCESSING_REVIEW_DATA:
			return {
				...state,
				processReviewData: action.processReviewData
			}

		case type.SET_CUSTOMER_DISCOUNT_REPORT:
			return {
				...state,
				customerDiscountReportsList:action.customerDiscountReportsList,
				customerDiscountReportsCount:action.customerDiscountReportsCount,
				customerDiscountReportsURL : action.customerDiscountReportsURL,
				inputDTO: action.inputDTO,
			}
		case type.SET_REGISTRATION_REPORT:
			return {
				...state,
				registrationReportList: action.registrationReportList,
				registrationReportCount: action.registrationReportCount,
				registrationReportURL: action.registrationReportURL,
				registrationReportinputDTO: action.registrationReportinputDTO,
			}
		case type.SET_CARRIER_REPORT:
			return {
				...state,
				carrierReportList:action.carrierReportList,
				carrierReportCount:action.carrierReportCount,
				carrierReportURL: action.carrierReportURL,
				carrierReportInputDTO: action.carrierReportInputDTO,
			}

		case type.SET_CARRIERS:
			return {
				...state,
				carrierList:action.carrierList,
				carrierCount:action.carrierCount
			}

		case type.SET_DAILY_BOOKING_REPORT:
			return {
				...state,
				dailyBookingReportList:action.dailyBookingReportList,
				dailyBookingReportCount:action.dailyBookingReportCount,
				dailyBookingReportURL: action.dailyBookingReportURL,
			}
		

		case type.SET_STATISTICAL_REPORT:
			return {
				...state,
				statisticalReportsList: action.statisticalReportsList,
				statisticalReportsCount: action.statisticalReportsCount,
				statisticalReportsURL: action.statisticalReportsURL,
				statisticalReportsInputDTO: action.statisticalReportsInputDTO,
			}

		case type.SET_CARRIERS:
			return {
				...state,
				carrierList:action.carrierList,
				carrierCount:action.carrierCount
			}
		case type.SET_SALES_TRACKING:
			return{
				...state,
				salesList:action.salesList,
				salesTrackingReportURL: action.salesTrackingReportURL
			}

		case type.SET_TOP_DOG_REPORT:
			return {
				...state,
				topDogReportList:action.topDogReportList,
				topDogReportCount:action.topDogReportCount,
				topDogReportURL: action.topDogReportURL,
				topDogReportInputDTO: action.topDogReportInputDTO,
			}

		case type.SET_TOP_DOG_REPORT_HEADER_DATA:
			return {
				...state,
				totalPaidSales:action.totalPaidSales,
				totalPaidProfit:action.totalPaidProfit
			}
		
		case type.SET_AGENT_COMPARISON_REPORT:
			return {
				...state,
				agentRevenueComparisonList: action.agentRevenueComparisonList,
				agentRevenueComparisonInputDTO: action.agentRevenueComparisonInputDTO,
				agentRevenueComparisonCount: action.agentRevenueComparisonCount,
				agentRevenueComparisonURL: action.agentRevenueComparisonURL,
			}

		case type.SET_CUSTOMER_TREND_REPORT:
			return {
				...state,
				customerTrendReportList: action.customerTrendReportList,
				customerTrendReportInputDTO: action.customerTrendReportInputDTO,
				customerTrendReportCount: action.customerTrendReportCount,
				customerTrendReportURL: action.customerTrendReportURL,
				totalCustomers: action.totalCustomers,
				totalDifference: action.totalDifference,
				totalBookings1: action.totalBookings1,
				totalProfit1: action.totalProfit1,
				totalProfitPercentage1: action.totalProfitPercentage1,
				totalBookings2: action.totalBookings2,
				totalProfit2: action.totalProfit2,
				totalProfitPercentage2: action.totalProfitPercentage2,
			}

		case type.SET_PAGING_SORTING:
			return {
			  ...state,
			  pageNumber: action.pageNumber,
			  pageSize: action.pageSize,
			  sortColumn: action.sortColumn,
			  sortOrder: action.sortOrder
			}
		case type.UPDATE_PROCESSQ_PAGING:
			return {
				...state,
				processQPageNumber: action.processQPageNumber,
				processQPageSize: action.processQPageSize,
				processQSortColumn: action.processQSortColumn,
				processQSortOrder: action.processQSortOrder,
			}

		case type.SET_DAILY_SALES_REPORT:
			return {
				...state,
			dailySalesReportList: action.dailySalesReportList,
			dailySalesReportInputDTO: action.dailySalesReportInputDTO,
			dailySalesReportCount: action.dailySalesReportCount,
			dailySalesReportURL: action.dailySalesReportURL,
			dailySalesReportTeamWiseData: action.dailySalesReportTeamWiseData,
		}

		case type.SET_AGENT_CONVERSION_REPORT:
			return {
				...state,
			agentConversionReportList: action.agentConversionReportList,
			agentConversionReportInputDTO: action.agentConversionReportInputDTO,
			agentConversionReportCount: action.agentConversionReportCount,
			agentConversionReportURL: action.agentConversionReportURL,
		}

		case type.SET_FC_QUOTE_RESULTS_REPORT:
			return {
				...state,
				fcQuoteResultsReportList: action.fcQuoteResultsReportList,
				fcQuoteResultsReportCount: action.fcQuoteResultsReportCount,
				fcQuoteResultsReportURL: action.fcQuoteResultsReportURL,
				fcQuoteResultsReportInputDTO: action.fcQuoteResultsReportInputDTO
			}

		case type.SET_COVERED_REPORT_DATA:
			return {
				...state,
				count: action.count,
				list: action.list,
				totalPaidCovered: action.totalPaidCovered,
				totalPaidProfit: action.totalPaidProfit
			}

		case type.SET_AGENT_BONUS_REPORT_DATA:
			return {
				...state,
				agentBonusReportCount: action.agentBonusReportCount,
				agentBonusReportList: action.agentBonusReportList,
				agentBonusReportTotalPaidTransactions: action.agentBonusReportTotalPaidTransactions,
				agentBonusReportTotalPaidProfit: action.agentBonusReportTotalPaidProfit
			}

		case type.SET_AGENT_BONUS_DETAILED_REPORT_DATA:
			return {
				...state,
				agentBonusDetailedReportCount: action.agentBonusDetailedReportCount,
				agentBonusDetailedReportList: action.agentBonusDetailedReportList,
				agentBonusDetailedReportTotalPaidTransactions: action.agentBonusDetailedReportTotalPaidTransactions,
				agentBonusDetailedReportTotalPaidProfit: action.agentBonusDetailedReportTotalPaidProfit
			}

		default:
			return state;
	}

};

export default reducer;
