// @flow
import * as type from './actionTypes';

const initialState = {
  users: [],
  totalcount: 0,
  currentUser: null,
  salesTeams: [],
  leadType: [],
  leadMode: [],
  timeZones: [],
  departments: [],
  roles: [],
  payTypes: [],
  addNewUser: false,
  //security level
  modules: [],
  securityLevelList: [],
  currentSecurityLevel: null,
  securityLevelCount: 0,
  searchKeyword: null,
  teams: [],
  pageSize: 50,
	sortColumn: 'name',
	sortOrder: 'asc',
	pageNumber: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_USERS:
      return {
        ...state,
        users: action.users,
        currentUser: action.users[0],
        totalcount: action.totalCount,
      };


    case type.SET_USER:
      return {
        ...state,
        currentUser: action.user
      };

    case type.SET_KEYWORD:
      return {
        ...state,
        searchKeyword: action.searchKeyword
      };

    case type.SET_SALESTEAMS:
      return {
        ...state,
        salesTeams: action.data
      };

    case type.SET_LEAD_TYPE:
      return {
        ...state,
        leadType: action.leadType
      };

    case type.SET_LEAD_MODE:
      return {
        ...state,
        leadMode: action.leadMode
      };

    case type.SET_PAYTYPES:
      return {
        ...state,
        payTypes: action.data
      };

    case type.SET_TIMEZONES:
      return {
        ...state,
        timeZones: action.data
      };

    case type.SET_ROLES:
      return {
        ...state,
        roles: action.data
      };

    case type.SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.data
      };

    case type.ADD_NEW_USER:
      return {
        ...state,
        addNewUser: true
      };

    case type.SET_MODULES:
      return {
        ...state,
        modules: action.modules
      }

    case type.SET_SECURITY_LEVEL:
      return {
        ...state,
        securityLevelList: action.securityLevelList,
        securityLevelCount: action.totalResults
      };

    case type.SET_CURRENT_SECURITY_LEVEL:
      return {
        ...state,
        currentSecurityLevel: action.currentSecurityLevel,
        currentSecurityLevelIndex: action.currentSecurityLevelIndex
      };

    case type.SET_TEAMS:
      return {
        ...state,
        teams: action.teams
      };
    case type.SET_USER_PAGINATION:
        return {
          ...state,
          pageNumber: action.pageNumber,
				  pageSize: action.pageSize,
				  sortColumn: action.sortColumn,
				  sortOrder: action.sortOrder,
        }
    default:
      return state;
  }
};

export default reducer;
