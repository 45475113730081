// @flow
// /file/{fileId}

export const BILLING_ADJUSTMENT_CONSOLE = 'admintool/BILLING_ADJUSTMENT_CONSOLE';
export const SET_PAYMENTS = 'admintool/SET_PAYMENTS';
export const SET_SHIPMENT_MASTER = 'admintool/SET_SHIPMENT_MASTER';
export const SET_MASTER_DATA = 'admintool/SET_MASTER_DATA';
export const SET_COUPON_VALIDATE_FLAG = 'admintool/SET_COUPON_VALIDATE_FLAG';
export const SET_INSURANCE_VALIDATE_FLAG = 'admintool/SET_INSURANCE_VALIDATE_FLAG';
export const SET_TRANSACTION_DETAILS = 'admintool/SET_TRANSACTION_DETAILS';
export const SET_SHIPMENT_PRODUCTS = 'admintool/SET_SHIPMENT_PRODUCTS';
export const SET_EXCLUDE_ITEM = 'admintool/SET_EXCLUDE_ITEM';
export const SET_TRANSACTIONS = 'admintool/SET_TRANSACTIONS';
export const SET_SHIPMENT_CHARGES = 'admintool/SET_SHIPMENT_CHARGES';
export const SET_CURRENT_TRANSACTION_DETAILS = 'admintool/SET_CURRENT_TRANSACTION_DETAILS';
export const SET_PAYMENT_AR_OPTIONS = 'admintool/SET_PAYMENT_AR_OPTIONS';
export const SET_PAYMENT_OPTIONS = 'admintool/SET_PAYMENT_OPTIONS';
export const SET_CURRENT_CUSTOMER = 'admintool/SET_CURRENT_CUSTOMER';
