import utilCommon from './utilCommon'
import { DATA_SOURCE_TMS } from '@helpers/enums';

export function authHeader() {
    let header = {}

    if (utilCommon.getSession('refreshToken') && !utilCommon.getSession('accessToken')) {
        header.refreshToken = utilCommon.getSession('refreshToken')
    }

    if (utilCommon.getSession('accessToken')) {
        header.accessToken = utilCommon.getSession('accessToken')
    }

    header['X-Data-Source'] = DATA_SOURCE_TMS;

    return header
}

export default authHeader;
