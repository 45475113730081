import * as type from './actionTypes';
import utilCommon from "@helpers/utilCommon";

export const setShipmentList = (shipments,totalCount,append) => ({
    type: type.SET_SHIPMENTS,
    shipments,
    totalCount,
    append
  });


export const setCurrentShipment = (currentShipment)=>({
    type: type.SET_CURRENT_SHIPMENT,
    currentShipment,
})

export const setLoader = (loading)=>({
    type:type.SET_LOADER,
    loading
})

export const setNotes = (notes,totalNotesCount)=>({
    type:type.SET_NOTES,
    notes,
    totalNotesCount
})


export const setAddresses = (addresses,totalAddressCount,append) => ({
    type: type.SET_ADDRESS,
    addresses,
    totalAddressCount,
    append
  });

export const setAgents = (agents) => ({
    type: type.SET_AGENTS,
    agents
});

export const setProducts = (products)=>({
    type: type.SET_PRODUCTS,
    products
})

export const setShipmentProducts = (shipmentProducts, shipmentProductsCount, shipmentProductAdded = false, shipmentProductDeleted = false)=>({
    type: type.SET_SHIPMENT_PRODUCTS,
    shipmentProducts,
    shipmentProductsCount,
    shipmentProductAdded,
    shipmentProductDeleted,
    shipmentElsFactor: shipmentProductsCount !== undefined ? utilCommon.getShipmentProductsMaxLength(shipmentProducts) : null
})

export const setAllContactLogs = (allContactLogs)=>({
    type: type.SET_ALL_CONTACTLOGS,
    allContactLogs
})

export const setShipmentAlert = (shipmentAlert)=>({
    type: type.SET_SHIPMENT_ALERT,
    shipmentAlert
})

export const setShipmentTransactions = (transactions)=>({
    type:type.SET_TRANSACTIONS,
    transactions
})

export const setShipmentTransactionDetails = (transactionDetails,totalCharge,totalCost, flag?)=>({
    type:type.SET_TRANSACTION_DETAILS,
    transactionDetails,
    totalCharge,
    totalCost
})

export const setShipmentTransactionLogs = (transactionLogs)=>({
    type:type.SET_TRANSACTION_LOGS,
    transactionLogs
})

export const setShipmentPaymentDetails = (payments,totalAmount)=>({
    type:type.SET_PAYMENTS,
    payments,
    totalAmount
})

export const setDocuments = (documents,totalDocumentCount)=>({
    type:type.SET_DOCUMENTS,
    documents,
    totalDocumentCount
})

export const clearDocuments = ()=>({
    type:type.CLEAR_DOCUMENTS
})

export const setTasks = (tasks,totalTaskCount,append) => ({
    type: type.SET_TASKS,
    tasks,
    totalTaskCount,
    append
});

export const setBolStatus = (status, documentsGenerated) => ({
  type: type.SET_BOL_STATUS,
  status,
  documentsGenerated,
});

export const addRunnerProduct = (uniqId) => ({
  type: type.ADD_PRODUCT_RUNNER,
  id: uniqId,
});

export const doneRunnerProduct = (uniqId) => ({
  type: type.DONE_PRODUCT_RUNNER,
  id: uniqId,
});

export const runRunnerProduct = (uniqId) => ({
  type: type.RUN_PRODUCT_RUNNER,
  id: uniqId,
});

export const setDocumentInvoicesInfo = (data) => ({
    type: type.SET_DOCUMENT_INVOICES_INFO,
    data
});

export const clearDocumentInvoicesInfo = ()=>({
    type:type.CLEAR_DOCUMENT_INVOICES_INFO
})

export const setTLPickupAddresses = (addresses) => ({
    type: type.SET_TL_PICKUP_ADDRESS,
    payload: addresses
});

export const setTLDropAddresses = (addresses) => ({
    type: type.SET_TL_DROP_ADDRESS,
    payload: addresses
});


export const submitTLPickupAddresses = (items) => ({
    type: type.SUBMIT_TL_PICKUP_ADDRESS,
    payload: items
});

export const submitTLDropAddresses = (items) => ({
    type: type.SUBMIT_TL_DROP_ADDRESS,
    payload: items
});

export const setTransactionDetails = (payload)=>({
    type: type.SET_TRANSACTION_DETAILS,
    transactionDetails: payload.transactionDetails,
    totalCost: payload.totalCost,
    totalCharge: payload.totalCharge,
    shipmentPayment: payload.shipmentPayment
});

export const setOrderStatuses = (orderStatuses) => ({
    type: type.SET_ORDER_STATUSES,
    orderStatuses
});

export const setTripEvents = (tripEvents) => ({
    type: type.SET_TRIP_EVENTS,
    tripEvents
});

export const setLocationUpdates = (locationUpdates) => ({
    type: type.SET_LOCATION_UPDATES,
    locationUpdates
});

export const setScheduleAlerts = (scheduleAlerts) => ({
    type: type.SET_SCHEDULE_ALERTS,
    scheduleAlerts
});

export const setCancellationInfo = (cancellationInfo) => ({
    type: type.SET_CANCELLATION_INFO,
    cancellationInfo
});
export const setOnlineCustomerShipmentList = (onlineCustomerShipments,totalOnline) => ({
    type: type.SET_ONLINE_CUSTOMER_SHIPMENTS,
    onlineCustomerShipments,
    totalOnline
});

export const addNewShipmentProduct = (newProduct) => ({
   type: type.ADD_SHIPMENT_PRODUCT,
   newProduct
});

export const removeTlShipmentProductByIndex = (productIndex) => ({
    type: type.REMOVE_TL_SHIPMENT_PRODUCT_BY_INDEX,
    productIndex
})

export const setManualPurchaseInsuranceNotification = (insurancePurchaseResponse)=>({
    type: type.SET_MANUAL_PURCHASE_INSURANCE_NOTIFICATION,
    insurancePurchaseResponse
})

export const setProcessingPaymentAndBookStatus = (status) => ({
    type: type.SET_PROCESSING_PAYMENT_AND_BOOK_STATUS,
    processingPaymentAndBook: status
})

