// @flow
import * as type from './actionTypes';

const initialState = {
  message: null,
  messageType: null,
  loading: false,
  accessorial: {},
  masterData: null,
  deleteKeyWord:false,
  debugOpen:false,
  logData:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_MESSAGE:
      return {
        ...state,
        messageType: action.messageType,
        message: action.message
      };

    case type.SET_LOADER:
      return {
        ...state,
        loading: action.flag
      };

    case type.SET_ACCESORIAL:
      return {
        ...state,
        accessorial: action.accessorial
      };

    case type.SET_MASTER_DATA:
      return {
        ...state,
        masterData: action.masterData
      };
    case type.DELETE_KEYWORD:
      return {
        ...state,
        deleteKeyWord:action.deleteKeyWord
      }
    case type.SHOW_CONSOLE:
      return {
        ...state,
        debugOpen:action.debugOpen
      }

    case type.CONSOLE_ADD:
      const log = state.logData
      log.push({
        name: action.name,
        data: JSON.stringify(action.data)
      })

      return {
        ...state,
        logData: log.slice(-200)
      }
    default:
      return state;
  }
};

export default reducer;
