// @flow

// /file/{fileId}
export const SET_CARRIERS = 'carrierManagement/SET_CARRIERS';
export const SET_CONTRACTS = 'carrierManagement/SET_CONTRACTS';
export const SET_CARRIER = 'carrierManagement/SET_CARRIER';
export const SET_LOADER = 'carrierManagement/SET_LOADER';
export const SET_CARRIER_LOGS = 'carrierManagement/SET_CARRIER_LOGS';
export const SET_KEYWORD = 'carrierManagement/SET_KEYWORD';
export const SET_CARRIER_PAGINATION = 'carrierManagement/SET_CARRIER_PAGINATION'
