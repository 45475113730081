import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Button, Grow, Paper, Modal, ClickAwayListener, MenuItem, MenuList, Radio, RadioGroup, FormControl, FormControlLabel, Select, InputLabel, IconButton, ListItemSecondaryAction, ListItem, List } from '@material-ui/core';
import CustomInputField from '../../atoms/CustomInputField';
import UserForm from '../../forms/userForm';
import UserBulEditForm from '../../forms/userBulkEditForm'
import userManagementService from '../../../services/api/userManagementService';
import utilCommon from '../../../helpers/utilCommon';
import classNames from 'classnames';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomRadioButton from '../../atoms/CustomRadioButton'
import CustomScroll from 'react-custom-scroll';
import moment from 'moment';
import Pagination from '../../molecules/Pagination'
import Loader from '../../atoms/Loader';
import sortImage from '../../../assets/images/sort.svg'
import lodash from 'lodash'

const now = moment().format().substring(0, 10);

class LogList extends React.Component<any,any> {
    constructor(props) {
        super(props);
        this.state = {
            checked: [],
            open: false,
            accountMenuOpen: false,
            hireDate: now,
            pageSize: 50,
            pageNumber: 1,
            sortColumn: 'firstName',
            sortOrder: 'desc',
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleToggle = (value) => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        });
    };

    handleChange = event => {
        this.setState({ accountMenuOpen: !this.state.accountMenuOpen });
    };

    handleClose = event => {
        this.setState({ accountMenuOpen: false });
    };

    handleBulkUpdate = (values) => {
        this.handleClose(values)
        let checked = Object.assign([], this.state.checked)
        // this.props.handleBulkUpdate(values, checked)
        this.setState({ checked: [] })
    }

    setPaging = (e) => {
        if (e.target) {
            this.setState({ pageNumber: 1, pageSize: e.target.value })
            // this.props.callAPI('getUsers', { pageSize: e.target.value, pageNumber: 1, sortColumn: this.state.sortColumn, sortOrder: this.state.sortOrder })
        } else {
            if (e != this.state.pageNumber) {
                this.setState({ pageNumber: e })
                // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: e, sortColumn: this.state.sortColumn, sortOrder: this.state.sortOrder })
            }
        }
    }


    getPages = () => {
        let pages = (this.props.totalResults / this.state.pageSize)
        let mod = pages % 1
        if (mod > 0) {
            pages = parseInt(pages) + 1
        }
        let arr = []
        for (let i = 0; i < pages; i++) {
            arr.push({})
        }
        return arr
    }

    handleSort = (column) => {
        if (this.state.sortColumn == column) {
            if (this.state.sortOrder == 'desc') {
                this.setState({ sortOrder: 'asc' })
                // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'asc' })
            } else {
                this.setState({ sortOrder: 'desc' })
                // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'desc' })
            }
        } else {
            this.setState({ sortColumn: column, sortOrder: 'desc' })
            // this.props.callAPI('getUsers', { pageSize: this.state.pageSize, pageNumber: this.state.pageNumber, sortColumn: column, sortOrder: 'desc' })
        }
    }


    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
          for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
     }


    render() {
        const { open, accountMenuOpen, checked } = this.state;
        let pages = this.getPages()

        return (
            <div className={"CustomerContractList"}>
                <table cellPadding="0" cellSpacing="0" border="0" className="tbl-data">
                        <thead>
                            <tr>
                                <td width="20%"><div className="sort">Date & time</div></td>
                                <td  width="40%"><div className="sort">Old value(s)</div></td>
                                <td width="40%"><div className="sort">New value(s)</div></td>
                            </tr>
                        </thead>
                        {this.props.logs.map((log, index) => (
                        ((this.props.allowOnlyTen && index < 10) || (!this.props.allowOnlyTen)) &&
                        <tbody key={log.id}>
                            <tr >
                                <td>
                                    <span className="dateFormate1">{moment(log.modifiedDate).format('MM/DD/YYYY hh:mm:ss A')}</span>
                                    <span>Source: <strong>{log.source}</strong></span><br/>
                                    <span>By: <strong>{log.updatedBy}</strong></span><br/>
                                    <span>Action: <strong>{log.action}</strong></span>
                                </td>
                                {/* <td>{getArr(log.Activities.old).map(x => (<div>{this.titleCase(x.split('_').join(' '))}{'=>'}{getTXT(log.Activities.old[x])}</div>))}</td> */}
                                <td style={{"wordBreak":"break-word"}}>{getArr(log.Activities.old).map((x, k) => (<div key={k}>{this.titleCase(x.split('_').join(' '))}{' => '}{getTXT(log.Activities.old[x])}</div>))}</td>
                                <td style={{"wordBreak":"break-word","paddingRight":"10px"}}>{getArr(log.Activities.attributes).map((x,k2) => (<div key={k2} className="multiline">{ this.titleCase(x.split('_').join(' ')) == 'Logstatus' ? '' : this.titleCase(x.split('_').join(' '))}{this.titleCase(x.split('_').join(' ')) == 'Logstatus' ? '' : ' => '}{getTXT(log.Activities.attributes[x])}</div>))}</td>
                            </tr>
                        </tbody>
                            ))}
                    </table>
            </div>

        );
    }
}

const titlesCases = (str) => {
    var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
 }

const getArr = (obj) => {

    // cost permission
    return utilCommon.isPermitted(1,3,1)  ? Object.keys(obj) : Object.keys(obj).filter(x=>x!='shipment_cost')
}

const getTXT = (obj) => {
    if(lodash.isObject(obj)){
       return obj[Object.keys(obj)[0]];
    }else{
        return obj
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    totalCount: state.user.totalcount,
    currentUser: state.user.currentUser,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LogList);
