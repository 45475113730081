// @flow

// /file/{fileId}

export const SET_ROLES = 'securitySettings/SET_ROLES';
export const SET_LOADER = 'securitySettings/SET_LOADER';
export const SET_CURRENT_SECURITY_ROLE = 'securitySettings/SET_CURRENT_SECURITY_ROLE';
export const SET_PAGING = 'securitySettings/SET_PAGING';
export const SET_LIST_VIEW = 'securitySettings/SET_LIST_VIEW';
export const SET_MODULES = 'securitySettings/SET_MODULES';

