import * as type from './actionTypes';

export const setCustomers = (customers, totalCount, append, url, advancedSearch, advancedSearchQuery) => ({
    type: type.SET_CUSTOMERS,
    customers,
    totalCount,
    append,
    url,
    advancedSearch,
    advancedSearchQuery
});

export const setProducts = (products, totalProductCount, append) => ({
    type: type.SET_PRODUCTS,
    products,
    totalProductCount,
    append
});

export const setAddresses = (addresses, totalAddressCount, append) => ({
    type: type.SET_ADDRESS,
    addresses,
    totalAddressCount,
    append
});


export const setCurrentCustomer = (currentCustomer) => {
    if (typeof currentCustomer.customerLeadSource === 'string' && currentCustomer.customerLeadSource.trim().length) {
        currentCustomer.customerLeadSource = currentCustomer.customerLeadSource.split(',');
    }

    return {
        type: type.SET_CURRENT_CUSTOMER,
        currentCustomer,
    }
}

export const setCarriers = (carriers) => ({
    type: type.SET_CARRIER,
    carriers,
})

export const setLoader = (loading) => ({
    type: type.SET_LOADER,
    loading
})

export const setNotes = (notes, totalNotesCount) => ({
    type: type.SET_NOTES,
    notes,
    totalNotesCount
})

export const setInitialCarrier = (initialCarrier) => ({
    type: type.SET_INITIAL_CARRIER,
    initialCarrier
})

export const setTasks = (tasks, totalTaskCount, append) => ({
    type: type.SET_TASKS,
    tasks,
    totalTaskCount,
    append
});

export const setAgents = (agents) => ({
    type: type.SET_AGENTS,
    agents
});

export const setContactLogs = (contactLogs, contactLogCount) => ({
    type: type.SET_CONTACTLOGS,
    contactLogs,
    contactLogCount
})

export const setAllContactLogs = (allContactLogs) => ({
    type: type.SET_ALL_CONTACTLOGS,
    allContactLogs
})

export const setCustomerStoreCreditBalance = (customerStoreCreditBalance) => ({
    type: type.SET_CUSTOMER_STORE_CREDIT_BALANCE,
    customerStoreCreditBalance
})

export const setStoreCreditLogs = (storeCreditLogs) => ({
    type: type.SET_STORE_CREDIT_LOGS,
    storeCreditLogs
})

export const setOnlineCustomers = (onlineCustomers, totalOnline) => ({
    type: type.SET_ONLINE_CUSTOMER,
    onlineCustomers,
    totalOnline
});

export const setCustomerEmailTemplateData = (emailTemplateData) => ({
    type: type.SET_CUSTOMER_EMAIL_TEMPLATE_DATA,
    emailTemplateData,
})

export const setCustomerRewardsPointsBalance = (rewardPoints) => ({
    type: type.SET_CUSTOMER_REWARDS_POINTS_BALANCE,
    rewardPoints
})
