import axios from 'axios';
import getApiUrl from '@helpers/apiUrls';
import {authHeader} from '@helpers/authHeader';
import * as commonActions from '@store/common/actions';
import utilCommon from '@helpers/utilCommon';
import lodash from "lodash";
import {setLocationUpdates, setOrderStatuses, setScheduleAlerts, setTripEvents} from "@store/shipment/actions";
import * as commonAction from "@store/common/actions";
import {getTLShipment} from "@services/shipmentService";

export const getTlTrackingData = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};

    let orderStatusesUrl = getApiUrl('shipment', 'getTlTrackingOrderStatuses');
    orderStatusesUrl += '?' + utilCommon.convertObjectToQueryString(inputDTO);
    let tripEventsUrl = getApiUrl('shipment', 'getTlTrackingTripEvents');
    tripEventsUrl += '?' + utilCommon.convertObjectToQueryString(inputDTO);
    let locationUpdatesUrl = getApiUrl('shipment', 'getTlTrackingLocationUpdates');
    locationUpdatesUrl += '?' + utilCommon.convertObjectToQueryString(inputDTO);
    let scheduleAlertsUrl = getApiUrl('shipment', 'getTlTrackingScheduleAlerts');
    scheduleAlertsUrl += '?' + utilCommon.convertObjectToQueryString(inputDTO);

    dispatch(commonActions.setLoader(true));
    const orderStatusesRequest = axios.get(orderStatusesUrl, params);
    const tripEventsRequest = axios.get(tripEventsUrl, params);
    const locationUpdatesRequest = axios.get(locationUpdatesUrl, params);
    const scheduleAlertsRequest = axios.get(scheduleAlertsUrl, params);
    axios.all([orderStatusesRequest, tripEventsRequest, locationUpdatesRequest, scheduleAlertsRequest])
        .then(axios.spread((...responses) => {
            dispatch(commonActions.setLoader(false))
            const orderStatusesResponse = lodash.get(responses, '0',{});
            const tripEventsResponse = lodash.get(responses, '1',{});
            const locationUpdatesResponse = lodash.get(responses, '2',{});
            const scheduleAlertsResponse = lodash.get(responses, '3',{});
            if (orderStatusesResponse.data.success) {
                dispatch(setOrderStatuses(orderStatusesResponse.data.data));
            }

            if (tripEventsResponse.data.success) {
                dispatch(setTripEvents(tripEventsResponse.data.data));
            }

            if (locationUpdatesResponse.data.success) {
                dispatch(setLocationUpdates(locationUpdatesResponse.data.data));
            }

            if (scheduleAlertsResponse.data.success) {
                dispatch(setScheduleAlerts(scheduleAlertsResponse.data.data));
            }
        }))
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
        });
};

export const bookTLShipment = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('shipment', 'bookTlShipment');
    dispatch(commonAction.setLoader(true));
    return axios.post(url, {shipmentUuid: inputDTO.shipmentUuid}, params)
        .then((response) => {
            dispatch(commonAction.setLoader(false))
            if (response.data.success) {
                getTLShipment(dispatch, lang, dictionary, inputDTO.shipmentUuid);
                utilCommon.showNotification(lodash.get(response, 'data.data.message', 'Success'), dictionary, lang);
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
            return response;
        })
        .catch((err) => {
            utilCommon.showNotification(lodash.get(err, 'response.data.message', 'Internal server error'), dictionary, lang, true)
            dispatch(commonAction.setLoader(false))
        });
};

export default {
    getTlTrackingData,
    bookTLShipment
}
