export const SET_BA_QUEUE = 'accounting/SET_BA_QUEUE';
export const SET_CUSTOMERS = 'accounting/SET_CUSTOMERS';
export const SET_CARRIERS = 'accounting/SET_CARRIERS';
export const SET_ACTIVE_AGENTS = 'accounting/SET_ACTIVE_AGENTS';
export const SET_INPROGRESS_BA_QUEUE = 'accounting/SET_INPROGRESS_BA_QUEUE';
export const SET_PROCESSED_BA_QUEUE = 'accounting/SET_PROCESSED_BA_QUEUE';
export const SET_CARRIER_DISPUTES = 'accounting/SET_CARRIER_DISPUTES'
export const SET_SHIPMENT_WITHOUT_CARRIER_BILL = 'accounting/SET_SHIPMENT_WITHOUT_CARRIER_BILL'
export const SET_NOTES = 'accounting/SET_NOTES'
export const SET_CARRIER_BILL_DETAILS = 'accounting/SET_CARRIER_BILL_DETAILS'
export const SET_BA_REVIEW_DETAILS = 'accounting/SET_BA_REVIEW_DETAILS'
export const SET_BA_DOCUMENTS = 'accounting/SET_BA_DOCUMENTS'
export const SET_RESET_DOCUMENT_FLAG = 'accounting/SET_RESET_DOCUMENT_FLAG';
export const SET_LOADER = 'accounting/SET_LOADER';
export const SET_INVOICE_DATA = 'accounting/SET_INVOICE_DATA'
