import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Grid, MenuItem, MenuList, withStyles} from '@material-ui/core';
import loginLogoImage from '../../../assets/images/headerLogo.jpg';
import UserImage from '../../../assets/images/Userpic.png';
import utilCommon, {buildQueryString} from '../../../helpers/utilCommon';
import MenuIcon from '@material-ui/icons/Menu';
import Input from '@material-ui/core/Input';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import * as types from '../../../store/common/actionTypes';
import * as commonActions from '../../../store/common/actions'
import CustomerAdvanceSearchForm from '../../forms/customerAdvancedSearchForm';
import ShipmentAdvanceSearchForm from '../../forms/shipmentAdvancedSearchForm';
import customerService from '../../../services/api/customerService';
import shipmentService from '../../../services/api/shipmentService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DebugModal from "../../../components/organisms/DebugModal";
import Marquee from "react-double-marquee/dist/bundle.esm";
import userManagementService from "@services/userManagementService";
import carrierService from "@services/carrierService";

const styles = theme => ({
    root: { display: 'flex', },
    paper: { marginRight: theme.spacing.unit * 2, },
    popperClose: { pointerEvents: 'none', },
    nested: { paddingLeft: theme.spacing.unit * 4, },
});

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            userMenuOpen: false,
            accountMenuOpen: false,
            listopen: true,
            searchFilterOpen: false,
            searchFilter: 'Customers',
            searchVal: '',
            openAdvanceSearch: false,
            isOpen:0,
            loginMenu:false,
            userRoleMenu:false,
            consoleButton: false
        };

        this.searchRef = React.createRef();
    }

    componentWillReceiveProps(nextProps, props) {
        if (nextProps != props) {
            if (nextProps.deleteKeyWord) {
                if (document.getElementById('SearchKeys').value) {
                    document.getElementById('SearchKeys').value = ''
                }
                this.props.setKeywordFlag()
            }
        }
    }

    LoginMenuToggle = () => {
        this.setState(state => ({
            loginMenu: !this.state.loginMenu
        }));
    };
    onBlurloginMenu = () => {
        if(this.state.loginMenu == true){
            this.setState(state => ({ loginMenu: false }));
        }
    }

    userRoleToggle = () => {
        this.setState(state => ({
            userRoleMenu: !this.state.userRoleMenu
        }));
    };
    onBluruserRoleMenu = () => {
        if(this.state.userRoleMenu == true){
            this.setState(state => ({ userRoleMenu: false }));
        }
    }

    handleToggle = () => {
        this.setState(state => ({ userMenuOpen: !this.state.userMenuOpen }));
    };

    openSearchFilter = () => {
        this.setState({ searchFilterOpen: !this.state.searchFilterOpen })
    }

    setSearchFilter = (filter) => {
        this.setState({ searchFilter: filter })
        this.setState({ searchFilterOpen: false })
    }

    closeSearch = () => {
        this.setState({ searchFilterOpen: false })
        this.setState({ accountMenuOpen: false });
    }

    handleClose = event => {
        this.setState({ userMenuOpen: false });
    };

    handleAccountMenuToggle = () => {
        this.setState(state => ({ accountMenuOpen: !this.state.accountMenuOpen }));
    };

    handleAccountMenuClose = event => {
        this.setState({ accountMenuOpen: false });
    };

    handleClick = () => {
        this.setState(state => ({ listopen: !state.listopen }));
    };

    searchKeyword = (keyword) => {
        return keyword.replace(/\(?(\d{3})[\)\.-]?\s?(\d{3})[-\.\s]?(\d{4})/gi, (match, p1, p2, p3, offset, string) => {
            return [p1, p2, p3].join('');
        });
    };

    updateSearchKeyWord = (e, flag = false) => {
        const searchValue = this.searchRef.current.value;
        if (e.keyCode === 13 || flag === true) {
            if (searchValue.length > 2 || searchValue.length === 0) {
                if (this.state.searchFilter === 'Users') {
                    this.props.history.push('/home/user-management')
                    this.props.getUsers(this.props.currentLanguage, this.props.dictionaries, {
                        pageSize: 50,
                        pageNumber: 1,
                        keyword: searchValue
                    })
                } else if (this.state.searchFilter === 'Customers') {
                    let keyword = this.searchKeyword(searchValue);
                    this.props.history.push('/home/customer-management')

                    let payload = {
                        pageSize: 50,
                        pageNumber: 1,
                        sortOrder: 'desc',
                        sortColumn: 'registrationDate',
                        keyword: keyword,
                        advancedSearch: true
                    }

                    if (!utilCommon.isPermitted(2, 84, 1) && keyword === '') {
                        payload = {
                            ...payload,
                            agentUserUuid: [utilCommon.getSession('userUuid')]
                        }
                    }

                    this.props.getCustomers(this.props.currentLanguage, this.props.dictionaries, payload)
                } else if (this.state.searchFilter === 'Carriers') {
                    this.props.history.push('/home/carrier-management')
                    this.props.getCarriers(this.props.currentLanguage, this.props.dictionaries, {
                        pageSize: 50,
                        pageNumber: 1,
                        sortOrder: 'desc',
                        sortColumn: 'carrierName',
                        keyword: searchValue
                    })
                } else if (this.state.searchFilter === 'Shipments') {
                    let params = {
                        pageSize: 50,
                        pageNumber: 1,
                        sortOrder: 'desc',
                        sortColumn: 'shipmentUuid',
                        keyword: searchValue
                    };

                    if (!utilCommon.isPermitted(4, 85, 1) && searchValue === '') {
                        params = {
                            ...params,
                            agentUuid: [utilCommon.getSession('userUuid')]
                        };
                    }

                    this.props.history.push(`/home/shipment-management?${buildQueryString(params)}`)
                }

                utilCommon.setSession('redirect', 'redirect')
                this.searchRef.current.value = ''
            }
        }
    }

    customerAdvanceSearch = (values) => {
        utilCommon.setSession('redirect', 'redirect')
        this.props.history.push('/home/customer-management')

        let payload = {
            pageSize: 50,
            pageNumber: 1,
            advancedSearch: true,
        }

        if (!utilCommon.isPermitted(2, 84, 1)) {
            payload = {
                agentUserUuid: [utilCommon.getSession('userUuid')],
                ...payload
            }
        }

        if (Object.keys(values).length === 0) {
            payload = {
                sortOrder: 'desc',
                sortColumn: 'registrationDate',
                ...payload
            }
        } else {
            payload = {
                ...values,
                ...payload
            }
        }

        this.props.getCustomers(this.props.currentLanguage, this.props.dictionaries, payload)
        this.setState({openAdvanceSearch: false})
    }

    // shipment advanced search
    shipmentAdvanceSearch = (values) => {
        this.props.clearFilter()

        let params = !utilCommon.isPermitted(4, 85, 1) ? {agentUuid: [utilCommon.getSession('userUuid')]} : {};

        if (Object.keys(values).length !== 0) {
            params = {
                ...params,
                ...values
            }
        }

        this.props.history.push(`/home/shipment-management?${buildQueryString(params)}`)
        this.handleOpen(0,true)
    }

    handleOpen = (index,flag)=>{
        if (index==0 && !flag && this.state.isOpen==1) {
            utilCommon.setSession('redirect', 'redirect')

            let payload = {
                pageSize: 50,
                pageNumber: 1,
                sortOrder: 'desc',
                sortColumn: 'shipmentUuid'
            }

            if (!utilCommon.isPermitted(4, 85, 1)) {
                payload = {
                    ...payload,
                    agentUuid: [utilCommon.getSession('userUuid')]
                }
            }

            this.props.getShipments(this.props.currentLanguage, this.props.dictionaries, payload)
        }

        this.setState({isOpen:index})
    }

    localStorageUpdated = () => {
        this.updateState(localStorage.getItem('debug') === "true")
    }
    updateState = (value) => {
        this.setState({consoleButton:value})
    }

    componentDidMount(){
        if (typeof window !== 'undefined') {

            this.localStorageUpdated();
            window.addEventListener('storage', this.localStorageUpdated)
            window.addEventListener('scroll', this.handleScrollWindow);
        }
    }
    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.handleScrollWindow);
            window.removeEventListener('storage', this.localStorageUpdated)
        }
    }
    handleScrollWindow =(e)=>{
        if(this.state.posY > window.pageYOffset){
            document.getElementById("topbar").classList.add('fixedtopbar')
            document.getElementById("topbar").style.top = "0px"
        } else {
            document.getElementById("topbar").classList.remove('fixedtopbar')

            document.getElementById("topbar").classList.add('fixedtopbarTop54')
           // document.getElementById("topbar").style.top = "-54px"
            //document.getElementById("topbar").style.position = "fixed"
        }
        if(window.pageYOffset< 4){
            document.getElementById("topbar").classList.remove('fixedtopbar');
            document.getElementById("topbar").classList.remove('fixedtopbarTop54')
            //document.getElementById("topbar").style.top = "0px"
            //document.getElementById("topbar").style.position = "relative"
        }
        this.setState({
            posY:window.pageYOffset
        })
    }

    getIconClass(item) {
      switch (item) {
        case 'Users': return 'zmdi zmdi-accounts-alt ';
        case 'Carriers': return 'zmdi zmdi-truck ';
        case 'Customers': return 'zmdi zmdi-account ';
        case 'Shipments': return 'zmdi zmdi-archive ';
      }
    }

    getTextColor(id) {
        switch (id) {
            case 1: return 'black';
            case 2: return 'white';
        }

        return 'black';
    }

    getBackgroundColor(id) {
        switch (id) {
            case 0: return '#e7e7e7';
            case 1: return '#ff6868';
            case 2: return '#f8e71c';
            case 3: return '#96b56f';
            case 4: return '#0093d0';
        }

        return '#e7e7e7';
    }

    render() {
        const userSettingPaths = ['/home/user-management', '/home/teams', '/home/security-levels', '/home/security-roles'];
        const shipmentsPaths = ['/home/shipment-management', '/home/tl-shipment-management', '/home/quote'];
        const carrierSettingPaths = ['/home/carrier-management', '/home/accessorials', '/home/transit-time', '/home/facility-type', '/home/fuel-surcharge'];
        this.props.history.listen((location, action) => {
            if (location.pathname === '/home/customer-management') {
                this.setState({ searchFilter: 'Customers' })
            } else if (userSettingPaths.includes(location.pathname)) {
                this.setState({ searchFilter: 'Users' })
            } else if (carrierSettingPaths.includes(location.pathname)) {
                this.setState({ searchFilter: 'Carriers' })
            } else if (shipmentsPaths.includes(location.pathname)) {
                this.setState({ searchFilter: 'Shipments' })
            } else if (location.pathname === '/home/add-customer-management') {
                this.setState({ searchFilter: 'Customers' })
            } else if (location.pathname.includes('/home/customer-management')) {
                this.setState({ searchFilter: 'Customers' })
            } else {
                // this.setState({ searchFilter: 'Carriers' })
                this.setState({ searchFilter: 'Customers' })
            }
        })
        const { open, accountMenuOpen, openAdvanceSearch , consoleButton } = this.state;

        const textColor = this.props.status ?
            this.getTextColor(this.props.textColor) :
            this.getTextColor(1);

        const backgroundColor = this.props.status ?
            this.getBackgroundColor(this.props.backgroundColor) :
            this.getBackgroundColor(0);

        return (
            <header onClick={this.props.toggleNav} id="topbar" className={this.props.status == true ? "topbar flmessage":"topbar"}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                >
                    <Grid item>
                        <div className="logoSection">
                            <a className="navbarLogo" onClick={(e)=>{e.preventDefault(); this.props.history.push('/home')}} href="/home">
                                <img src={loginLogoImage} alt="FreightCenter" title="FreightCenter" />
                            </a>
                            <div className="menuIconWrap">
                                <Button onClick={this.props.toggleSideNav} className="menuIcon">
                                    {
                                        !this.props.sideNavOpen ? <MenuIcon /> : <i className="zmdi zmdi-close"/>
                                    }

                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="userRole" onClick={this.userRoleToggle.bind(this)}  onMouseLeave={this.onBluruserRoleMenu.bind(this)}>
                            <Button
                                aria-owns={null}
                                aria-haspopup="true"
                            >
                                <span className="userIcon">
                                    <i className={this.getIconClass(this.state.searchFilter)}/>
                                </span>
                                <span className="userText">
                                    {this.state.searchFilter}
                                </span>
                                <span className="dropdownIcon">
                                    <i className="arrow-down"/>
                                </span>
                            </Button>
                            {this.state.userRoleMenu && <div className="popup header">
                                <MenuList role="menu">
                                    <MenuItem onClick={() => { this.setSearchFilter('Customers') }}><i className="zmdi zmdi-account "/>&nbsp; Customers</MenuItem>
                                    <MenuItem onClick={() => { this.setSearchFilter('Shipments') }}><i className="zmdi zmdi-archive "/>&nbsp; Shipments</MenuItem>
                                    <MenuItem onClick={() => { this.setSearchFilter('Users') }}><i className="zmdi zmdi-accounts-alt "/>&nbsp; Users</MenuItem>
                                    <MenuItem onClick={() => { this.setSearchFilter('Carriers') }}><i className="zmdi zmdi-truck "/>&nbsp; Carriers</MenuItem>
                                </MenuList>
                            </div>}
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="topSearch">
                            <Input
                                placeholder="Type any customer name, Id etc…"
                                className="form-inputPlaceholder"
                                aria-owns={open ? 'menu-list-grow' : null}
                                aria-haspopup="true"
                                id="SearchKeys"
                                inputRef={this.searchRef}
                                onKeyUp={this.updateSearchKeyWord}
                            />
                            <div className="btnsrc">
                                <Button onClick={(e) => this.updateSearchKeyWord(e, true)} >
                                    <i className="zmdi zmdi-search "/>
                                </Button>
                                {
                                    this.state.searchFilter === 'Customers' && <Button onClick={() => { this.setState({ openAdvanceSearch: true }) }} >
                                        <i className="zmdi zmdi-caret-down"/>
                                    </Button>
                                }
                                {
                                    this.state.searchFilter === 'Shipments' && <Button onClick={() => this.handleOpen(1)} >
                                        <i className="zmdi zmdi-caret-down"/>
                                    </Button>
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="notification" style={{backgroundColor: backgroundColor}}>
                            <a
                                className={this.props.status ? "notificationIcon active" : "notificationIcon"}
                                style={{color: textColor}}
                            >
                                <i className="zmdi zmdi-notifications"/>
                            </a>
                            {
                                this.props.status &&
                                <div className="notificationMarquee" style={{color: textColor}}>
                                    <Marquee
                                        direction="left"
                                        speed={0.06}
                                        delay={2000}
                                        scrollWhen="overflow"
                                    >
                                        {this.props.name}
                                    </Marquee>
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid item md>
                        <div className="topBar-userProfile">
                            <div className="loggedinUser">
                                <span>Logged in as</span>
                                <span className="userName">{utilCommon.getSession('username')}</span>
                            </div>
                            <div className="userProfile" onClick={this.LoginMenuToggle.bind(this)}  onMouseLeave={this.onBlurloginMenu.bind(this)}>
                                <Button
                                    aria-owns={accountMenuOpen ? 'menu-list-grow' : null}
                                    aria-haspopup="true"
                                >
                                    <Avatar alt="user picture" src={UserImage} className="userIcon" />
                                    <span className="dropdownIcon">
                                        <i className="arrow-down"/>
                                    </span>
                                </Button>
                                {this.state.loginMenu && <div className="popup header">
                                    <MenuList role="menu">
                                        <MenuItem onClick={this.handleAccountMenuClose}><Link className="forgot-psw" to="/home/my-account"><i className="zmdi zmdi-account"/>&nbsp;  My Account </Link></MenuItem>
                                        <MenuItem onClick={this.props.handleLogout}><i className="zmdi zmdi-power"/>&nbsp;Log Out</MenuItem>
                                        { consoleButton && <MenuItem onClick={this.props.handleConsole}><i className="zmdi zmdi-bug"/>&nbsp;Console</MenuItem> }
                                    </MenuList>
                                </div>}
                            </div>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="notificationMob" style={{backgroundColor: backgroundColor}}>
                            {
                                this.props.status &&
                                <div className="notificationMarquee" style={{color: textColor}}>
                                    <Marquee
                                        direction="left"
                                        speed={0.06}
                                        delay={2000}
                                        scrollWhen="overflow"
                                    >
                                        {this.props.name}
                                    </Marquee>
                                </div>
                            }
                        </div>
                    </Grid>
                <CustomerAdvanceSearchForm onSubmit={this.customerAdvanceSearch} open={openAdvanceSearch}  handleClose={() => { this.customerAdvanceSearch({}) }} />
                <ShipmentAdvanceSearchForm onSubmit={this.shipmentAdvanceSearch} open={this.state.isOpen==1} handleOpen={this.handleOpen} handleClose={() => { this.handleOpen(0) }} />
                <DebugModal />
                </Grid>
            </header>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    deleteKeyWord: state.common.deleteKeyWord,
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    name : state.applicationSettings.systemWideNotification ? state.applicationSettings.systemWideNotification.name:'',
    status: state.applicationSettings.systemWideNotification ?  state.applicationSettings.systemWideNotification.status !== '0' : false,
    textColor: state.applicationSettings.systemWideNotification ? state.applicationSettings.systemWideNotification.textColor : 2,
    backgroundColor: state.applicationSettings.systemWideNotification ? state.applicationSettings.systemWideNotification.backgroundColor : 1,
});

const mapDispatchToProps = dispatch => ({
    setKeywordFlag: () => {
        dispatch({ type: types.DELETE_KEYWORD, deleteKeyWord: false })
    },
    handleConsole: () => {
        dispatch(commonActions.showConsole());
        // dispatch({ type: types.DELETE_KEYWORD, deleteKeyWord: false })
    },
    getCustomers: (currentLanguage, dictionaries, inputDTO) => {
        customerService.getCustomers(dispatch, currentLanguage, dictionaries, inputDTO)
    },
    getShipments: (currentLanguage, dictionaries, inputDTO) => {
        shipmentService.getShipments(dispatch, currentLanguage, dictionaries, inputDTO)
    },
    getUsers: (currentLanguage, dictionaries, inputDTO) => {
        userManagementService.getUsers(dispatch, currentLanguage, dictionaries, inputDTO)
    },
    getCarriers: (currentLanguage, dictionaries, inputDTO) => {
        carrierService.getCarrriers(dispatch, currentLanguage, dictionaries, inputDTO)
    },
    clearFilter:()=>{
        dispatch(reset('FilterShipmentForm'))
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header))
