import React from 'react';
import TextField from '@material-ui/core/TextField';
import utilCommon from '../../../helpers/utilCommon';
import moment from 'moment';


const DatePicker = ({ label, input, disabled, requiredClass, excludeWeekends, updateDeliveryDate, meta: { touched, error }, dateType = 'date' }) => (
    <div>
        <div>
            <TextField
                disabled={disabled}
                label={label}
                value={input.value}
                readOnly={true}
                onChange={(e) => {
                    const date = moment(e.target.value)
                    if (excludeWeekends) {
                        switch (date.day()) {
                            case 0:
                                date.add(1, 'd');
                                break;
                            case 6:
                                date.add(2, 'd');
                                break;
                        }
                    }
                    e.target.value = (dateType === 'month' ? date.format('YYYY-MM') : date.format('YYYY-MM-DD'));
                    if (typeof updateDeliveryDate === 'function') updateDeliveryDate(e)
                    input.onChange(e)
                }}
                className={utilCommon.getInputClasses(requiredClass, touched, error, disabled)}
                type={dateType}
                InputLabelProps={{ shrink: true }}
            />
        </div>
        {!disabled && error && error.length < 25 && touched && (<span className="invalidTxt">
            {error}
        </span>
        )}
        {!disabled && error && error.length > 25 && (<span className="invalidTxt">
            {error}
        </span>
        )}
        {!disabled && error && error.length === 23 && (<span className="invalidTxt">
            {error}
        </span>
        )}
    </div>
);

export default DatePicker;
