import React from "react";
import { reduxForm, Field } from 'redux-form';
import { connect } from "react-redux";
import CustomInputField from '../atoms/CustomInputField'
import utilCommon from '../../helpers/utilCommon'
import Button from '@material-ui/core/Button';

const validate = formProps => {
    const errors = {}
    if (!formProps.email) {
        errors.email = 'Please enter your email address.';
    } else if (!utilCommon.Email_Regex.test(formProps.email)) {
        errors.email = 'Please enter a valid email address.';
    }
    return errors
}

export class ForgotPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <form name="ForgotPasswordForm" onSubmit={handleSubmit}>
                <div className="form-group text-center row">
                    <Field label="Email Address" name="email" autoFocus type='email' component={CustomInputField} />
                </div>
                <div className="form-group txtCenter row btnWrap">
                    <Button type="submit" disabled={submitting || this.props.loading} variant="contained" color="primary" className="btnPrimary">
                        OK
                    </Button>
                </div>
            </form>
        )
    }
}

export default connect((state) => ({
    loading: state.common.loading,
}), {})(reduxForm({
    form: 'ForgotPasswordForm',
    validate,
})(ForgotPasswordForm));
