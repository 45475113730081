// @flow
// /file/{fileId}

export const SET_LEADSETTINGS_PARAMS = 'leads/SET_LEADSETTINGS_PARAMS';
export const SET_LEADSETTINGS = 'leads/SET_LEADSETTINGS';
export const SET_ALL_LEADS = 'leads/SET_ALL_LEADS';
export const SET_LEADS_STATUS_MASTER = 'leads/SET_LEADS_STATUS_MASTER';
export const SET_LEADS_MANAGEMENT_REVIEW_REASON_MASTER = 'leads/SET_LEADS_MANAGEMENT_REVIEW_REASON_MASTER';
export const SET_LEADS_DETAILS = 'leads/SET_LEADS_DETAILS';

export const SET_ADMIN_LEADS = 'leads/SET_ADMIN_LEADS';
export const SET_LEAD_AGENTS = 'leads/SET_LEAD_AGENTS';
export const SET_LEADS_CONTACTED = 'leads/SET_LEADS_CONTACTED';
export const SET_STATUS_FLAG = 'leads/SET_STATUS_FLAG';
export const SET_ADMIN_LEAD_QUOTA = 'leads/SET_ADMIN_LEAD_QUOTA'

export const SET_ONLINE_USER = 'leads/SET_ONLINE_USER'
export const SET_AGENT_LEAD_MODE = 'leads/SET_AGENT_LEAD_MODE'
export const SET_LOADER = 'leads/SET_LOADER';
