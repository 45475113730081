import * as type from './actionTypes';

export const setCarriers = (carriers, totalCount) => ({
  type: type.SET_CARRIERS,
  carriers,
  totalCount
});

export const setContracts = (contracts) => ({
  type: type.SET_CONTRACTS,
  contracts
});

export const setCurrentCarrier = (carrier) => ({
  type: type.SET_CARRIER,
  carrier
});