import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomCheckBox = ({
  input, checkThis ,disabled,indeterminate, label, meta: { touched, error, warning },
}) => (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={input.value}
                        indeterminate={indeterminate}
                    />
                }
                label={label}
                disabled={disabled}
                value={input.value}
                onBlur={(e) => { input.onBlur(e) }}
                onChange={(e) => { input.onChange(e) }}
                className={input.value == true && indeterminate ? "checkBoxWrap checked":"checkBoxWrap"}
            />
            {/* {touched && ( input.value === "" && (<span className="invalidTxt">
                Required
            </span>
            ))} */}
        </div>

    );
export default CustomCheckBox;
