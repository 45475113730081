import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const getClasses = (requiredClass, touched, error, disabled) => {
    if (disabled) {
        return 'form-inputFieldrdonly'
    } else {
        if (touched && error) {
            if (requiredClass)
                return 'form-inputField form-inputFieldError form-inputFieldImp'
            return 'form-inputField form-inputFieldError'
        } else {
            if (requiredClass)
                return 'form-inputField form-inputFieldImp'
            return 'form-inputField'
        }
    }
}


const getOptions = (options, value) => {
    return options.filter(x => (x.id && (x.id).toString().startsWith(value.toLowerCase()) || (x.name && x.name.toLowerCase().startsWith(value.toLowerCase()))))
}

const isSelected = (options, value) => {
    return options.filter(x => (x.id && x.id == value.toLowerCase()) || (x.name && x.name.toLowerCase() == value.toLowerCase())).length > 0
}


const Adornment = (value) => {
    return <InputAdornment position="end">Kg</InputAdornment>
}

function checkAdornment(handleClickShowPassword, handleMouseDownPassword, showPassword, adornment, position, adornmentValue) {
    if (showPassword !== undefined) {
        return <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
        </InputAdornment>;
    } else {
        return adornment ? <InputAdornment className={position == 'start' ? "start-Adornmen" : "end-Adornmen"}
                                           position={position}>{adornmentValue}</InputAdornment> : ''
    }
}

function filterChars(value) {
    let result = '';

    for (let i = 0; i < value.length; i++) {
        let c = value.charCodeAt(i);

        if ((c >= 32 && c <= 127) || c === 10) {
            result += String.fromCharCode(c);
        }
    }

    return result;
}

const renderField = ({
                         input,
                         defaultValue,
                         type,
                         tableSelect,
                         label,
                         options,
                         autoSuggest,
                         spellcheck,
                         placeholder,
                         fileType,
                         disabled,
                         multiline,
                         maxLength,
                         requiredClass,
                         checkBoxError,
                         adornment,
                         position,
                         adornmentValue,
                         meta: {touched, error},
                         handleClickShowPassword,
                         handleMouseDownPassword,
                         showPassword,
                         whiteField,
                         readOnly = false,
                         autoComplete,
                         ...custom
                     }) => (
    <div>
        {
            !disabled && <div className={whiteField ? "white-field" : ""}>
                <TextField
                    multiline={multiline}
                    label={label}
                    className={getClasses(requiredClass, touched, error, disabled)}
                    margin="normal"
                    hinttext={label}
                    defaultValue={defaultValue}
                    type={type}
                    inputProps={{
                        maxLength: maxLength ? maxLength : 255,
                        spellCheck: spellcheck,
                        autoComplete: autoComplete
                    }}
                    InputProps={{
                        [`${(position ? position : 'end')}Adornment`]: checkAdornment(handleClickShowPassword, handleMouseDownPassword, showPassword, adornment, position, adornmentValue),
                        onChange: (e) => {
                            e.target.value = filterChars(e.target.value);
                            input.onChange(e);
                        },
                        onFocus: (e) => {
                            if (defaultValue) {
                                input.onChange(e);
                            }
                        },
                        readOnly
                    }}
                    disabled={disabled}
                    floatinglabeltext={label}
                    {...input}
                    {...custom}
                />

                {autoSuggest && !isSelected(options, input.value) && autoSuggest && input.value.length > 0 && getOptions(options, input.value).length > 0 &&
                    <ul className="listItem-custom">
                        {autoSuggest && input.value.length > 0 && getOptions(options, input.value).map(option => (
                            <li
                                key={option.name}
                                value={option.id}
                                onClick={() => {
                                    input.onChange(option.name)
                                }}
                            >
                                {option.name}
                            </li>
                        ))}
                    </ul>}

            </div>
        }
        {
            disabled && <div className="inputField">
                <div className="label">{label}</div>
                <div className="labelData">{adornment && position == 'start' ?
                    <span className="adornmentSign">{adornmentValue}</span> : ''} <span
                    className="adornmentValue multiline">{input.value}</span> {input.value && adornment && position == 'end' ? ` ${adornmentValue}` : ''}
                </div>
            </div>
        }

        {!disabled && !autoSuggest && touched && error && !tableSelect && (<span className="invalidTxt">
                {error == ' is a required field' ? label + ' is a required field' : error}
            </span>
        )}

        {!disabled && !autoSuggest && error && tableSelect && (<span className="invalidTxt">
                {error == ' is a required field' ? label + ' is a required field' : error}
            </span>
        )}
        {/* show checkbox error in inoutField */}
        {disabled && checkBoxError && touched && error && (<span className="invalidTxt">
                {error}
            </span>
        )}
        {disabled && checkBoxError && fileType && error && (<span className="invalidTxt">
                {error}
            </span>
        )}

        {!disabled && autoSuggest && touched && !isSelected(options, input.value) && (<span className="invalidTxt">
                {error == ' is a required field' ? label + ' is a required field' : error}
            </span>
        )}
    </div>
);
export default renderField;
