
import React from "react";
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import loginService from '../../../services/api/loginService'
import utilCommon from '../../../helpers/utilCommon'
import ResetPasswordForm from '../../forms/resetPasswordForm'
import Footer from '../../atoms/Footer'
import Loader from '../../atoms/Loader'
import loginLogoImage from '../../../assets/images/login-logo.png'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
    this.handleResetPassword = this.handleResetPassword.bind(this);

  }



  componentWillMount() {
    Window['history'] = this.props.history
  }

  handleResetPassword(values) {
    this.props.resetPassword({ emailId: values.email, activationCode: window.location.pathname.split('/')[2], password: values.password }, this.props.currentLanguage, this.props.dictionaries)
    this.setState({ loading: true })
  }

  render() {
    return (
      <div>
        <div className="loginwrapper">
          <div className="innWrapper">
            <div className="loginLogo">
              <a href="javascript:void()"><img src={loginLogoImage} alt="FreightCenter" title="FreightCenter" /></a>
            </div>
            <ResetPasswordForm onSubmit={this.handleResetPassword} />
          </div>
        </div>
        <Footer />
        {this.props.loading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentLanguage: state.i18nReducer.currentLanguage,
  dictionaries: state.i18nReducer.dictionaries,
  loading: state.common.loading
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (inputDTO, currentLanguage, dictionaries) => {
    loginService.resetPassword(dispatch, currentLanguage, dictionaries, inputDTO)
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

