import axios from 'axios';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon from '../../helpers/utilCommon'
import * as actions from '../../store/contract/actions'
import * as commonActions from '../../store/common/actions'
import * as type from '../../store/contract/actionTypes'

// Get accesorials
export const getAccesorialData = (dispatch, lang, dictionary, contractUuid) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('contract', 'getAccessorial') + '?contractUuid=' + contractUuid;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_ACCESSORIAL_DATA, accessorialData: response.data.content.contractAccessorialList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// Get Negotiated Lanes
export const getNegotiatedLane = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('contract', 'negotiatedLane') + '?contractUuid=' + inputDTO.contractUuid + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({
                    type: type.SET_NEGOTIATEDLANE, negotiatedLanes: response.data.content.negotiatedLaneList, totalCount: response.data.content.count
                })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// Get Liability Coverage
export const getLiabilityCoverages = (dispatch, lang, dictionary, contractUuid) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('contract', 'contractLiabilityCovrage') + '?pageSize=50&pageNumber=1&contractUuid=' + contractUuid
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_LIABILITYCOVERAGE, liabilityCoverageList: response.data.content.liabilityCoverageList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// Get Carriers
export const getCarriers = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('contract', 'getCarriers')
    getCustomers(dispatch, lang, dictionary)
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_CARRIERS, carriers: response.data.content.carrierList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        }).catch((error) => {

        })


    // Countries
    let url1 = getApiUrl('contract', 'getCountry');
    axios.get(url1, params)
        .then((response) => {
            if (response.data.success) {
                let data = response.data.content.salesTeamLists
                dispatch({ type: type.SET_COUNTRIES, countries: response.data.content.countryList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});

    // States
    let url2 = getApiUrl('contract', 'getState');
    axios.get(url2, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_STATES, states: response.data.content.stateList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {});
}


// Get Customers
export const getCustomers = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('contract', 'getCustomers')
    url = url + '?pageSize=100&pageNumber=1&sortColumn=customerName&sortOrder=asc&permFlag=1&qd=1';
    axios.get(url, params)
        .then((response) => {
            if (response.data.success) {
                dispatch({ type: type.SET_CUSTOMERS, customers: response.data.content.customerList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        }).catch((error) => {})
}


// Get Contract Details
export const getContractDetails = (dispatch, lang, dictionary, contractUuid) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('contract', 'generalInformation') + '?pageSize=50&pageNumber=1&contractUuid=' + contractUuid
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success && response.data.content.contractList.length > 0) {

                dispatch({ type: type.SET_CONTRACTINFO, contract: response.data.content.contractList[0] })
                getContractLogs (dispatch, lang, dictionary, contractUuid)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        }).catch((error) => {
            dispatch(commonActions.setLoader(false))
        })
}


// Add/Update General Info Contracts
export const addContractGeneralInfo = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'contractGeneralInfo');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.contractId ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.contractUuid) {
                    getContractDetails(dispatch, lang, dictionary, inputDTO.contractUuid)
                    utilCommon.showNotification('General information updated successfully', dictionary, lang)
                    if(inputDTO.contractUuid) {
                        getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                    }
                } else {
                    utilCommon.setSession('ContractId', response.data.content.responseData.contractUuid)
                    getContractDetails(dispatch, lang, dictionary, response.data.content.responseData.contractUuid)
                    utilCommon.showNotification('General information added successfully', dictionary, lang)
                }

            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// Update Bill of Lading
export const updateBillOfLading = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'contractBillOfLading');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                getContractDetails(dispatch, lang, dictionary, inputDTO.contractUuid)
                utilCommon.showNotification('Billing information updated successfully', dictionary, lang)
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};


// Update Profit Margin Discount
export const updateMarginDiscount = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'contractProfitMargin');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification('Margin and discount information updated successfully', dictionary, lang)
                getContractDetails(dispatch, lang, dictionary, inputDTO.contractUuid)
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }

                getAccesorialData(dispatch, lang, dictionary, inputDTO.contractUuid)
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};


// Update Fuel Surcharge
export const updateFuelSurcharge = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'contractFuelSurcharge');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification('Fuel surcharge information updated successfully', dictionary, lang)
                getContractDetails(dispatch, lang, dictionary, inputDTO.contractUuid)
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};


// Update Contract Specifics
export const updateContractSpecifics = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'contractContractSpecifics');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification('Contract specifics information updated successfully', dictionary, lang)
                getContractDetails(dispatch, lang, dictionary, inputDTO.contractUuid)
                getCommodityType(dispatch, lang, dictionary, inputDTO.contractUuid)
                getExcludedFreightClass(dispatch, lang, dictionary, inputDTO.contractUuid)
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};


// Update Liability Coverage
export const updateLiabilityCoverage = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'contractLiabilityCovrage') + `?contractUuid=${inputDTO.liabilityCoverages[0].contractUuid}`;
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                getLiabilityCoverages(dispatch, lang, dictionary, inputDTO.liabilityCoverages[0].contractUuid)
                utilCommon.showNotification('Liability coverage information updated successfully', dictionary, lang)
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};


// Add Accessorial
export const addAccessorial = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'accessorial') + '?contractUuid=' + inputDTO.contractUuid;
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Accessorial information updated successfully', dictionary, lang)
                getAccesorialData(dispatch, lang, dictionary, inputDTO.accessorials[0].contractUuid)
                if(inputDTO.accessorials[0].contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.accessorials[0].contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// Update Freight Classes
export const updateFreightClass = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'excludeFreightClass') + `?contractUuid=${inputDTO.excludeFreightClass[0].contractUuid}`;
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Freight class information updated successfully', dictionary, lang)
                getExcludedFreightClass(dispatch, lang, dictionary, inputDTO.excludeFreightClass[0].contractUuid)
                if(inputDTO.excludeFreightClass[0].contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.excludeFreightClass[0].contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};

// Add Negotiated Lane
export const addNegotiatedLanes = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('contract', 'negotiatedLane') + `?contractUuid=${inputDTO.negotiatedLanes[0].contractUuid}`;
    if (inputDTO.negotiatedGLanes && inputDTO.negotiatedGLanes.length && inputDTO.negotiatedGLanes[0].negotiatedLaneUuid ) {
      url += `&negotiatedLaneUuid=${inputDTO.negotiatedGLanes[0].negotiatedLaneUuid}`;
    }
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Negotiated lane information updated successfully', dictionary, lang)
                getNegotiatedLane(dispatch, lang, dictionary, { contractUuid: inputDTO.negotiatedLanes[0].contractUuid, pageSize: 50, pageNumber: 1 })
                getGeographicLane(dispatch, lang, dictionary, {
                    contractUuid: inputDTO.negotiatedLanes[0].contractUuid,
                    negotiatedLaneUuid: inputDTO.negotiatedGLanes[0].negotiatedLaneUuid ? inputDTO.negotiatedGLanes[0].negotiatedLaneUuid:response.data.content[0].negotiatedLaneUuid
                })
                if(inputDTO.negotiatedLanes[0].contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.negotiatedLanes[0].contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};


// Add Commodity type
export const addCommodityType = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'commodityType') + '?contractUuid=' + inputDTO.contractUuid;
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Included commodity type information updated successfully', dictionary, lang)
                getCommodityType(dispatch, lang, dictionary, inputDTO.commodityTypes[0].contractUuid)
                if(inputDTO.commodityTypes[0].contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.commodityTypes[0].contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};


// Get Commodity type
export const getCommodityType = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'commodityType') + '?contractUuid=' + inputDTO;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_COMMODITYTYPE, commodityTypes: response.data.content.commodityTypeList })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

// Get FreightClasses Data
export const getExcludedFreightClass = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'excludeFreightClass') + '?contractUuid=' + inputDTO
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_EXCLUDED_FREIGHT_CLASSES, excludedFreightClasses: response.data.content.contractExcludeFreightClass })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))

        });
};



// Get Geographic Lane
export const getGeographicLane = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'negotiatedGlane') + '?negotiatedLaneUuid=' + inputDTO.negotiatedLaneUuid
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_GEOGRAPHICLANE, geographicLane: response.data.content.negotiatedGLaneList })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// Delete Contract
export const deleteContract = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'generalInformation')
    dispatch(commonActions.setLoader(true))
    axios.patch(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Contract deleted successfully.')
                Window['history'].push('/home/carrier-management')
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


// Delete Contract
export const activateContract = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'generalInformation')
    dispatch(commonActions.setLoader(true))
    axios.patch(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getContractLogs = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'contractLogs');
    dispatch(commonActions.setLoader(true))
    dispatch({ type: type.SET_CONTRACT_LOGS, logs: [], totalResults: 0})
    axios.get(url + "?id=" + inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_CONTRACT_LOGS, logs: [], totalResults: 0 })
                dispatch({ type: type.SET_CONTRACT_LOGS, logs: response.data.content.activityLog, totalResults: response.data.content.totalResults })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const duplicateContract = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('contract', 'duplicateContract');
    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Contract duplicated successfully.', dictionary, lang, false)
                if(inputDTO.contractUuid) {
                    getContractLogs (dispatch, lang, dictionary, inputDTO.contractUuid)
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang, true)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
            utilCommon.showNotification(err, dictionary, lang, true)
        });
};

export default {
    getAccesorialData,
    getLiabilityCoverages,
    getCarriers,
    getCustomers,
    getContractDetails,
    addContractGeneralInfo,
    updateBillOfLading,
    updateMarginDiscount,
    updateFuelSurcharge,
    updateContractSpecifics,
    updateLiabilityCoverage,
    addAccessorial,
    updateFreightClass,
    getNegotiatedLane,
    addNegotiatedLanes,
    addCommodityType,
    getCommodityType,
    getGeographicLane,
    deleteContract,
    activateContract,
    getExcludedFreightClass,
    getContractLogs,
    duplicateContract,
};


