import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getOptions = (options,label) => {
  let result = [];
  if(!options){
    return []
  }
  if(options){
    options.map(x => {
      let opt = {
        text: '',
        value: ''
      }
      if (x.name && x.id) {
        opt = {
          text: x.name,
          value: x.id
        }
      } else {
        for (var property in x) {
          if (x.hasOwnProperty(property)) {
            if (isNaN(parseInt(x[property])) || x[property].length > 5 ) {
              if (opt.text.length == 0) {
                opt.text = x[property]
              }
            } else {
              if (opt.value == '') {
                opt.value = x[property]
              }
            }
          }
        }
      }
      result.push(opt)
    })
  }
  return result
}


const mapSelected = (selected) => {
  if (Array.isArray(selected)) {
    let value = []
    selected.map((x) => {
      if (x.value) {
        value.push(x.value)
      } else {
        value.push(x)
      }
    })

    return value
  } else {
    let value
    if (selected.value) {
      value = selected.value
    } else {
      value = selected
    }
    return value
  }
}

const renderSelectField = ({ label, tableSelect, input, multiple, options, readOnly, notEmpty, meta: { touched, error } }) => {
    return (
    <div>
      {
        !readOnly && <FormControl className={touched && error ? "formSelectBoxImp formControl form-inputFieldError" : "formSelectBoxImp formControl"} >
          <InputLabel className={touched && error ? 'invalidTxt' : ''} htmlFor="select-multiple">{label}</InputLabel>
          <Select
            multiple={multiple}
            readOnly={readOnly}
            value={notEmpty ? input.value : input.value || []}
            onChange={(e) => input.onChange(e)}
            input={<Input />}
            onBlur={input.onBlur}
            MenuProps={MenuProps}
            renderValue={selected => (
              options && options.length > 0 && <div>
                {Array.isArray(selected) ? mapSelected(selected).map((value, index) => <Chip
                        key={getOptions(options).filter(x => x.value == value)[0] ? getOptions(options).filter(x => x.value == value)[0].text : index}
                        label={getOptions(options).filter(x => x.value == value)[0] ? getOptions(options).filter(x => x.value == value)[0].text : ''}/>) :
                    <Chip
                        key={getOptions(options).filter(x => x.value == mapSelected(selected))[0] ? getOptions(options).filter(x => x.value == mapSelected(selected))[0].text : ''}
                        label={getOptions(options).filter(x => x.value == mapSelected(selected))[0] ? getOptions(options).filter(x => x.value == mapSelected(selected))[0].text : ''}/>
                }

              </div>
            )}
          >
            {getOptions(options,label).map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      {
        readOnly && <FormControl className="formSelectBoxRdonly formControl">
          <InputLabel htmlFor="select-multiple">{label}</InputLabel>
          <Select
            multiple={multiple}
            readOnly={readOnly}
            value={notEmpty ? input.value : input.value || []}
            onChange={(e) => input.onChange(e)}
            input={<Input />}
            MenuProps={MenuProps}
            renderValue={selected => (
              options && options.length > 0 && <div>
               {Array.isArray(selected) ? mapSelected(selected).map(value => <Chip key={getOptions(options).filter(x => x.value == value).length > 0 ? getOptions(options).filter(x => x.value == value)[0].text : ''} label={getOptions(options).filter(x => x.value == value).length > 0 ?  getOptions(options).filter(x => x.value == value)[0].text : ''} />) : <Chip key={getOptions(options).filter(x => x.value == mapSelected(selected)).length > 0 ?  getOptions(options).filter(x => x.value == mapSelected(selected))[0].text : ''} label={getOptions(options).filter(x => x.value == mapSelected(selected)).length > 0 ? getOptions(options).filter(x => x.value == mapSelected(selected))[0].text : ''} />}
              </div>
            )}
          >
            {getOptions(options,label).map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      }
      {!readOnly && touched && !tableSelect && (error && (<span className="invalidTxt">
        {error == ' is a required field' ? label + ' is a required field' : error}
      </span>))}
      {!readOnly &&  tableSelect && (error && (<span className="invalidTxt">
      {error == ' is a required field' ? label + ' is a required field' : error}
      </span>))}
    </div>
)};
export default renderSelectField;
