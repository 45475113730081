import React from "react";
import { connect } from "react-redux";
import customerService from "../../../services/api/customerService";
import shipmentService from "../../../services/api/shipmentService";
import Loader from '../../atoms/Loader'
import CustomerLogList from './../CustomerLogList'
import LogsList from './../LogsList'

class Logs extends React.Component<any,any> {
    constructor(props) {
        super(props);
        this.state = {
            isCustomerPage:false
        };
       
    }

    componentWillMount(){
        if(this.props.location.pathname.includes('customerlogs')){
            this.setState({isCustomerPage:true})
            this.props.getLogs(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1],this.props.currentLanguage, this.props.dictionaries)
        }
        else{
            this.props.getShipmentLogs(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1],this.props.currentLanguage, this.props.dictionaries)
        }
        
    }
    getHeading = (flag)=>{
        switch(flag){
            case true:return 'Customer';
            case false:return 'Shipment';
            default:return ''
        }
    }

    

    render() {
        
        return (
                    <div className="main-wraper mt30">
                        <div className="main-content">
                        
                        <div className="page-title"><h2>{this.getHeading(this.state.isCustomerPage) + ' logs'}</h2></div>
                    <div className="white-section search-cutomer">
                    <fieldset className={"tableFieldset p10 bdr0"}>
                        <div className="filter-title-row1 mb10 uppercase pl10"><span className="font13 pl10">{this.getHeading(this.state.isCustomerPage)} Id# {this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]}</span></div> 
                        {this.state.isCustomerPage && <CustomerLogList logs={this.props.customerLogs}/>}
                        {!this.state.isCustomerPage && <LogsList logs={this.props.shipmentLogs}/>}
                        {this.props.loading && <Loader />}
                    </fieldset>    
                        </div>  
                        </div>
                    </div>        
        );
    }
}

const mapStateToProps = state => ({
    currentLanguage: state.i18nReducer.currentLanguage,
    dictionaries: state.i18nReducer.dictionaries,
    loading: state.common.loading,
    customerLogs:state.customer.customerLogs,
    shipmentLogs:state.shipment.shipmentLogs
});

const mapDispatchToProps = dispatch => ({
    getLogs:(id,lang,dictionaries)=>{
         customerService.getCustomerLogs(dispatch,lang,dictionaries,{id})
    },
    getShipmentLogs:(id,lang,dictionaries)=>{
        shipmentService.getShipmentLogs(dispatch,lang,dictionaries, {id})
   },
    
});

export default connect(mapStateToProps, mapDispatchToProps)(Logs);
