// @flow

// /file/{fileId}
export const SET_USERS = 'userManagement/SET_USERS';
export const SET_USER = 'userManagement/SET_USER';
export const SET_ROLES = 'userManagement/SET_ROLES';
export const SET_LOADER = 'userManagement/SET_LOADER';
export const SET_DEPARTMENTS = 'userManagement/SET_DEPARTMENTS';
export const SET_PAYTYPES = 'userManagement/SET_PAYTYPES';
export const SET_TIMEZONES = 'userManagement/SET_TIMEZONES';
export const SET_SALESTEAMS = 'userManagement/SET_SALESTEAMS';
export const ADD_NEW_USER = 'userManagement/ADD_NEW_USER';
export const SET_SECURITY_LEVEL = 'userManagement/SET_SECURITY_LEVEL';
export const SET_CURRENT_SECURITY_LEVEL = 'userManagement/SET_CURRENT_SECURITY_LEVEL';
export const SET_SECURITY_ROLES = 'userManagement/SET_SECURITY_ROLES';
export const SET_CURRENT_SECURITY_ROLES = 'userManagement/SET_CURRENT_SECURITY_ROLES';
export const SET_MODULES = 'userManagement/SET_MODULES';
export const SET_LEAD_TYPE = 'userManagement/SET_LEAD_TYPE';
export const SET_LEAD_MODE = 'userManagement/SET_LEAD_MODE';
export const SET_PAGING = 'userManagement/SET_PAGING';
export const SET_KEYWORD = 'userManagement/SET_KEYWORD';
export const SET_TEAMS = 'userManagement/SET_TEAMS';
export const SET_USER_PAGINATION = 'userManagement/SET_USER_PAGINATION';

